export const ONLINE_EN = {
    main: {
        service_name: "Service name",
        service_description: "Service description",
        service_duration: "Service duration",
        service_price: "Service price",
        minutes: "minutes",
        currency: "USD",
        save: "Save",
        edit: "Edit",
        delete: "Delete",
    },
    online: {
        service: {
            create_title: "Create Service",
            edit_title: "Edit Service",
            list_title: "Service List",
            name: "Name",
            description: "Description",
            duration: "Duration",
            price: "Price",
        },
        schedule: {
            create_title: "Create Schedule",
            edit_title: "Edit Schedule",
            list_title: "Schedule List",
            start_time: "Start Time",
            end_time: "End Time",
            status: "Status",
        },
        booking: {
            create_title: "Create Booking",
            edit_title: "Edit Booking",
            list_title: "Booking List",
            user_name: "User Name",
            user_phone: "User Phone",
            additional_info: "Additional Information",
        },
        blacklist: {
            create_title: "Add to Blacklist",
            edit_title: "Edit Blacklist",
            list_title: "Blacklist List",
            user_name: "User Name",
            user_phone: "User Phone",
        },
    },
};
