import React from "react";
import { Header, Sidebar } from "./Components/index";

import { Col, Container, Row } from "react-bootstrap";
import styles from "./MainApp.module.scss";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function MainApp() {
    const user = useSelector(({ user }) => user.userInfo);

    return (
        <div className={styles.container}>
            {/* <Header /> */}
            <Container fluid>
                <Row style={{ position: "relative" }}>
                    <Col className={styles.sidebar} md={3} lg={2}>
                        <Sidebar />
                    </Col>
                    <Col md={9} lg={10}>
                        <Header user={user} />
                        {/* <Routes >
                                <Route path="/"  element={<Dashboard />} />
                                <Route path="/profile"  element={<Profile />} />
                                <Route path="/multilink"  element={<Multilink />} />
                                <Route path="/tools"  element={<Tools />} />
                                <Route
                                    path="*"
                                    element={<Navigate to="/profile" />}
                                /> 
                            </Routes >                     */}

                        <Outlet />
                        <div
                            style={{
                                height: "40px",
                            }}
                        ></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MainApp;

// import React from 'react'
// import { useDispatch, useSelector } from "react-redux";
// import { userCheck } from '../redux/user/user.action'

// import { Auth, Profile } from '../Components';

// function Main() {

//     const dispatch = useDispatch()

// 	const store = useSelector(({ user }) => {

// 		return {
// 			checkAuth: user.userAuth,
// 			user: user.userInfo }

// 	  })

// 	React.useEffect(() => {
// 		if (localStorage.getItem('token')) {
// 			localStorage.getItem('token')
// 			dispatch(userCheck())
// 		} else {
// 			localStorage.removeItem('token');

// 		}
// 	}, [dispatch])

//     return (
//         <div>

//             <p>
//                 Edit <code>src/App.js</code> and save to reload.
//             </p>

//             <p>
//                 {store.checkAuth ? 'тру' : 'нетру'}
//             </p>

//             {store.checkAuth
//                 ?
//                     <Profile />
//                 :
//                     <Auth />

//             }

//     </div>
//     )
// }

// export default Main
