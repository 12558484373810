import React from "react";
import emoji from "./emoji";

import styles from "./EmojiComponent.module.scss";
import { Modal } from "react-bootstrap";

function EmojiComponent({ onEmojiClick, show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Эмоджи</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.container}>
                    <div>Эмоции</div>
                    <div className={styles.block}>
                        {emoji.Smileys.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>
                    <div>Животные</div>
                    <div className={styles.block}>
                        {emoji.Animals.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>
                    <div>Путешествия</div>
                    <div className={styles.block}>
                        {emoji.Travel.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>

                    <div>Активности</div>
                    <div className={styles.block}>
                        {emoji.Activities.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>

                    <div>Предметы</div>
                    <div className={styles.block}>
                        {emoji.Objects.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>
                    <div>Символы</div>
                    <div className={styles.block}>
                        {emoji.Symbols.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>
                    <div>Флаги</div>
                    <div className={styles.block}>
                        {emoji.Flags.map((em) => (
                            <div
                                onClick={() => onEmojiClick(em)}
                                className={styles.emoji}
                                key={`${em.no}_${em.code}`}
                            >
                                {em.emoji}
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

export default EmojiComponent;
