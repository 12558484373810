import React, { useRef, useState } from "react";
import {
    Button,
    Form,
    FormControl,
    InputGroup,
    Overlay,
    Tooltip,
} from "react-bootstrap";
import { AiFillCopy, AiOutlineQrcode, AiOutlineShareAlt } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { editSlugMultilink } from "../../../../../redux/multilink/multilink.action";
import styles from "./MainProjectInfo.module.css";
import QrProject from "./QrProject/QrProject";

function MainProjectInfo({ mainInfo, user }) {
    const MultilinkError = useSelector(
        ({ multilink }) => multilink.multilinkError
    );

    const dispatch = useDispatch();
    //////////////////////////////////////////
    const [show, setShow] = useState(false);
    const target = useRef(null);

    // function copy text / url project
    const copyBtnHandler = () => {
        navigator.clipboard.writeText(`https://clckd.in/g/${mainInfo.slug}`);
        setShow(!show);
        setTimeout(() => {
            setShow(show);
        }, 2500);
    };

    /////////////////////////////////////////////////////////////////
    const projectId = window.location.pathname.toString().split("/");

    const [data, setData] = useState({
        slug: "",
        id: projectId[2],
    });
    const editSlugHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const postSlugHandler = (e) => {
        e.preventDefault();
        dispatch(editSlugMultilink(data, user.id));
    };

    const [showModalQr, setShowModalQr] = useState(false);

    const handleShowModalQr = () => setShowModalQr(true);

    return (
        <div className={styles.main}>
            {showModalQr && (
                <QrProject setShowModalQr={setShowModalQr} data={mainInfo} />
            )}

            <Form.Label htmlFor="basic-url">Укажите ваш URL</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                    https://clckd.in/g/
                </InputGroup.Text>
                <FormControl
                    placeholder="Введите текст"
                    defaultValue={mainInfo.slug}
                    name="slug"
                    onChange={editSlugHandler}
                    isInvalid={
                        MultilinkError.errors && MultilinkError.errors.slug
                    } // выводим ошибку
                />
                {data.slug.length > 4 && (
                    <Button variant="dark" onClick={postSlugHandler}>
                        Сохранить
                    </Button>
                )}

                <Form.Control.Feedback type="invalid">
                    {MultilinkError.message}
                </Form.Control.Feedback>
            </InputGroup>

            <div className={styles.buttons}>
                <Overlay target={target.current} show={show} placement="bottom">
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            Скопировано
                        </Tooltip>
                    )}
                </Overlay>
                <div
                    onClick={() => {
                        copyBtnHandler();
                    }}
                    className={styles.button}
                    ref={target}
                >
                    <AiFillCopy className={styles.icon} />
                    <p>Скопировать</p>
                </div>

                <div
                    onClick={() => {
                        handleShowModalQr();
                    }}
                    className={styles.button}
                >
                    <AiOutlineQrcode className={styles.icon} />
                    <p>QR код</p>
                </div>
                <div className={styles.button}>
                    <AiOutlineShareAlt className={styles.icon} />
                    <p>Поделиться</p>
                </div>
            </div>
        </div>
    );
}

export default MainProjectInfo;
