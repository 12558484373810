import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Button, ButtonGroup, Navbar } from "react-bootstrap";
import styles from "./Sidebar.module.scss";
import SidebarMenu from "./SidebarMenu";
import SidebarSetting from "./SidebarSetting";
import { BsArrowLeftShort } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import SidebarMenuCRM from "./SidebarMenuCRM";

function Sidebar() {
    const { t } = useTranslation("sidebar");
    let navigate = useNavigate();
    const urlLocation = window.location.pathname;

    // переключаем меню pult / crm
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <div className={styles.container}>
            <Navbar expand={false} className={styles.nav}>
                <Navbar.Toggle
                    aria-controls="offcanvasNavbar"
                    className={styles.menu_toggle}
                />

                <div className={styles.brand}>
                    <div className={styles.brand__logo}>
                        <Navbar.Brand
                            as={NavLink}
                            to="/"
                            className={styles.brand__logo__img}
                        >
                            Clicked
                            <sup className={styles.brand__logo__beta}>beta</sup>
                        </Navbar.Brand>

                        <span className={styles.brand__logo__desc}>
                            {t("sidebar.brand.desc")}
                        </span>
                    </div>
                </div>

                <ButtonGroup className={`btn-block ${styles.toggleMenu}`}>
                    <Button
                        className={`${styles.toggleBtn}  
                        ${
                            !toggleMenu && styles.active
                        }`}
                        onClick={() => setToggleMenu(false)}
                    >
                        Пульт
                    </Button>
                    <Button
                        className={`${styles.toggleBtn} ${
                            toggleMenu && styles.active
                        }`}
                        onClick={() => setToggleMenu(true)}
                    >
                        CRM
                    </Button>
                </ButtonGroup>

                {/* меню мобильное */}
                <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                    className={styles.menu_mobile}
                >
                    {!toggleMenu ? <SidebarMenu /> : <SidebarMenuCRM />}

                    <SidebarSetting />
                </Navbar.Offcanvas>

                {/* меню десктопное */}
                <div className={`${styles.menu} flex-column`}>
                    {!toggleMenu ? <SidebarMenu /> : <SidebarMenuCRM />}
                    <SidebarSetting />
                </div>
            </Navbar>

            {/* <SidebarSetting /> */}
        </div>
    );
}

export default Sidebar;
