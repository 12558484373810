export const MULTILINK_RU = {
    multilink: {
        header: {
            list: 'Все мультиссылки',
            create: 'Создать мультиссылку',
            project: 'Проект',
            stat: 'Статистика',
            settingMain: 'Основные настройки мультиссылки',
            settingAccess: 'Настройки доступа мультиссылки',
            settingIntegration: 'Настройка интеграций'
        }
    }
} 