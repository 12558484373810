import React, { useMemo } from "react";
import {
    Button,
    Col,
    Form,
    InputGroup,
    Pagination,
    Row,
} from "react-bootstrap";
import { PropTypes } from "prop-types";
// загружаем иконку сбросить фильтр из набора react-icons
import { BsArrowCounterclockwise } from "react-icons/bs";


function UiPaginationComponent({
    itemsPerPage,
    setItemsPerPage,
    totalItems,
    currentPage,
    onPageChange,
}) {
    // itemsPerPage: количество элементов на странице
    // setItemsPerPage: установить количество элементов на странице
    // totalItems: общее количество элементов
    // currentPage: текущая страница
    // onPageChange: обработчик изменения страницы

    const totalPages = Math.ceil(totalItems / itemsPerPage); // общее количество страниц в пагинации
    const pageItems = [];

    // В массив pageItems добавляем Pagination.Item для каждой страницы, которая отображается в пагинации. Также, если страниц больше 5, мы добавляем Pagination.Ellipsis на третью страницу до текущей страницы и третью страницу после текущей страницы.
    for (let i = 1; i <= totalPages; i++) {
        if (
            i === 1 ||
            i === totalPages ||
            (i >= currentPage - 2 && i <= currentPage + 2)
        ) {
            // Если страница не первая или последняя или находится в пределах пагинации, добавляем Pagination.Item на страницу.
            pageItems.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        } else if (
            (i === currentPage - 3 && currentPage > 4) ||
            (i === currentPage + 3 && currentPage < totalPages - 3)
        ) {
            // Если страница не первая или последняя и находится в пределах пагинации, добавляем Pagination.Ellipsis на страницу.
            pageItems.push(
                <Pagination.Ellipsis key={i} onClick={() => onPageChange(i)} />
            );
        }
    }

    const moreItemsHandler = (count) => {
        return setItemsPerPage(itemsPerPage + count);
    };

    useMemo(() => {
        if (currentPage > totalPages) {
            onPageChange(totalPages);
        }
    }, [currentPage, totalPages]);

    const checkNumber = (number) => {
        if (Number(number) !== NaN && Number(number) > 0) {
            setItemsPerPage(Number(number));
        } else {
            setItemsPerPage(10);
        }
    };
    // Возвращаем разметку пагинации, где мы отображаем Pagination компонент из react-bootstrap. Если общее количество страниц totalPages больше 1, отображаем компонент Pagination и добавляем Pagination.First, Pagination.Prev, Pagination.Next и Pagination.Last кнопки. Также добавляем Form.Select, чтобы пользователь мог выбирать количество элементов на странице.

    return (
        <>
            <Row className="mt-3">
                <Col sm={8}>
                    {totalPages > 1 && (
                        <Pagination>
                            <Pagination.First onClick={() => onPageChange(1)} />
                            <Pagination.Prev
                                disabled={currentPage === 1}
                                onClick={() => onPageChange(currentPage - 1)}
                            />
                            {pageItems}
                            <Pagination.Next
                                disabled={currentPage === totalPages}
                                onClick={() => onPageChange(currentPage + 1)}
                            />
                            <Pagination.Last
                                onClick={() => onPageChange(totalPages)}
                            />
                        </Pagination>
                    )}
                </Col>
                <Col sm={4}>
                    <InputGroup
                        className="mb-3"
                        size="sm"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        {/* <Form.Select
                            onChange={(e) =>
                                setItemsPerPage(Number(e.target.value))
                            }
                            value={
                                itemsPerPage > totalItems
                                    ? totalItems
                                    : itemsPerPage
                            }
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            {itemsPerPage > 20 && (
                                <option
                                    value={
                                        itemsPerPage > totalItems
                                            ? totalItems
                                            : itemsPerPage
                                    }
                                >
                                    {itemsPerPage > totalItems
                                        ? totalItems
                                        : itemsPerPage}
                                </option>
                            )}
                        </Form.Select> */}
                        {itemsPerPage > 20 && (
                            <>
                                                           <Button
                                    onClick={() => setItemsPerPage(10)}
                                    variant="dark"
                                    
                                >
                                    <BsArrowCounterclockwise style={{marginBottom: "3px"}} />
                                </Button>{" "}
                                <Button
                                    onClick={() => moreItemsHandler(-10)}
                                    variant="outline-secondary"
                                >
                                    - 10
                                </Button>{" "}
                            </>
                        )}
                        <Form.Control
                            type="text"
                            style={{ maxWidth: "60px" }}
                            value={
                                itemsPerPage > totalItems
                                    ? totalItems
                                    : itemsPerPage
                            }
                            onChange={(e) => checkNumber(e.target.value)}
                        />
                        {itemsPerPage <= totalItems && (
                            <Button
                                onClick={() => moreItemsHandler(10)}
                                variant="outline-secondary"
                            >
                                +10
                            </Button>
                        )}

                    </InputGroup>
                </Col>
            </Row>
            {/* <div>
                {itemsPerPage <= totalItems && (
                    <Button
                        onClick={() => moreItemsHandler(10)}
                        variant="outline-primary"
                    >
                        Показать ещё 10
                    </Button>
                )}{" "}
                {itemsPerPage > 20 && (
                    <>
                        <Button
                            onClick={() => moreItemsHandler(-10)}
                            variant="outline-primary"
                        >
                            Уменьшить на 10
                        </Button>{" "}
                        <Button
                            onClick={() => setItemsPerPage(10)}
                            variant="dark"
                        >
                            Сбросить
                        </Button>
                    </>
                )}
            </div> */}
        </>
    );
}

UiPaginationComponent.propTypes = {
    itemsPerPage: PropTypes.number.isRequired,
    setItemsPerPage: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default UiPaginationComponent;
