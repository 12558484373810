// online.types.js
const OnlineActionTypes = {
    // Projects
    FETCH_PROJECT: 'FETCH_PROJECT',
    CREATE_PROJECT: 'CREATE_PROJECT',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    // Services
    FETCH_SERVICES: 'FETCH_SERVICES',
    CREATE_SERVICE: 'CREATE_SERVICE',
    UPDATE_SERVICE: 'UPDATE_SERVICE',
    DELETE_SERVICE: 'DELETE_SERVICE',
  
    // Schedules
    FETCH_SCHEDULES: 'FETCH_SCHEDULES',
    CREATE_SCHEDULE: 'CREATE_SCHEDULE',
    UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
    DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  
    // Bookings
    FETCH_BOOKINGS: 'FETCH_BOOKINGS',
    CREATE_BOOKING: 'CREATE_BOOKING',
    UPDATE_BOOKING: 'UPDATE_BOOKING',
    DELETE_BOOKING: 'DELETE_BOOKING',
  
    // Blacklist
    FETCH_BLACKLIST: 'FETCH_BLACKLIST',
    ADD_TO_BLACKLIST: 'ADD_TO_BLACKLIST',
    UPDATE_BLACKLIST_ITEM: 'UPDATE_BLACKLIST_ITEM',
    REMOVE_FROM_BLACKLIST: 'REMOVE_FROM_BLACKLIST',

    // style
    SET_ONLINE_PAGE_STYLES: 'SET_ONLINE_PAGE_STYLES',

  };
  
  export default OnlineActionTypes;
  