import React from "react";

import { Container, Text } from './ViewMultilinkText.styled'


function ViewMultilinkText({ text }) {



    return( 
        <>
            <Container container={text} >
                {text.setting.mode && 
                
                    <Text container={text}>
                        {text.main.text}
                    </Text>
                }
            </Container>
            
        </>
    )
}

export default ViewMultilinkText;
