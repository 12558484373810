import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMinishops } from "../../../../redux/minishop/minishop.action";

import styles from "./AllShop.module.scss";
import { Col, Row } from "react-bootstrap";
import ShopItems from "./ShopItems/ShopItems";
import NoneShop from "../NoneShop/NoneShop";

function AllShop() {
    const dispatch = useDispatch();

    const user = useSelector(({ user }) => user.userAuthInfo);
    const minishopsFetch = useSelector(
        ({ minishop }) => minishop.minishopsFetch
    );

    console.log("minishopsFetch.length", minishopsFetch.length);

    useEffect(() => {
        dispatch(fetchMinishops(user));
    }, []);

    return (
        <Row>
            <Col sm={7}>
                <div className={styles.minishopsFetch}>
                    {minishopsFetch.length > 0 ? (
                        minishopsFetch.map((minishop, index) => (
                            
                            <ShopItems minishop={minishop} key={`${index}_ShopItems`} />
                        ))
                    ) : (
                        <NoneShop />
                    )}
                </div>
            </Col>
            <Col sm={5}></Col>
        </Row>
    );
}

export default AllShop;
