

export const TRANSLATIONS_EN = {
    landing:{
        auth: {
            login: 'Log in',
            signup: 'Sign Up',
            popup: {
                login: 'Sign in to your account',
                login_signup: 'Or create your ',
                login_signup_link: 'free account ',
                login_btn: 'Login',
                signup: 'Sign in to your account',
                signup_login: 'Or create your ',
                signup_login_link: 'free account ',
                signup_btn: 'Login',
                email: 'Enter your email',
                email_desc: 'We\'ll never share your email with anyone else.',
                password: 'Password',
                google_btn: 'Log in with Google'
            }
        },
        menu: {
            link1:'Multilink',
            link2:'Storylanding',
            link3:'Instastore'
        },
        title: {
            part1:'Multilink to Instragram',
            part2:'for free',
            part3:'in 5 minutes'
        },
        desc:'Unite the audiences of all social networks and messengers to get more sales and appeals.'
    },

};