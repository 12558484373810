import OpenAiActionTypes from "./openai.types";

const INITIAL_STATE = {
    openaiCreate: {},
    openaiItems: {},
    openaiCurrent: null,
    openaiError: {},
//     openaiCreate: false,
    openaiLoading: true,
    openAiData: {},
    redirect: false,
//     openaiItemCurrent: {},
};

const openaiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OpenAiActionTypes.OPENAI_CREATE:
            return {
                ...state,
                openaiCreate: action.payload,
            };
        case OpenAiActionTypes.OPENAI_DATA:
            return {
                ...state,
                openAiData: action.payload,
            };
        case OpenAiActionTypes.OPENAI_GET:
            return {
                ...state,
                openaiItems: action.payload,
            };
            case OpenAiActionTypes.OPENAI_LOADING:
                return {
                    ...state,
                    openaiLoading: action.payload,
                };
            case OpenAiActionTypes.OPENAI_CURRENT_TEXT:
                return {
                    ...state,
                    openaiCurrent: action.payload,
                };
            case OpenAiActionTypes.OPENAI_ERROR:
                return {
                    ...state,
                    openaiError: action.payload,
                };
            case OpenAiActionTypes.OPENAI_REDIRECT:
                return {
                    ...state,
                    redirect: action.payload,
                };
        default:
            return state;
        }
    }



export default openaiReducer;