import React, { useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import styles from "./SettingComponent.module.scss";

function SettingComponent({ settingComponent, setSettingComponent }) {
    const [checked, setChecked] = useState(true);

    const checkedHandler = (event) => {
        setSettingComponent({
            ...settingComponent,
            setting: {
                ...settingComponent.setting,
                [event.target.name]: event.target.checked,
            },
        });
        setChecked(event.target.checked);
    };

    const buttonHandler = (event) => {
        setSettingComponent({
            ...settingComponent,
            setting: {
                ...settingComponent.setting,
                [event.target.name]: event.target.value,
            },
        });
    };

    return (
        <>
            <Row className={styles.row}>
                <Col sm={8}>Показывать блок?</Col>
                <Col sm={4}>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            name="mode"
                            // label="В новой вкладке"
                            // checked={socialDesign.design.target === '_blank'}
                            checked={checked}
                            onChange={checkedHandler}
                        />
                    </Form>
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col sm={4}>Анимация</Col>
                <Col sm={8} className={styles.buttonContainer}>
                    <Button
                        variant={
                            settingComponent.setting.animate === "no"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="no"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Нет
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animate === "zoom"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="zoom"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Зум
                    </Button>

                    <Button
                        variant={
                            settingComponent.setting.animate === "pulse"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="pulse"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Пульс
                    </Button>

                    <Button
                        variant={
                            settingComponent.setting.animate === "shaking"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="shaking"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Потряхивание
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animate === "vertical_shaking"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="vertical_shaking"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Вертикальное потряхивание
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animate === "horizontal_shaking"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="horizontal_shaking"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Горизонтальное потряхивание
                    </Button>

                    <Button
                        variant={
                            settingComponent.setting.animate === "vibration"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="vibration"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Вибрация
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animate === "oscillation"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="oscillation"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Колебание
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animate === "rubber_band"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="rubber_band"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Резинка
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animate === "shine"
                                ? "primary"
                                : "light"
                        }
                        name="animate"
                        value="shine"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Блеск
                    </Button>
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col sm={4}>Скорость анимация</Col>
                <Col sm={8} className={styles.buttonContainer}>
                    <Button
                        variant={
                            settingComponent.setting.animationSpeed === "standart"
                                ? "primary"
                                : "light"
                        }
                        name="animationSpeed"
                        value="standart"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Обычная
                    </Button>
                    <Button
                        variant={
                            settingComponent.setting.animationSpeed === "fast"
                                ? "primary"
                                : "light"
                        }
                        name="animationSpeed"
                        value="fast"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Быстрая
                    </Button>

                    <Button
                        variant={
                            settingComponent.setting.animationSpeed === "slow"
                                ? "primary"
                                : "light"
                        }
                        name="animationSpeed"
                        value="slow"
                        onClick={buttonHandler}
                        size="sm"
                    >
                        Медленная
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default SettingComponent;
