import React from "react";
import { Outlet } from "react-router-dom";

function HeaderOutlet() {
    return (
        <>
            {" "}
            <Outlet />
        </>
    );
}

export default HeaderOutlet;
