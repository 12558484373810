export const getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

//  передаем число и слова которые нужно склонить в зависимости от числа
//  пример использования: {getNoun(count, "Мультиссылка", "Мультиссылки", "Мультиссылок")}
//  ответ функции: 1 мультиссылка, 4 мультиссылки, 6 мультиссылок 