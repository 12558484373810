const defaultOptions = {
    social: {
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design: {
            target: "_blank",
            type_button: "logo",
            style_button: "bg",
            shape_button: "none",
            color_button: "brand",
        },
        items: [
            {
                _id: "6235fd3bfee9302874c3bb9e",
                name: "FB",
                title: "Facebook",
                site: "https://fb.com/",
                color: "#3b5998",
                index: 1,
                order: 1,
            },
        ],
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    messanger: {
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design: {
            target: "_blank",
            type_button: "logo",
            style_button: "bg",
            shape_button: "none",
            color_button: "brand",
        },
        items: [
            {
                name: "TG",
                title: "Telegram",
                site: "https://telegram.com/",
                color: "#0088cc",
                index: 1,
                order: 1,
            },
        ],
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    title: {
        main: {
            textAlign: "center",
            size: "21",
            text: "",
            weight: 400,
            color: "#000000",
            transform: "none",
            header: "h2",
        },
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },

        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    text: {
        main: {
            textAlign: "left",
            size: "16px",
            text: "",
            weight: 400,
            color: "#000000",
            transform: "none",
        },
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },

        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    blockLink: {
        main: {
            title: "",
            description: "",
            inputText: "",
            icon: null,
            iconType: "no_icon",
            textPosition: "left",
            iconPosition: "right",
            type: { value: "example", name: "Ссылка", mask: "Ссылка на сайт" },
        },
        design_link: {
            color: "#c1c1c1",
            btn_color: "#000000",
            shape_button: "none",
        },
        design: {
            target: "_blank",
            type_button: "logo",
            style_button: "bg",
            shape_button: "none",
            color_button: "brand",
        },
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },

        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    priceList: {
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design: {
            color: '#000000',
            border: true,
            color_border: '#cfcfcf'
        },
        items: [
            {
                title: "",
                price: "",
                currency: "Rub",
                price_type: "equally", //from before 
                index: 1,
                order: 1,
            },
        ],
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    listBlock: {
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design: {
            color: '#000000',
            list_type: "disc", // 
            list_custum: ''
        },
        items: [
            {
                title: "",
                description: "",
                index: 0,
                order: 0,
            },
        ],
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    faqBlock: {
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design: {
            color: '#000000',
            list_type: "disc", // 
            list_custum: ''
        },
        items: [
            {
                title: "",
                description: "",
                index: 0,
                order: 0,
            },
        ],
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    videoBlock: {
        main: {
            linkVideo: null,
            description: null,
            type: null
        },
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },
    svgBlock: {
        main: {
            linkVideo: null,
            description: null,
            type: null
        },
        setting: {
            mode: true,
            animate: "no",
            animationSpeed: "standart",
        },
        design_block: {
            background: "",
            background_color: "#ffffff",
            background_img: "",
            background_padding: {
                top: 6,
                right: 6,
                bottom: 6,
                left: 6,
            },
            background_margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blur: 0,
            brightness: 100,
            opacity: 100,
        },
    },

};

export default defaultOptions;
