import React from "react";
import { Container, Item, Title, Description } from "./ViewMultilinkList.styled";

function ViewMultilinkList({ listBlock }) {
    return (
        <Container container={listBlock}>
            <Item container={listBlock}>
                <ul>
                    {listBlock.items.map((item, index) => (
                        <li  key={`${index}_list_${item.title}`} >
                            <Title>{item.title}</Title>
                            <Description>{item.description}</Description>
                        </li>
                    ))}
                </ul>
            </Item>
        </Container>
    );
}

export default ViewMultilinkList;
