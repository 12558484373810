import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiFillCopy } from "react-icons/ai";
import styles from "../Tools.module.scss";

function Duplicates() {
    const [delSpace, setDelSpace] = useState(true);

    const delSpaceHandler = (e) => {
        setDelSpace(!delSpace);
    };

    const [text, setText] = useState({
        valueText: "",
        originalText: "",
        resultText: "",
        textDuplicateLength: 0,
    });

    // console.log(text);

    const deliteDuplicatesHandler = (e) => {
        // удаляем дубликаты
        let text1 = text.originalText;
        let textArray = text1.split("\n");
        let textArrayUnique = [...new Set(textArray)];
        let textUnique = textArrayUnique.join("\n");
        // удаление пустых строк
        if (delSpace) {
            textUnique = textUnique.replace(/^\s*[\r \n]/gm, "");
        }
        // показываем кнопку копирования
        if (textUnique.length > 0) {
            setCopyBtn(true);
        } else {
            setCopyBtn(false);
        }
        // пишем в state
        setText({
            ...text,
            resultText: textUnique,
            valueText: textUnique,
            textDuplicateLength: textArray.length - textArrayUnique.length,
        });
    };
const [copyBtn, setCopyBtn] = useState(false);
    const addTextHandler = (e) => {
        setText({
            ...text,
            originalText: e.target.value,
            valueText: e.target.value,
            resultText: "",
            textDuplicateLength: 0,
        });
        setCopyBtn(false);
    };

    

    const copyBtnHandler = () => {
        navigator.clipboard.writeText(text.resultText);
        // setShow(!show);
        // setTimeout(() => {
        //     setShow(show);
        // }, 2500);
    };

    return (
        <div>
            <Row>
                <Col sm={8}>
                    <Form className={styles.form}>
                        <div className={styles.title_container}>
                            <h5>Удаление дубликатов строк</h5>
                            {copyBtn && (
                                <Button
                                    variant="light"
                                    onClick={copyBtnHandler}
                                    size="sm"
                                    className={styles.roll_out}
                                >
                                    <AiFillCopy /> Копировать{" "}
                                </Button>
                            )}
                        </div>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Введите текст</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                onChange={addTextHandler}
                                defaultValue={text.valueText}
                                value={text.valueText}
                            />
                        </Form.Group>
                        {/* <Form.Group controlId="exampleForm.ControlTextarea2">
                            <Form.Label>Результат</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                defaultValue={text.resultText}
                                value={text.resultText}
                            />
                        </Form.Group>{" "} */}
                    </Form>
                </Col>
                <Col sm={4}>
                    <div className={styles.info}>
                        <Row>
                            <Col> Всего строк: </Col>
                            <Col>
                                {text &&
                                    text.originalText.split("\n").length - 1}
                            </Col>
                        </Row>
                        <Row>
                            <Col>Уникальных строк: </Col>
                            <Col>
                                {text && text.resultText.split("\n").length - 1}
                            </Col>
                        </Row>
                        <Row>
                            <Col>Количество дубликатов: </Col>
                            <Col>{text.textDuplicateLength}</Col>
                        </Row>
                    </div>
                    <div className={styles.check}>
                        <Form.Check
                            type="switch"
                            label="Удалить пустые строки"
                            defaultChecked={delSpace}
                            onClick={setDelSpace}
                        />
                    </div>
                    <div className={styles.info}>
                        {/* <Button onClick={deliteDuplicatesHandler}>
                            Удалить дубликаты
                        </Button> */}

                        {text.originalText ? (
                            <Button onClick={deliteDuplicatesHandler}>
                                Удалить дубликаты
                            </Button>
                        ) : (
                            <Button disabled>Удалить дубликаты</Button>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Duplicates;
