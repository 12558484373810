import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import styles from "./InputComponent.module.scss";

function InputComponent({ data, elementStyles, handler }) {
    // это дизайн компонент для отображения настроек дизайна таких элементов как padding, margin, borderRadius
    // используя его в elementStyles нужно передать стили элемента
    // в data передаем заголовок элемента и лэйблы для инпутов и единиц


    return (
        <Row className={styles.row}>
            <Col sm={4}>{data.title}</Col>
            <Col sm={8}>
                <Row>
                    <Col>
                        <Form.Label><small>{data.label.top}</small></Form.Label>
                        <Form.Control
                            type="number"
                            max="5000"
                            defaultValue={
                                typeof Object.values(elementStyles)[0] ===
                                "string"
                                    ? parseFloat(
                                          Object.values(elementStyles)[0].match(
                                              /\d+(\.\d+)?/
                                          )[0]
                                      )
                                    : Object.values(elementStyles)[0].value || 0
                            }
                            id={`${Object.keys(elementStyles)[0]}-1`}
                            name={Object.keys(elementStyles)[0]}
                            data-units={data.units}
                            onChange={handler}
                            size="sm"
                        />
                    </Col>
                    <Col>
                        <Form.Label><small>{data.label.right}</small></Form.Label>
                        <Form.Control
                            type="number"
                            max="5000"
                            defaultValue={
                                typeof Object.values(elementStyles)[1] ===
                                "string"
                                    ? parseFloat(
                                          Object.values(elementStyles)[1].match(
                                              /\d+(\.\d+)?/
                                          )[0]
                                      )
                                    : Object.values(elementStyles)[1].value || 0
                            }
                            id={`${Object.keys(elementStyles)[1]}-2`}
                            name={Object.keys(elementStyles)[1]}
                            data-units={data.units}
                            onChange={handler}
                            size="sm"
                        />
                    </Col>
                    <Col>
                        <Form.Label><small>{data.label.bottom}</small></Form.Label>
                        <Form.Control
                            type="number"
                            max="5000"
                            defaultValue={
                                typeof Object.values(elementStyles)[2] ===
                                "string"
                                    ? parseFloat(
                                          Object.values(elementStyles)[2].match(
                                              /\d+(\.\d+)?/
                                          )[0]
                                      )
                                    : Object.values(elementStyles)[2].value || 0
                            }
                            id={`${Object.keys(elementStyles)[2]}-3`}
                            name={Object.keys(elementStyles)[2]}
                            data-units={data.units}
                            onChange={handler}
                            size="sm"
                        />
                    </Col>
                    <Col>
                        <Form.Label><small>{data.label.left}</small></Form.Label>
                        <Form.Control
                            type="number"
                            max="5000"
                            defaultValue={
                                typeof Object.values(elementStyles)[3] ===
                                "string"
                                    ? parseFloat(
                                          Object.values(elementStyles)[3].match(
                                              /\d+(\.\d+)?/
                                          )[0]
                                      )
                                    : Object.values(elementStyles)[3].value || 0
                            }
                            id={`${Object.keys(elementStyles)[3]}-4`}
                            name={Object.keys(elementStyles)[3]}
                            data-units={data.units}
                            onChange={handler}
                            size="sm"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default InputComponent;
