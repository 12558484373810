import React, { useEffect, useState } from "react";
import { Container } from './ViewMultilinkVideo.styled';

function ViewMultilinkVideo({ videoBlock }) {
// может появляться ошибка CORS при включении адблокер

    const [videoEmbed, setVideoEmbed] = useState(null);

    const videoEmbedHandler = () => {
        if (videoBlock.main.type === "youtube") {
            let test = videoBlock.main.linkVideo
                .replace("http://", "")
                .replace("https://", "")
                .replace("www.", "")
                .replace("youtu.be/", "youtube.com/watch?v=")
                .replace("youtube.com/watch?v=", "");

            setVideoEmbed(test);
        }
    };

    useEffect(() => {
        videoEmbedHandler();
    }, []);

    return (
        <Container container={videoBlock}>
            {videoBlock.main.type === "youtube" && (
                <iframe
                    width="100%"
                    crossOrigin="Access-Control-Allow-Headers"
                    src={`https://www.youtube.com/embed/${videoEmbed}`}
                    title="YouTube video player"
                    frameBorder="0"
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
                
            )}
        </Container>
    );
}

export default ViewMultilinkVideo;
