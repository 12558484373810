import React from "react";

import { Logo, Container, Item, Button,  Title } from './ViewMultilinkSocial.styled'

function ViewMultilinkSocial({ social }) {

    return (
        <>

            {social.setting.mode && (
                <Container
                    container={social.design_block} 
                    typeButton={social.design.type_button}
                >
                    {social.items.map((item, index) => (
                        <Item 
                            key={`${index}_social_${item.name}`} 
                            nameSocial={item.name}
                            color={item.color}
                            styleButton={social.design.style_button}
                            shapeButton={social.design.shape_button}
                        >
                            <Button 
                                styleButton={social.design.style_button}
                                shapeButton={social.design.shape_button}
                                typeButton={social.design.type_button}
                            >
                                {(social.design.type_button === 'logo' || social.design.type_button ===  'all') &&
                                    <Logo >
                                        <img
                                            src={`/social/brand/${social.design.style_button === 'bg' ? `${item.name}-W` : item.name}.png`}
                                            width="26"
                                            alt={`${item.title}`}
                                        />
                                    </Logo>
                                }
                                {(social.design.type_button === 'text' || social.design.type_button === 'all') &&
                                    <Title
                                        typeButton={social.design.type_button}
                                    >{item.title}</Title>                      
                                }                                     
                            </Button>
     
                        </Item>
                    ))}

                </Container>
            )}
        </>
    );
}

export default ViewMultilinkSocial;
