import React, { useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
    Spinner,
} from "react-bootstrap";
import { AiTwotoneEdit, AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
    minishopLabelCopy,
    minishopLabelRemove,
    minishopLabelSwitch,
} from "../../../../../../../redux/minishop/minishop.action";

function PageShopAddLabelList({
    index,
    label,
    labels,
    setLabels,
    handleEditLabel,
    minishopId,
    userId,
}) {
    const dispatch = useDispatch();

    const editHandler = (e) => {
        handleEditLabel(index, label);
    };

    const onShowLabel = (e) => {
        e.preventDefault();
        if (e.target.name === label._id) {
            dispatch(
                minishopLabelSwitch({
                    minishopId,
                    userId,
                    labelId: e.target.name,
                })
            );
        }
    };

    const copyHandler = (e) => {
        dispatch(minishopLabelCopy({ minishopId, userId, labelId: label._id }));
    };

    const deleteHandler = (e) => {
        dispatch(
            minishopLabelRemove({ minishopId, userId, labelId: label._id })
        );
    };

    return (
        <Card className="mt-2">
            <Card.Body>
                <Row>
                    <Col sm={3}>
                        <div
                            style={{
                                backgroundColor: label.labelColor,
                                padding: "3px 10px 3px 10px",
                                borderRadius: "12px",
                                textAlign: "center",
                                fontSize: "12px",
                                display: "inline-block",
                                color: label.labelTextColor,
                            }}
                        >
                            {label.labelName}
                        </div>
                    </Col>

                    <Col sm={5}>
                        <Form.Check
                            type="switch"
                            label="Показывать"
                            name={label._id}
                            checked={label.labelShow}
                            onChange={onShowLabel}
                        />
                    </Col>
                    <Col sm={4}>
                        <div>
                            <Button
                                // as={NavLink}
                                // to={{
                                //     pathname: minishop._id,
                                // }}
                                name={label._id}
                                onClick={(e) => editHandler(e)}
                                variant="outline-primary"
                                size="sm"
                            >
                                <AiTwotoneEdit />
                            </Button>{" "}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Копировать
                                    </Tooltip>
                                }
                            >
                                <Button
                                    name={label._id}
                                    variant="outline-dark"
                                    size="sm"
                                    onClick={(e) => copyHandler(e)}
                                >
                                    <AiOutlineCopy />
                                </Button>
                            </OverlayTrigger>{" "}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">Удалить</Tooltip>
                                }
                            >
                                <Button
                                    name={label._id}
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={(e) => deleteHandler(e)}
                                >
                                    <AiOutlineDelete />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default PageShopAddLabelList;
