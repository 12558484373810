// import axios from "axios";
import React, { useState, useCallback } from "react";
import { Button, Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { userAuth } from "../../../redux/user/user.action";

function Auth() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [authState, setAuthState] = useState({
        email: "",
        password: "",
    });

    const handleChange = (event) => {
        setAuthState({
            ...authState,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            dispatch(userAuth({ ...authState }));
        },
        [dispatch, authState]
    );

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div>
                    <h2>{t("landing.auth.popup.login")}</h2>
                    <Form.Text >{t("landing.auth.popup.login_signup")} {t("landing.auth.popup.login_signup_link")}</Form.Text>
                </div>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{t("landing.auth.popup.email")}</Form.Label>
                    <Form.Control 
                    type="email" 
                    placeholder="Enter email" 
                    name="email"
                    value={authState.email}
                    onChange={handleChange}
                    />
                    {/* <Form.Text className="text-muted">
                    {t("landing.auth.popup.email")}
                        
                    </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>{t("landing.auth.popup.password")}</Form.Label>
                    <Form.Control 
                    type="password"                     
                    name="password"
                    placeholder="Password"
                    value={authState.password}
                    onChange={handleChange} />
                </Form.Group>

                <Button variant="primary" type="submit">
                    {t("landing.auth.popup.login_btn")}
                </Button>
            </Form>


            {/* <button onClick={() => dispatch(logout())}>Выйти</button> */}
        </>
    );
}

export default Auth;
