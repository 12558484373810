import MultilinkActionTypes from "./multilink.types";

const INITIAL_STATE = {
    multilink: {},
    multilinkError: {},
    multilinkCreate: false,
    multilinkLoading: true,
    multilinksFetch: [],
    multilinkRedirect: null,
    multilinkItemCurrent: {},
};

const multilinkReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MultilinkActionTypes.MULTILINK_CREATE:
            return {
                ...state,
                multilinkCreate: action.payload,
            };
        case MultilinkActionTypes.MULTILINK_LOADING:
            return {
                ...state,
                multilinkLoading: action.payload,
            };
        case MultilinkActionTypes.MULTILINK_REDIRECT:
            return {
                ...state,
                multilinkRedirect: action.payload,
            };
        case MultilinkActionTypes.MULTILINK_GET:
            return {
                ...state,
                multilink: action.payload,
            };
        case MultilinkActionTypes.MULTILINK_UPDATE_SETTING:
            return {
                ...state,
				multilink: {...state.multilink, mainMultilink: action.payload.mainMultilink}
            };
        case MultilinkActionTypes.MULTILINK_UPDATE_SLUG:
            return {
                ...state,
				multilink: {...state.multilink, secondMultilink: action.payload.secondMultilink}
            };
        case MultilinkActionTypes.MULTILINK_ERROR_SLUG:
            return {
                ...state,
				multilinkError: action.payload
            };
        case MultilinkActionTypes.MULTILINKS_FETCH:
            return {
                ...state,
                multilinksFetch: action.payload,
            };
        case MultilinkActionTypes.MULTILINK_CURRENT_ITEM:
            return {
                ...state,
                multilinkItemCurrent: action.payload,
            };

        default:
            return state;
    }
};

export default multilinkReducer;
