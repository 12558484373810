import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styles from "./Tools.module.scss";

function Tools() {
    const { t } = useTranslation("tools");

    return (
        <>
            <div className={styles.container} style={{marginBottom: '15px', padding: '10px', backgroundColor: '#e9ecef' }}>
                <Card  className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>AI Генератор текста</Card.Title>
                        <Card.Text>Генератор описаний к товарам</Card.Text>
                        <Button as={NavLink} variant="primary" to="text_generator">
                            Сгенерировать описание
                        </Button>
                    </Card.Body>
                </Card>
            </div>
            <div className={styles.container}>
                <Card className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>{t("tools.QrCreator.title")}</Card.Title>
                        <Card.Text>Создайте уникальный qr код</Card.Text>
                        <Button as={NavLink} variant="primary" to="qr">
                            {t("tools.QrCreator.btn")}
                        </Button>
                    </Card.Body>
                </Card>

                <Card className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>{t("tools.CountLetters.title")}</Card.Title>
                        <Card.Text>
                            Посчитайте количество букв в тексте
                        </Card.Text>
                        <Button
                            as={NavLink}
                            variant="primary"
                            to="countletters"
                        >
                            {t("tools.CountLetters.btn")}
                        </Button>
                    </Card.Body>
                </Card>

                <Card className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>{t("tools.Duplicates.title")}</Card.Title>
                        <Card.Text>Удалите дубликаты из текста</Card.Text>
                        <Button as={NavLink} variant="primary" to="duplicates">
                            {t("tools.Duplicates.btn")}
                        </Button>
                    </Card.Body>
                </Card>

                <Card className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>{t("tools.UTMGenerator.title")}</Card.Title>
                        <Card.Text>Генератор UTM-меток</Card.Text>
                        <Button
                            as={NavLink}
                            variant="primary"
                            to="utm_generator"
                        >
                            {t("tools.UTMGenerator.btn")}
                        </Button>
                    </Card.Body>
                </Card>

                <Card className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>
                            {t("tools.PasswordGenerator.title")}
                        </Card.Title>
                        <Card.Text>Генератор паролей</Card.Text>
                        <Button
                            as={NavLink}
                            variant="primary"
                            to="password_generator"
                        >
                            {t("tools.PasswordGenerator.btn")}
                        </Button>
                    </Card.Body>
                </Card>

                <Card className={styles.card}>
                    <Card.Body className={styles.card_body}>
                        <Card.Title>{t("tools.Translit.title")}</Card.Title>
                        <Card.Text>Транслит онлайн </Card.Text>
                        <Button as={NavLink} variant="primary" to="translit">
                            {t("tools.Translit.btn")}
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default Tools;
