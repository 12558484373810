import React from "react";

import { Route, Routes } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function HeaderDashboardRoutes() {

    const { t } = useTranslation("dashboard");

    return (
        <Routes>
            <Route path="/" element={<h2>{t("dashboard.header.list")}</h2>} />
        </Routes>
    );
}

export default HeaderDashboardRoutes;
