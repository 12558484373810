import React, { useState } from "react";

import { Modal, Button, Col, Row } from "react-bootstrap";
import styles from './AddSocialLink.module.scss'
import { BsPlusCircle } from "react-icons/bs";

function AddSocialLink({ socialLinks, setSocialLinks }) {
    // (props.nameSocial === 'FB' && '#3b5998') || 
    // (props.nameSocial === 'TW' && '#1da1f2') ||
    // (props.nameSocial === 'YT' && '#ff0000') ||
    // (props.nameSocial === 'VK' && '#0077ff') ||
    // (props.nameSocial === 'IG' && '#df457d') 
    const socials = {
        main:[
            { name: "VK", title: "Вконтакте", site: "https://vk.com/", color: '#0077ff'},
            { name: "FB", title: "Facebook", site: "https://fb.com/", color: '#3b5998' },
            { name: "TW", title: "Twitter", site: "https://twitter.com/", color: '#1da1f2' },
            { name: "IG", title: "Instagram", site: "https://instagram.com/", color: 'linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)' },
            { name: "YT", title: "YouTube", site: "https://www.youtube.com/", color: '#ff0000' },
        ],
        music:[
            { name: "APPLE_M", title: "Apple music", site: "https://music.apple.com/" , color: ''},
            { name: "APPLE_P", title: "Apple podcast", site: "https://www.apple.com/apple-podcasts/" , color: ''},
            { name: "APPLE_M", title: "Amazon Music", site: "https://www.amazon.com/music/" , color: ''},
        ]

    };

    const addSocialHandler = (event, item) => {
        let idItem = socialLinks.items.length
        let addItem = {
            ...item,
            index: idItem + 1,
            order: idItem + 1,
        };

        setSocialLinks({
            ...socialLinks,
            items: [...socialLinks.items, addItem],
        });
    };

    const [modal, setModal] = useState(false);
    const modalCloseHandler = () => setModal(false);
    const modalShowHandler = (item) => {
        setModal(true);
    };

    // ВКонтакте
    // Facebook
    // Twitter
    // Instagram
    // YouTube
    // LinkedIn
    // Twitch
    // Snapchat
    // Одноклассники
    // OnlyFans
    // TikTok
    // Patreon
    // Apple Music
    // Amazon Music
    // Deezer
    // Яндекс.Музыка
    // Spotify
    // Soundcloud
    // BeatStars
    // MixCloud
    // Audiomack
    // Pandora
    // Behance
    // Dribbble
    // Amazon
    // Etsy
    // Shopee
    // Apple Store
    // Google Play

    return (
        <>
            <Modal show={modal} onHide={modalCloseHandler} dialogClassName={styles.modal_size}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавить соц.сеть</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                <h2 className={styles.h2}>Основные социальные сети </h2>
                    <div className={styles.social}>
                        {socials.main.map((item, index) => (
                            <div
                                className={styles.social__button}
                                key={`${item.title}_${index}_btn`}
                                onClick={(event) =>
                                    addSocialHandler(event, item)
                                }
                            >
                               <img src={`/social/brand/${item.name}.png`} width='27' alt={`${item.title}`} /> {item.title}
                            </div>
                        ))}
                    </div>
                <h2 className={styles.h2}>Музыка </h2>
                <div className={styles.social}>
                        {socials.music.map((item, index) => (
                            <div
                                className={styles.social__button}
                                key={`${item.title}_${index}_btn`}
                                onClick={(event) =>
                                    addSocialHandler(event, item)
                                }
                            >
                               <img src={`brand_color_${item.name}.svg`} alt={item.name} /> {item.title}
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalCloseHandler}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className={styles.header}>
                <Col sm={9}></Col>
                <Col sm={3}>
                    <div>
                        <Button variant="warning" onClick={modalShowHandler}>
                            <BsPlusCircle style={{marginBottom: '3px'}} /> Добавить
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AddSocialLink;
