import React from "react";
import { Route, Routes } from "react-router-dom";
import { CreateShop, PageShop, Shop } from "../App/Pages";
import { PATH_MINISHOP } from "../constants/path";

export default function MiniShopRoutes() {
    return (
        <Routes>
            <Route path={PATH_MINISHOP.root} element={<PageShop />} />
            <Route path={PATH_MINISHOP.index} element={<Shop />} />
            <Route path={PATH_MINISHOP.create} element={<CreateShop />} />
        </Routes>
    );
}
