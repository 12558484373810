import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
    createSlide,
    updateSlide,
} from "../../../../redux/storyLanding/storyLanding.action";
import { AddColorComponent } from "../../../Components";

const StoryLandingSlideForm = ({ slide = null }) => {
    const [background, setBackground] = useState(
        slide ? slide.background : { type: "color", data: "#000000" }
    );
    const dispatch = useDispatch();

    const projectId = useParams().id;

    const changeBackground = (e) => {
        setBackground({ ...background, [e.target.name]: e.target.value });
    };
    const handlerBackgroundColor = (hex) => {
        setBackground((prevColor) => ({
            ...prevColor,
            type: "color",
            data: hex,
        }));
    };

    const [backgroundGradient, setBackgroundGradient] = useState(
        {startColor: "#ffffff",  endColor: "#000000", deg: "45deg"}
    )
    const handleBackgroundGradient = (hex) => {

setBackgroundGradient( (prevGradient) => ({
    ...prevGradient,
    startColor: hex,
}));
    
}
        // setBackground((prevColor) => ({
        //     ...prevColor,
        //     type: "gradient",
        // }));
    // }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (slide) {
            dispatch(updateSlide({ ...slide, background }));
        } else {
            dispatch(createSlide({ background, projectId }));
        }
    };

    return (
        <Form>
            {/* "color", "gradient", "image" */}
            <Form.Group className="mb-3">
                <Form.Label>Тип фона</Form.Label>
                <Form.Select name="type" onChange={changeBackground}>
                    <option value="color">Цвет</option>
                    <option value="gradient">Градиент</option>
                    <option value="image">Изображение</option>
                </Form.Select>

                <Form.Label>Фон слайда:</Form.Label>

                {background.type === "color" && (
                    <AddColorComponent
                        color={"#ffffff"}
                        colorHandler={handlerBackgroundColor}
                    />
                )}

                {background.type === "gradient" && (
                    <>
                        <AddColorComponent
                            color={"#ffffff"}
                            name="startColor"
                            colorHandler={handleBackgroundGradient}
                        />

                        <AddColorComponent
                            color={"#000000"}
                            name="endColor"
                            colorHandler={(hex, e) => handleBackgroundGradient(hex, e)}
                        />
                    </>
                )}

                {/* <Form.Control
                    type={background.type === "image" ? "file" : "text"}
                    name="data"
                    value={background.type !== "image" && background.data}
                    onChange={changeBackground}
                /> */}
            </Form.Group>
            <Button type="submit" onClick={handleSubmit}  style={{bottom:'0px'}}>
                {slide ? "Обновить слайд" : "Создать слайд"}
            </Button>
        </Form>
    );
};

export default StoryLandingSlideForm;
