import { useState } from "react";
import noise from "noisejs";

const Canvas = () => {
    const [shapes, setShapes] = useState([]);

    const generateShape = (shapeType, x, y, size, color) => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        const shape = {
            type: shapeType,
            x,
            y,
            size,
            color,
        };

        ctx.beginPath();
        switch (shapeType) {
            case "circle":
                // Generate Perlin noise to distort the circle shape
                const perlin = noise.perlin3(x / 50, y / 50, 0);
                let radius = size / 20 + perlin * 5;
                ctx.arc(x, y, radius, 0, 2 * Math.PI);
                break;
            case "oval":
                // Generate Perlin noise to distort the ellipse shape
                const perlinX = noise.perlin3(x / 50, y / 50, 0);
                const perlinY = noise.perlin3(x / 50, y / 50, 1);
                const rx = size / 2 + perlinX * 5;
                const ry = size / 4 + perlinY * 5;
                ctx.ellipse(x, y, rx, ry, 0, 0, 2 * Math.PI);
                break;
            case "random":
                if (Math.random() > 0.5) {
                    ctx.rect(x - size / 2, y - size / 2, size, size);
                    shape.type = "square";
                } else {
                    const perlin = noise.perlin3(x / 50, y / 50, 0);
                    const radius = size / 2 + perlin * 5;
                    ctx.arc(x, y, radius, 0, 2 * Math.PI);
                    shape.type = "circle";
                }
                break;

            case "topo-oval":
                const noise2 = new noise(Math.random());
                const width = size;
                const height = size / 2;
                const step2 = size / 20;
                for (let angle = 0; angle <= 360; angle += step2) {
                    const xoff = Math.cos((angle / 180) * Math.PI);
                    const yoff = Math.sin((angle / 180) * Math.PI);
                    const w = width + noise2.perlin2(xoff, yoff) * width;
                    const h = height + noise2.perlin2(yoff, xoff) * height;
                    const nx = x + w * Math.cos((angle / 180) * Math.PI);
                    const ny = y + h * Math.sin((angle / 180) * Math.PI);
                    if (angle === 0) {
                        ctx.moveTo(nx, ny);
                    } else {
                        ctx.lineTo(nx, ny);
                    }
                }
                ctx.closePath();
                break;
            default:
                break;
        }
        ctx.fillStyle = color;
        ctx.fill();

        setShapes([...shapes, shape]);
    };

    const handleClick = () => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setShapes([]);

        const numShapes = parseInt(
            prompt("How many shapes to generate?", 10),
            10
        );
        for (let i = 0; i < numShapes; i++) {
            const x = Math.floor(Math.random() * canvas.width);
            const y = Math.floor(Math.random() * canvas.height);
            const size = Math.floor(Math.random() * 50) + 20;
            const color = `rgba(${Math.floor(
                Math.random() * 256
            )}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
                Math.random() * 256
            )}, 1)`;
            const shapeType = ["circle", "square", "oval", "random"][
                Math.floor(Math.random() * 4)
            ];

            generateShape(shapeType, x, y, size, color);
        }
    };

    return (
        <div>
            <canvas id="canvas" width="300" height="300"></canvas>
            <button onClick={handleClick}>Generate shapes</button>
        </div>
    );
};

export default Canvas;
