const MiniShopActionTypes = {
	MINISHOP_CREATE: 'MINISHOP_CREATE',
	MINISHOP_FETCH: 'MINISHOP_FETCH',
	MINISHOP_LOAD: 'MINISHOP_LOAD',
	MINISHOP_REDIRECT: 'MINISHOP_REDIRECT',
	MINISHOP_GET: 'MINISHOP_GET',
	MINISHOP_LOADING: 'MINISHOP_LOADING',
	MINISHOP_UPDATE_LABEL: 'MINISHOP_UPDATE_LABEL',
	MINISHOP_UPDATE_SWITCH: 'MINISHOP_UPDATE_SWITCH',
	MINISHOP_LABEL_CURRENT: 'MINISHOP_LABEL_CURRENT',
}

export default MiniShopActionTypes;