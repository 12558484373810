import OnlineActionTypes from "./online.types";

const initialState = {
    projects: [],
    services: [],
    schedules: [],
    bookings: [],
    blacklist: [],
    cache: {
        projects: null,
        services: null,
        schedules: null,
        bookings: null,
        blacklist: null,
      },
};

const onlineReducer = (state = initialState, action) => {
    switch (action.type) {

        // Projects
        case OnlineActionTypes.FETCH_PROJECT:
            return {
                ...state,
                projects: action.payload.projects,
                cache: {
                    projects: action.payload.cacheTime,
                },
            }
        case OnlineActionTypes.CREATE_PROJECT:
            return {
                ...state,
                projects: [...state.projects, action.payload],
            }
        case OnlineActionTypes.UPDATE_PROJECT:
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project._id === action.payload._id
                        ? action.payload
                        : project
                ),
            }
        case OnlineActionTypes.DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(
                    (project) => project._id !== action.payload
                ),
            }
        // Services
        case OnlineActionTypes.FETCH_SERVICES:
            return { ...state, services: action.payload };
        case OnlineActionTypes.CREATE_SERVICE:
            return { ...state, services: [...state.services, action.payload] };
        case OnlineActionTypes.UPDATE_SERVICE:
            return {
                ...state,
                services: state.services.map((service) =>
                    service._id === action.payload._id
                        ? action.payload
                        : service
                ),
            };
        case OnlineActionTypes.DELETE_SERVICE:
            return {
                ...state,
                services: state.services.filter(
                    (service) => service._id !== action.payload
                ),
            };

        // Schedules
        case OnlineActionTypes.FETCH_SCHEDULES:
            return { ...state, schedules: action.payload };
        case OnlineActionTypes.CREATE_SCHEDULE:
            return {
                ...state,
                schedules: [...state.schedules, action.payload],
            };
        case OnlineActionTypes.UPDATE_SCHEDULE:
            return {
                ...state,
                schedules: state.schedules.map((schedule) =>
                    schedule._id === action.payload._id
                        ? action.payload
                        : schedule
                ),
            };
        case OnlineActionTypes.DELETE_SCHEDULE:
            return {
                ...state,
                schedules: state.schedules.filter(
                    (schedule) => schedule._id !== action.payload
                ),
            };

        // Bookings
        case OnlineActionTypes.FETCH_BOOKINGS:
            return { ...state, bookings: action.payload };
        case OnlineActionTypes.CREATE_BOOKING:
            return { ...state, bookings: [...state.bookings, action.payload] };
        case OnlineActionTypes.UPDATE_BOOKING:
            return {
                ...state,
                bookings: state.bookings.map((booking) =>
                    booking._id === action.payload._id
                        ? action.payload
                        : booking
                ),
            };
        case OnlineActionTypes.DELETE_BOOKING:
            return {
                ...state,
                bookings: state.bookings.filter(
                    (booking) => booking._id !== action.payload
                ),
            };

        default:
            return state;
    }
};

export default onlineReducer;
