import React, { useState } from "react";

import { Card, Form,} from "react-bootstrap";


function AddCategory({ category, setCategory }) {

    const handleCategory = (e) => {
        setCategory((prevCategory) => [
            ...prevCategory,
            {
                _id: category._id,
                categoryName: category.categoryName,
                slug: category.slug,
                categoryKey: category.categoryKey,
                categoryDescription: category.categoryDescription,
                categoryShow: category.categoryShow,
            },
        ]);
    }

    
    return (
        <Card className="mt-2">
            <Card.Body>
                <Form >
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Название категории</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите название категории"
                            value={category?.categoryName}
                            onChange={(e) => handleCategory(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Описание категории</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите описание категории"
                            value={category?.categoryDescription}
                            onChange={(e) => handleCategory(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Ключевые слова категории</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите ключевые слова категории"
                            value={category?.categoryKey}
                            onChange={(e) => handleCategory(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Статус категории</Form.Label>
                        <Form.Switch
                            value={category?.categoryShow}
                            onChange={(e) => handleCategory(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}


export default AddCategory;
