import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getMultilink } from "../../../../../redux/multilink/multilink.action";

function SettingMultilinkAccess() {
    
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userAuthInfo);
    const project = useSelector(({ multilink }) => multilink.multilink.mainMultilink);
    
    useEffect(() => {
        const projectId = window.location.pathname.toString().split("/");

        dispatch(getMultilink(projectId[3], user.id));
    }, [user.id]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email пользователя</Form.Label>
                <Form.Control type="email" placeholder="Укажите email" />
                <Form.Text className="text-muted">
                    Вам нужно указать email зарегистрированного пользователя
                </Form.Text>
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
}

export default SettingMultilinkAccess;
