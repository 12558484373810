import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AddColorComponent } from "../../../../../Components";
import styles from "./PagePriceListDesign.module.scss";

function PagePriceListDesign({ priceList, setPriceList }) {
    const colorTextHandler = (hex) => {
        setPriceList({
            ...priceList,
            design: {
                ...priceList.design,
                color: hex,
            },
        });
    };
    const colorBorderHandler = (hex) => {
        setPriceList({
            ...priceList,
            design: {
                ...priceList.design,
                color_border: hex,
            },
        });
    };
    const checkboxHandler = () => {
        setPriceList({
            ...priceList,
            design: {
                ...priceList.design,
                border: !priceList.design.border, 
            },
        });
    };

    return (
        <>
            <Row className={`${styles.background_block} ${styles.text}`}>
                <Col sm={8}>Цвет текста</Col>
                <Col>
                    <AddColorComponent
                        color={
                            priceList.design
                                ? priceList.design.color
                                : "#000000"
                        }
                        colorHandler={colorTextHandler}
                    />
                </Col>
            </Row>
            <Row className={styles.background_block}>
                <Col sm={8}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        name="border"
                        className="mt-1"
                        label="Разделитель"
                        defaultChecked={priceList.design.border}
                        onClick={checkboxHandler}
                    />
                </Col>
                <Col>
                    {priceList.design.border && (
                        <AddColorComponent
                            color={
                                priceList.design
                                    ? priceList.design.color_border
                                    : "#cfcfcf"
                            }
                            colorHandler={colorBorderHandler}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
}

export default PagePriceListDesign;
