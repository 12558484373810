export const STORYLANDING_EN = {
    projectForm: {
        projectName: "Название проекта:",
        projectDescription: "Описание проекта:",
        projectFavicon: "Фавикон проекта:",
        createProject: "Создать проект",
        updateProject: "Обновить проект",
    },
    saveProject: {
        saveAsImage: "Сохранить проект как изображение",
    },
    publishProject: {
        publish: "Опубликовать проект",
    },
};
