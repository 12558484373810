import { ProfileService, MoneyService } from '../../services'
import ProfileActionTypes from './profile.types'
import UserActionTypes from '../user/user.types';


export const editProfile = (FormData, id) => {

	return async dispatch => {
		const response = await ProfileService.edit(FormData, id);
		dispatch({
			type: ProfileActionTypes.PROFILE_EDIT,
			payload: response.data
		})
		dispatch({ 
			type: UserActionTypes.USER_INFO,
			payload: response.data	
		})
	}
}

export const addMoney = (amount, id) => {
	
	return async dispatch => {

		const response = await MoneyService.addMoney(amount, id);

		if(response.data.type === 'redirect') {
			window.location.replace(response.data.confirmation_url);

		}


	}
}

export const fetchInvoices = (id) => {
	return async dispatch => {
		const response = await MoneyService.fetchInvoices(id);
		dispatch({
			type: ProfileActionTypes.PROFILE_INVOICES,
			payload: response.data
		})
	}
}

export const checkInvoice = (data, id) => {
	return async dispatch => {
		await MoneyService.checkInvoice(data, id);
		await dispatch(fetchInvoices(id))
		// dispatch({
		// 	type: ProfileActionTypes.PROFILE_INVOICE,
		// 	payload: response.data
		// })
	}
}
export const getInvoice = (user, link) => {
	return async dispatch => {
		const response = await MoneyService.getInvoice(user, link);

		
		dispatch({
			type: ProfileActionTypes.PROFILE_INVOICE,
			payload: response.data
		})
		dispatch(fetchInvoices(user._id))
	}
}
	