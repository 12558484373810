import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editProfile } from "../../../../redux/profile/profile.action";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
// import "react-image-crop/src/ReactCrop.scss";
import styles from "./EditPage.module.scss";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    PixelRatio,
} from "react-image-crop";

function EditPage() {
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userInfo);

    // email: { type: String, unique: true, required: true },
    // password: { type: String },
    // isActivated: { type: Boolean, default: false },
    // activationLink: { type: String },
    // roles: [{ type: String, ref: 'Role' }],
    // googleId: { type: String },
    // avatar: { type: String },
    // avatarType: { type: String, default: 'local' }, // remote or local
    // login: { type: String },
    // firstName: { type: String },
    // lastName: { type: String },
    // language: { type: String },

    const [stateProfile, setStateProfile] = useState({
        // поле с избражением должно быть последним
        id: "",
        login: "",
        avatarType: "",
        firstName: "",
        lastName: "",
        language: "",
        googleId: "",
        password: "",
        avatar: "",
    });


    const handlerFile = (event) => {
        onSelectFile(event);
        // setStateProfile({
        //     ...stateProfile,
        //     avatar: event.target.files[0],
        //     avatarType: event.target.files[0] ? "local" : "",
        // });
    };

    const handleUpdateProfile = (event) => {
        event.preventDefault();

        let formData = new FormData();
        for (let dataKey in stateProfile) {

            formData.append(dataKey, stateProfile[dataKey]);
        }
        dispatch(editProfile(formData, stateProfile.id));
        setStateProfile({ ...stateProfile, avatar: "" });
    };

    useEffect(() => {
        setStateProfile({ ...stateProfile, id: user._id });
        // imgRef.current = value;
    }, []);

    /////////////////////////////
    const [crop, setCrop] = useState({
        unit: "%",
        width: 500,
        height: 500,
        aspect: 1 / 1,
    });
    const [imgSrc, setImgSrc] = useState("");
    const imgRef = useRef(null);
    const canvasRef = useRef(null);
    const croppedImage = useRef(null);

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setImgSrc(reader.result));
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {

        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 50,
                    height: 50,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    }

    const onLoad =  async (img) => {
        // устанавливаем кроп по центру
        if (crop.aspect) {
            const { width, height } = img.currentTarget;
            setCrop(centerAspectCrop(width, height, crop.aspect));
        }

        imgRef.current = img.target;
    };

    const onCropComplete = async (crop) => {
        await makeClientCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imgRef.current) {
            croppedImage.current = await getCroppedImg(
                imgRef.current,
                crop,
                "avatar.jpeg"
            );
            setStateProfile({
                ...stateProfile,
                avatar: croppedImage.current,
                avatarType: croppedImage.current ? "local" : "",
            });
        }
    };

    const pixelRatio = window.devicePixelRatio || 1;

    const getCroppedImg = (image, crop, fileName) => {
        // console.log("pixelRatio", image.naturalWidth / image.width);

        if (!canvasRef.current || !imgRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    // reject(new Error("Canvas is empty"));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, "image/jpeg");
        });
    };

    useMemo(() => {
        if (imgRef.current) { 

           setCrop(centerAspectCrop(1000, imgRef.current.naturalHeight, crop.aspect))
           
// makeClientCrop(kek)
            // makeClientCrop({
            //     "x": imgRef.current.naturalHeight / 100 * crop.x,
            //     "y": imgRef.current.naturalHeight / 3 * 2,
            //     "width": imgRef.current.naturalHeight,
            //     "height": imgRef.current.naturalHeight,
            //     "unit": "px"
            // });
        }

    }, [imgSrc.current, imgRef.current]);

    return (
        <div>
            <Row>
                <Col sm={8}>
                    <div className={styles.setting}>
                        <Form>
                            <h3>Аватар</h3>
                            <div
                            // style={{ width: "280px" }}
                            >
                                <ReactCrop
                                    aspect={1}
                                    crop={crop}
                                    src={imgSrc}
                                    onChange={(c) => setCrop(c)}
                                    // onComplete={(c) => setCompletedCrop(c)}
                                    onComplete={onCropComplete}
                                    // onImageLoaded={(img) => onLoad(img)}
                                >
                                    <img
                                        src={imgSrc}
                                        // onLoad={onImageLoad}
                                        onLoad={onLoad}
                                        
                                        alt="avatar"
                                    />
                                </ReactCrop>


                            </div>


                            <canvas
                                width="350"
                                height="350"
                                style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: 150,
                                    height: 150,
                                }}
                                ref={canvasRef}
                            />
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Загрузить аватар</Form.Label>

                                <Form.Control
                                    type="file"
                                    placeholder="Аватар"
                                    name="avatar"
                                    accept="image*,.png,.jpg,.jpeg,.gif,.webp"
                                    encType="multipart/form-data"
                                    onChange={handlerFile}
                                />
                            </Form.Group>
                            {stateProfile.avatar && (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={handleUpdateProfile}
                                >
                                    Сохранить
                                </Button>
                            )}
                        </Form>
                    </div>

                    <div className={styles.setting}>
                        <Form>
                            <h3>Изменить email</h3>

                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Новый email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Введите новый email"
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Сохранить
                            </Button>
                        </Form>
                    </div>

                    <div className={styles.setting}>
                        <Form className={styles.form}>
                            <h3>Изменить Пароль</h3>

                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Новый пароль</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Введите новый пароль"
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <Form.Label>
                                    Подтвердите новый пароль
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Повторите ввод"
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Сохранить
                            </Button>
                        </Form>
                    </div>

                    <div className={styles.setting}>
                        <h3>Привязанные социальные сети авторизации</h3>
                        <Card body className={styles.social}>
                            Для авторизации с помощью <b>Google</b> исользуется
                            аккаунт -{" "}
                        </Card>
                        <Card body className={styles.social}>
                            Для авторизации с помощью <b>VK</b> исользуется
                            аккаунт -{" "}
                        </Card>
                        <Card body className={styles.social}>
                            Для авторизации с помощью <b>FaceBook</b>{" "}
                            исользуется аккаунт -{" "}
                        </Card>
                        <Card body className={styles.social}>
                            Для авторизации с помощью <b>Instagram</b>{" "}
                            исользуется аккаунт -{" "}
                        </Card>
                    </div>
                    <div className={`${styles.setting} ${styles.logout__all}`}>
                        <h3>Сбросить авторизацию на всех устройствах</h3>
                        <Button variant="primary" type="submit">
                            Сбросить
                        </Button>
                    </div>
                </Col>
                <Col sm={4}></Col>
            </Row>
        </div>
    );
}

export default memo(EditPage);

// const [imgSrc, setImgSrc] = useState("");
//     const [aspect, setAspect] = useState(1);
//     const [completedCrop, setCompletedCrop] = useState();
//     const [crop, setCrop] = useState({
//         unit: "px",
//         width: 250,
//         height: 250,
//         x: 252,
//         y: 252,
//     });

//     function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
//         return centerCrop(
//             makeAspectCrop(
//                 {
//                     unit: "%",
//                     width: 50,
//                     height: 50,
//                 },
//                 aspect,
//                 mediaWidth,
//                 mediaHeight
//             ),
//             mediaWidth,
//             mediaHeight
//         );
//     }

//     function onSelectFile(event) {
//         if (event.target.files && event.target.files.length > 0) {
//             console.log('kkkkkkkkkkkkk');
//             setCrop(undefined); // Makes crop preview update between images.
//             const reader = new FileReader();
//             reader.addEventListener("load", () =>
//                 setImgSrc(reader.result.toString() || "")
//             );
//             reader.readAsDataURL(event.target.files[0]);

//         }
//     }

//     function onImageLoad(event) {
//         if (aspect) {
//             const { width, height } = event.currentTarget;
//             setCrop(centerAspectCrop(width, height, aspect));
//             cropImageNow()
//         }
//     }

//     // console.log('crop', crop.width);
//     // console.log(completedCrop);
//     const [output, setOutput] = useState(null);
//     const cropImageNow = (crop) => {
//         console.log('aspect',crop);
//         const canvas = document.createElement('canvas');
//         const scaleX = imgSrc.naturalWidth / imgSrc.width;
//         const scaleY = imgSrc.naturalHeight / imgSrc.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext('2d');

//         const pixelRatio = window.devicePixelRatio;
//         canvas.width = aspect.width * pixelRatio;
//         canvas.height = aspect.height * pixelRatio;
//         ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
//         ctx.imageSmoothingQuality = 'high';

//         ctx.drawImage(
//         imgSrc,
//         aspect.x * scaleX,
//         aspect.y * scaleY,
//         aspect.width * scaleX,
//         aspect.height * scaleY,
//         0,
//         0,
//         aspect.width,
//         aspect.height,
//         );

//         // Converting to base64

//         const base64Image = canvas.toDataURL('image/jpeg');
//         console.log(base64Image);
//         setOutput(base64Image);
//     };
//     console.log('output', output);
