import React, { useState, useRef } from "react";

import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { BsTextCenter, BsTextRight, BsTextLeft } from "react-icons/bs";
import { MdTextFields, MdTitle } from "react-icons/md";
import { AddColorComponent } from "../../../../../Components";

function PageTextMain({ text, setText }) {
    const buttonHandler = (event) => {
        setText({
            ...text,
            main: {
                ...text.main,
                [event.target.name]: event.target.value,
            },
        });
    };
    const colorBgHandler = (hex) => {
        setText({
            ...text,
            main: {
                ...text.main,
                color: hex
            },
        });
    };

    // изменение цвета кнопки
    const [buttonVariant, setButtonVariant] = useState(false);
    const target = useRef(null);

    const handleClick = () => {
        setButtonVariant(!buttonVariant);
    };

    return (
        <>
            <Row className="mb-3">
                <Col sm={2}>
                    <Form.Select
                        size="sm"
                        name="size"
                        value={text.main.size}
                        onChange={buttonHandler}
                    >
                        <option>10px</option>
                        <option>12px</option>
                        <option>14px</option>
                        <option>16px</option>
                        <option>18px</option>
                        <option>20px</option>
                        <option>22px</option>
                        <option>24px</option>
                    </Form.Select>
                </Col>

                <Col sm={2}>
                    <Form.Select size="sm">
                        <option>Roboto</option>
                    </Form.Select>
                </Col>

                <Col sm={2}>
                    <Form.Select
                        size="sm"
                        name="weight"
                        value={text.main.weight}
                        onChange={buttonHandler}
                    >
                        <option value="300">Тонкий</option>
                        <option value="400">Обычный</option>
                        <option value="900">Жирный</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={3}>
                    <AddColorComponent
                        color={text.main.color}
                        colorHandler={colorBgHandler}
                    />
                </Col>
                <Col sm={2}>
                    <ButtonGroup size="sm">
                        <Button
                            variant={
                                text.main.textAlign === "left"
                                    ? "primary"
                                    : "light"
                            }
                            name="textAlign"
                            value="left"
                            onClick={buttonHandler}
                        >
                            <BsTextLeft style={{ pointerEvents: "none" }} />
                        </Button>
                        <Button
                            variant={
                                text.main.textAlign === "center"
                                    ? "primary"
                                    : "light"
                            }
                            name="textAlign"
                            value="center"
                            onClick={buttonHandler}
                        >
                            <BsTextCenter style={{ pointerEvents: "none" }} />
                        </Button>
                        <Button
                            variant={
                                text.main.textAlign === "right"
                                    ? "primary"
                                    : "light"
                            }
                            name="textAlign"
                            value="right"
                            onClick={buttonHandler}
                        >
                            <BsTextRight style={{ pointerEvents: "none" }} />
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col sm={2}>
                    <ButtonGroup size="sm">
                        <Button
                            variant={
                                text.main.transform === "none"
                                    ? "primary"
                                    : "light"
                            }
                            name="transform"
                            value="none"
                            onClick={buttonHandler}
                        >
                            <MdTextFields style={{ pointerEvents: "none" }} />
                        </Button>{" "}
                        <Button
                            variant={
                                text.main.transform === "uppercase"
                                    ? "primary"
                                    : "light"
                            }
                            name="transform"
                            value="uppercase"
                            onClick={buttonHandler}
                        >
                            <MdTitle style={{ pointerEvents: "none" }} />
                        </Button>{" "}
                    </ButtonGroup>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col sm={8}>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Введите текст "
                            name="text"
                            defaultValue={text.main.text}
                            onChange={buttonHandler}
                            as="textarea"
                            rows={3}
                        />

                    </Form.Group>
                </Col>
            </Row> 
        </>
    );
}

export default PageTextMain;
