import React from "react";
import { Modal, Button } from 'react-bootstrap';

function SaveModalComponent({showModal, handleModalClose, handleModalCloseNoSave}) {
    return (
        <Modal show={showModal} onHide={handleModalClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Внимание!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Есть не сохранённые данные! Вы действительно хотите закрыть
                окно?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCloseNoSave}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleModalClose}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SaveModalComponent;
