import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.container.design_block.background_color};

    font-size: 16px;
    line-height: 20px;
    padding: ${(props) => props.container.design_block.background_padding.top}px
        ${(props) => props.container.design_block.background_padding.right}px
        ${(props) => props.container.design_block.background_padding.bottom}px
        ${(props) => props.container.design_block.background_padding.left}px;
    margin: ${(props) => props.container.design_block.background_margin.top}px
        ${(props) => props.container.design_block.background_margin.right}px
        ${(props) => props.container.design_block.background_margin.bottom}px
        ${(props) => props.container.design_block.background_margin.left}px;

    &:after {
        content: "";
        filter: brightness(
                ${(props) => props.container.design_block.brightness}%
            )
            blur(${(props) => props.container.design_block.blur}px)
            opacity(${(props) => props.container.design_block.opacity}%);
    }
`;

