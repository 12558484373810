import MinishopActionTypes from "./minishop.types";

const INITIAL_STATE = {
    minishopCreate: {},
    minishopItems: {},
    //     minishopError: {},
    //     minishopCreate: false,
    minishopLoading: true,
    minishopsFetch: [],

    minishopLabelCurrent: {},
    //     minishopRedirect: null,
    //     minishopItemCurrent: {},
};

const minishopReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MinishopActionTypes.MINISHOP_CREATE:
            return {
                ...state,
                minishopCreate: action.payload,
            };
        case MinishopActionTypes.MINISHOP_FETCH:
            return {
                ...state,
                minishopsFetch: action.payload,
            };
        case MinishopActionTypes.MINISHOP_GET:
            return {
                ...state,
                minishopItems: action.payload,
            };
        case MinishopActionTypes.MINISHOP_UPDATE_LABEL:
            return {
                ...state,
                minishopItems: {...state.minishopItems, labels: action.payload},
            };
        case MinishopActionTypes.MINISHOP_UPDATE_SWITCH:
            return {
                ...state,
                minishopItems: {...state.minishopItems, labels: action.payload},
            }
        case MinishopActionTypes.MINISHOP_LOADING:
            return {
                ...state,
                minishopLoading: action.payload,
            };
        case MinishopActionTypes.MINISHOP_LABEL_CURRENT:
            return {
                ...state,
                minishopLabelCurrent: action.payload,
            };
        default:
            return state;
    }
};

export default minishopReducer;
