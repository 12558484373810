import MiniShopService from "../../services/MiniShopService";
import MiniShopActionTypes from "./minishop.types";

export const createMiniShop = (store) => {
    return async (dispatch) => {
        const response = await MiniShopService.create(store);
        console.log("createMiniShop", response.data);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_CREATE,
            payload: response.data,
        });
        dispatch({
            type: MiniShopActionTypes.MINISHOP_REDIRECT,
            payload: response.data._id,
        });
    };
};

export const fetchMinishops = (user) => {
    return async (dispatch) => {
        const response = await MiniShopService.fetch(user);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_FETCH,
            payload: response.data,
        });
    };
};

export const getMiniShop = (projectId, userId) => {
    return async (dispatch) => {
        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: true,
        });
        const response = await MiniShopService.get(projectId, userId);

        await dispatch({
            type: MiniShopActionTypes.MINISHOP_GET,
            payload: response.data.getMinishop,
        });
        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: false,
        });
    };
};
/////
export const minishopLabelAdd = (data) => {

    return async (dispatch) => {
    
        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: true,
        })

        const response = await MiniShopService.minishopLabelAdd(data);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_UPDATE_LABEL,
            payload: response.data.labels,
        });

        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: false,
        })
    };
};

export const minishopLabelRemove = (data) => {
    return async (dispatch) => {

        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: true,
        })

        const response = await MiniShopService.minishopLabelRemove(data);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_UPDATE_LABEL,
            payload: response.data,
        });

        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: false,
        })
    };
};

export const minishopLabelCopy = (data) => {
    return async (dispatch) => {
        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: true,
        })
        const response = await MiniShopService.minishopLabelCopy(data);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_UPDATE_LABEL,
            payload: response.data,
        });
        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: false,
        })
    };
};

export const minishopLabelSwitch = (data) => {
    //write code update switchEdit on server
    

    return async (dispatch) => {

        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: true,
        })

        const response = await MiniShopService.minishopLabelSwitch(data);
        console.log("minishopLabelSwitch", response.data);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_UPDATE_LABEL,
            payload: response.data,
        });

        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: false,
        })

    };
};
/////

export const minishopCategoryAdd = (data) => {

    return async (dispatch) => {
    
        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: true,
        })

        const response = await MiniShopService.minishopLabelAdd(data);
        dispatch({
            type: MiniShopActionTypes.MINISHOP_UPDATE_LABEL,
            payload: response.data.labels,
        });

        dispatch({
            type: MiniShopActionTypes.MINISHOP_LOADING,
            payload: false,
        })
    };
};

/////


export const fetchShops = (store) => {
    // return async dispatch => {
    // 	const response = await MiniShopService.create(store)
    // 	dispatch({
    // 		type:MiniShopActionTypes.MINISHOP_CREATE,
    // 		payload: response.data
    // 	})
    // 	dispatch({
    // 		type: MiniShopActionTypes.MINISHOP_REDIRECT,
    // 		payload: response.data._id
    // 	})
    // }
};
export const copyShop = (store) => {
    // return async dispatch => {
    // 	const response = await MiniShopService.create(store)
    // 	dispatch({
    // 		type:MiniShopActionTypes.MINISHOP_CREATE,
    // 		payload: response.data
    // 	})
    // 	dispatch({
    // 		type: MiniShopActionTypes.MINISHOP_REDIRECT,
    // 		payload: response.data._id
    // 	})
    // }
};
export const removeShop = (store) => {
    // return async dispatch => {
    // 	const response = await MiniShopService.create(store)
    // 	dispatch({
    // 		type:MiniShopActionTypes.MINISHOP_CREATE,
    // 		payload: response.data
    // 	})
    // 	dispatch({
    // 		type: MiniShopActionTypes.MINISHOP_REDIRECT,
    // 		payload: response.data._id
    // 	})
    // }
};
