import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Row } from "react-bootstrap";
import styles from "./ShopDashboard.module.scss";
import { NavLink } from "react-router-dom";
import ShopDashboadItems from "./ShopDashboadItems/ShopDashboadItems";

function ShopDashboard({ minishopsFetch }) {
    if (!minishopsFetch) {
        return (
            <div className={styles.container}>
                <Alert variant="danger">
                    An error has occurred while fetching the minishops.
                </Alert>
            </div>
        );
    } else if (minishopsFetch && minishopsFetch.length > 0) {
        return (
            <div className={styles.container}>
                {minishopsFetch.map((minishop, index) => (
                    <ShopDashboadItems
                        minishop={minishop}
                        key={`${index}_dashboard_minishop_items`}
                    />
                ))}
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <Row>
                    <Col sm={8}>
                        <div className={` ${styles.no_create_shop}`}>
                            Вы можете начать продавать свои товары, создав
                            магазин. Нажмите на кнопку "Создать магазин" ниже.
                            <Button
                                as={NavLink}
                                variant="primary"
                                className={styles.create_shop_btn}
                                to="/minishop/create"
                            >
                                Создать магазин
                            </Button>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <Alert variant="info">
                            Вы можете создать минимагазин и встроить его в свою
                            мультиссылку <hr />А так же у Вас есть возможность
                            использовать минимагазин как отдельный продукт{" "}
                        </Alert>
                    </Col>
                </Row>
            </div>
        );
    }
}

ShopDashboard.propTypes = {
    minishopsFetch: PropTypes.array,
    minishopsFetch: PropTypes.shape({
        minishop: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired
        })
    })
}

export default ShopDashboard;