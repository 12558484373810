import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";

function PageShopSetting() {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    return (
        <div>
            <Modal
                show={showModal}
                onHide={() => {
                    handleCloseModal();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Настройки магазина</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="mb-3">
                        <Card.Body>
                            <Row>
                                <Col sm={6}>Валюта магазина</Col>
                                <Col sm={6}>
                                    <Form.Control as="select">
                                        <option>Рубли</option>
                                        <option>Доллары</option>
                                        <option>Евро</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-3">
                        <Card.Body>
                            Настройки заявок
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Имя"
                                checked
                            />
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Телефон"
                                checked
                            />
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Почта"
                                checked
                            />
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Адрес"
                            />
                        </Card.Body>
                    </Card>

                    <Card className="mb-3">
                        <Card.Body>
                            <Row>
                                <Col sm={4}>После заказа</Col>
                                <Col sm={8}>
                                    <Form.Control as="select">
                                        <option>Открыть страницу благодарности за заказ</option>
                                        {/* <option>Переход на страницу магазина</option> */}
                                    </Form.Control>

                                    </Col>
                                    
                            </Row>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleCloseModal();
                        }}
                    >
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={() => {}}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button variant="primary" onClick={handleShowModal}>
                Открыть
            </Button>
        </div>
    );
}

export default PageShopSetting;
