import { Route, Routes } from "react-router-dom";
import Online from "../App/Pages/Online/Online";



export default function OnlineRoutes() {
    return (
        <Routes>
            <Route index element={<Online />} />
        </Routes>
    );
}