// import AuthService from '../../services/AuthService';
import { AuthService, UserService } from "../../services/index";
import axios from "axios";
import { API_URL } from "../../http";

import UserActionTypes from "./user.types";

export const userAuth = (user) => {
    return async (dispatch) => {
        const response = await AuthService.login(user);
        localStorage.setItem("token", response.data.accessToken);
        console.log(response.data);
        dispatch({
            type: UserActionTypes.USER_AUTH_INFO,
            payload: response.data.user,
        });
        dispatch({
            type: UserActionTypes.USER_INFO,
            payload: response.data.userInfo,
        });
        dispatch({
            type: UserActionTypes.USER_AUTH,
            payload: true,
        });
        dispatch({
            type: UserActionTypes.USER_CHECK,
            payload: true,
        });
    };
};

export const userInfo = (data) => ({
    type: UserActionTypes.USER_INFO,
    data,
});

export const userCheck = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: UserActionTypes.USER_LOADING,
                payload: false,
            });
            // console.log('API_URL', API_URL);
            // const response = await axios.get(`${API_URL}/auth/refresh`, {
            //     withCredentials: true,
            // });
            const response = await AuthService.refresh();
            
            await localStorage.setItem("token", response.data.accessToken);
           
            dispatch({
                type: UserActionTypes.USER_AUTH_INFO,
                payload: response.data.user,
            });
            dispatch({
                type: UserActionTypes.USER_INFO,
                payload: response.data.userInfo,
            });
            dispatch({
                type: UserActionTypes.USER_AUTH,
                payload: true,
            });
            dispatch({
                type: UserActionTypes.USER_CHECK,
                payload: true,
            });
            dispatch({
                type: UserActionTypes.USER_LOADING,
                payload: true,
            });
        } catch (e) {
            dispatch({
                type: UserActionTypes.USER_LOADING,
                payload: true,
            });
        } finally {
            // this.setLoading(false);
        }
    };
};

export const usersGet = () => {
    return async (dispatch) => {
        try {
            const response = await UserService.fetchUsers();

            dispatch({
                type: UserActionTypes.USER_GET,
                // payload: response.data
            });
            dispatch({
                type: UserActionTypes.USER_ALL,
                payload: response.data,
            });
        } catch (e) {
            console.log(e.response?.data?.message);
            // dispatch(userAuth(false))
        } finally {
            // this.setLoading(false);
        }
    };
};

export const usersAll = (data) => ({
    type: UserActionTypes.USER_ALL,
    payload: data,
});

export const logout = () => {
    return async (dispatch) => {
        try {
            await AuthService.logout();
            localStorage.removeItem("token");
            dispatch({
                type: UserActionTypes.USER_AUTH,
                payload: false,
            });
            dispatch({
                type: UserActionTypes.USER_CHECK,
                payload: false,
            });
            dispatch({
                type: UserActionTypes.USER_AUTH_INFO,
                payload: {},
            });
            dispatch({
                type: UserActionTypes.USER_LOGOUT,
                // payload: data
            });
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    };
};
