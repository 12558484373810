const MultilinkActionTypes = {
	MULTILINK_CREATE: 'MULTILINK_CREATE',
	MULTILINK_LOADING: 'MULTILINK_LOADING',
	MULTILINK_REDIRECT: 'MULTILINK_REDIRECT',
	MULTILINK_GET: 'MULTILINK_GET',
	MULTILINK_REMOVE: 'MULTILINK_REMOVE',
	MULTILINK_COPY: 'MULTILINK_COPY',
	MULTILINKS_FETCH: 'MULTILINKS_FETCH',
	MULTILINK_UPDATE: 'MULTILINK_UPDATE',
	MULTILINK_UPDATE_SETTING: 'MULTILINK_UPDATE_SETTING',
	MULTILINK_UPDATE_SLUG: 'MULTILINK_UPDATE_SLUG',
	MULTILINK_ERROR_SLUG: 'MULTILINK_ERROR_SLUG',
	MULTILINK_CURRENT_ITEM: 'MULTILINK_CURRENT_ITEM'
}

export default MultilinkActionTypes;