import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
    createProject,
    updateProject,
} from "../../../redux/storyLanding/storyLanding.action.js";

const StoryLandingProjectForm = ({ project = null }) => {
    const [name, setName] = useState(project ? project.name : "");
    const [description, setDescription] = useState(
        project ? project.description : ""
    );
    const [favicon, setFavicon] = useState(project ? project.favicon : "");
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (project) {
            dispatch(updateProject({ ...project, name, description, favicon }));
        } else {
            dispatch(createProject({ name, description, favicon }));
        }
    };

    return (
        <>
            <Form>
                <Form.Group>
                    <Form.Label>Название проекта:</Form.Label>
                    <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Описание проекта:</Form.Label>
                    <Form.Control
                        as="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Фавикон проекта:</Form.Label>
                    <Form.Control
                        type="file"
                        value={favicon}
                        onChange={(e) => setFavicon(e.target.value)}
                    />
                </Form.Group>
            </Form>
            <Button type="submit" onClick={handleSubmit}>
                {project ? "Обновить проект" : "Создать проект"}
            </Button>
        </>
    );
};

export default StoryLandingProjectForm;
