import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.container.design_block.background_color};
    font-size: 21px;
    padding: ${(props) => props.container.design_block.background_padding.top}px 
    ${(props) => props.container.design_block.background_padding.right}px
    ${(props) => props.container.design_block.background_padding.bottom}px
    ${(props) => props.container.design_block.background_padding.left}px
    ;
    margin: ${(props) => props.container.design_block.background_margin.top}px 
    ${(props) => props.container.design_block.background_margin.right}px
    ${(props) => props.container.design_block.background_margin.bottom}px
    ${(props) => props.container.design_block.background_margin.left}px
    ;
    
    &:after {    
        content: "";
        filter: brightness(${(props) => props.container.design_block.brightness}%) 
        blur(${(props) => props.container.design_block.blur}px) 
        opacity(${(props) => props.container.design_block.opacity}%)  
    }

`;

export const Button = styled.div`
    background-color: ${(props) => props.container.design_link.btn_color };
    color: ${(props) => props.container.design_link.color };
    width: calc(100%-5px);
    height: 100%;
    margin: 5px;
    padding: 7px;
    display: flex;
    flex-direction: ${(props) => (props.container.main.iconPosition === 'right' && 'row') ||  (props.container.main.iconPosition === 'left' && 'row-reverse')};
    // text-align:center;
    justify-content: space-between; 
    align-items: center;
    gap:5px;
    border-radius: ${(props) => (props.container.design_link.shape_button === 'none' && '0px') || (props.container.design_link.shape_button === 'rounded' && '6px') || (props.container.design_link.shape_button === 'circle' && '180px') };
    `;

export const TextBlock = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align: ${(props) => props.container.main.textPosition };
    flex: 1 1 auto;
    padding-left: ${(props) => props.container.main.textPosition === 'left' ? '10px' : 'none' }; 
    padding-right: ${(props) => props.container.main.textPosition === 'right' ? '10px' : 'none' }; 
`

export const TitleBlock = styled.div`
    width:100%;
    text-align:inherit;
    font-size: 16px;
`

export const DescriptionBlock = styled.div`
    width:100%;
    text-align:inherit;
    font-size: 12px;
    padding: 0px 1px;
`
export const IconBlock = styled.div`

`
