import { MultilinkService } from '../../services'
import MultilinkActionTypes from './multilink.types'

export const createMultilink = (multilink) => {
	
	return async dispatch => {
		const response = await MultilinkService.create(multilink)
		dispatch({
			type: MultilinkActionTypes.MULTILINK_CREATE,
			payload: response.data
		})
		dispatch({
			type: MultilinkActionTypes.MULTILINK_REDIRECT,
			payload: response.data._id
		})
	}
}

export const fetchMultilinks = (user) => {
	return async dispatch => {
		const response = await MultilinkService.fetch(user)
		dispatch({
			type: MultilinkActionTypes.MULTILINKS_FETCH,
			payload: response.data
		})
	}
}
export const getMultilink = (projectId, userId) => {
	return async dispatch => {
		dispatch({
			type: MultilinkActionTypes.MULTILINK_LOADING,
			payload: true
		})
		const response = await MultilinkService.get(projectId, userId)
		
		await dispatch({
			type: MultilinkActionTypes.MULTILINK_GET,
			payload: response.data.getMultilink
		})
		dispatch({
			type: MultilinkActionTypes.MULTILINK_LOADING,
			payload: false
		})
	}
}

export const removeMultilink = (id, user) => {
	return async dispatch => {
		const response = await MultilinkService.remove(id, user.id)
		dispatch({
			type: MultilinkActionTypes.MULTILINK_REMOVE,
			payload: response.data
		})

		dispatch(fetchMultilinks(user))
	}
}
export const copyMultilink = (id, user) => {
	console.log('copyMultilink', id, user)
	return async dispatch => {
		const response = await MultilinkService.copy(id, user.id)
		dispatch({
			type: MultilinkActionTypes.MULTILINK_COPY,
			payload: response.data
		})

		dispatch(fetchMultilinks(user))
	}
}
// export const currentMultilink = (item)  => ({
// 	type: MultilinkActionTypes.MULTILINK_CURRENT_ITEM,
// 	payload: item
//   })
export const currentMultilink = (item)  => {
	return async dispatch => {

		dispatch({
			type: MultilinkActionTypes.MULTILINK_CURRENT_ITEM,
			payload: item
		})

	}
}

export const update = (projectId, userId, type, data) => {
	return async (dispatch) => {

		const response = await MultilinkService.update(projectId, userId, type, data)	
		

		await dispatch({
			type: MultilinkActionTypes.MULTILINK_GET,
			payload: response.data.getMultilink
		})
		

	}		
}
export const editSettingMultilink = (data, userId) => {
	return async (dispatch) => {

		dispatch({
			type: MultilinkActionTypes.MULTILINK_LOADING,
			payload: true
		})

		const response = await MultilinkService.editSetting(data, userId)	
		dispatch({
			type: MultilinkActionTypes.MULTILINK_UPDATE_SETTING,
			payload: response.data
		})

		dispatch({
			type: MultilinkActionTypes.MULTILINK_LOADING,
			payload: false
		})

	}		
}
export const editSlugMultilink = (data, userId) => {
	return async (dispatch) => {

		dispatch({
			type: MultilinkActionTypes.MULTILINK_LOADING,
			payload: true
		})
		dispatch({
			type: MultilinkActionTypes.MULTILINK_ERROR_SLUG,
			payload: {}
		})
		try {		

			const response = await MultilinkService.editSlug(data, userId)	
			console.log(response.data);
			dispatch({
				type: MultilinkActionTypes.MULTILINK_UPDATE_SLUG,
				payload: response.data
			})


		  } catch (err) {
			if (!err.response) {
			  throw err
			}
			dispatch({
				type: MultilinkActionTypes.MULTILINK_ERROR_SLUG,
				payload: err.response.data
			})
		  }

		  dispatch({
			type: MultilinkActionTypes.MULTILINK_LOADING,
			payload: false
		})




	}		
}
