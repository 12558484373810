import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import styles from './CreateShop.module.scss'
import { createMiniShop } from './../../../../redux/minishop/minishop.action';



function CreateShop() {

  const dispatch = useDispatch()
  const minishop = useSelector((shop) => shop.shop)
  const user = useSelector(({ user }) => user.userAuthInfo);
  

  const [minishopCreate, setMinishopCreate] = React.useState({
    project: "",
    desc: "",
    creator: user.id,
    
});

  const changeHandler = (e) => {
    setMinishopCreate( { ...minishopCreate, [e.target.name]: e.target.value } )
  }

  const createMinishopHandler = (e) => {
    console.log('llllllllllllllllllllllllllllllllllllll');
    e.preventDefault()
    dispatch(createMiniShop(minishopCreate))
  }


  return (
    <>
    <Row className={styles.container}>
        <Col sm={7}>
        {/* 
            {projectError.error === null ? "" : 
                
                <Alert
                    variant="danger"
                    onClose={() => dispatch(addTodoFailure(null))}
                    dismissible
                >
                    <p>{projectError.error}</p>
                </Alert>

            } 
        */}

            <Form>
                <Form.Group>
                    <Form.Label>Название магазина</Form.Label>
                    <Form.Control
                        type="text"
                        name="project"
                        value={minishopCreate.project}
                        onChange={changeHandler}
                        placeholder="Укажите название магазина"
                    />
                    <Form.Text className="text-muted">
                        Название в вашем списке
                    </Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Описание</Form.Label>
                    <Form.Control
                        type="text"
                        name="desc"
                        value={minishopCreate.desc}
                        onChange={changeHandler}
                        placeholder="Краткое описание проекта"
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    // disabled={loading}
                    type="submit"
                    onClick={createMinishopHandler}
                >
                    Далее
                </Button>
            </Form>
        </Col>
        <Col sm={5}>
            <Alert variant="info">
                Создавая новый проект вы соглашаетесь с правилами
                использования сайта
            </Alert>
        </Col>
    </Row>
</>
  )
}

export default CreateShop