import React from "react";

import PropTypes from "prop-types";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

function ShopDashboadItems({ minishop }) {

    return (
        <Card style={{ width: "18rem" }}>
            <Card.Body>
                <Card.Title>{minishop.project}</Card.Title>
                {/* <Card.Subtitle className="mb-2 text-muted">
                    Card Subtitle
                </Card.Subtitle> */}
                <Card.Text>{minishop.desc}</Card.Text>
                <Card.Link as={NavLink} to={`/minishop/${minishop._id}`}>
                    Редактировать
                </Card.Link>
            </Card.Body>
            <Card.Footer>
                {minishop.multilink ? (
                    <Card.Link href="#">Another Link </Card.Link>
                ) : (
                    <Row>
                        <Col sm={10}>
                            <small>Не привязан к мультиссылке</small>
                        </Col>
                        <Col sm={2}>
                            <Button variant="dark" size="sm">
                                +
                            </Button>
                        </Col>
                    </Row>
                )}
            </Card.Footer>
        </Card>
    );
}

ShopDashboadItems.propTypes = {
    minishop: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired,
    }).isRequired
};

export default ShopDashboadItems;