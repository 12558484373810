import React, { useCallback } from "react";
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AddColorComponent from "../../../../../../Components/Ui/AddColorComponent/AddColorComponent";

function PageShopLabelSetting({
    editLabel,
    setEditLabel,
    labels,
    setLabels,
    minishopId,
    userId,
}) {
    const handleLabel = useCallback(
        (e) => {
            setEditLabel((prevEditLabel) => ({
                ...prevEditLabel,
                label: {
                    ...prevEditLabel.label,
                    [e.target.name]: e.target.value,
                },
            }));
        },
        [setEditLabel]
    );

    const handleLabelColor = (info) => {
        setEditLabel((prevEditLabel) => ({
            ...prevEditLabel,
            label: {
                ...prevEditLabel.label,
                [info.field]: info.hex,
            },
        }));
    };

    const handleLabelShow = useCallback(() => {
        setEditLabel((prevEditLabel) => ({
            ...prevEditLabel,
            label: {
                ...prevEditLabel.label,
                labelShow: !prevEditLabel.label.labelShow,
            },
        }));
    }, [setEditLabel]);

    // const addLabel = () => {
    //     setLabels((prevLabels) => [...prevLabels, editLabel.label]);
    // };

    return (
        <Card className="mt-2">
            <Card.Body>
                <Form>
                    <Form.Group controlId="formLabelName">
                        <Form.Control
                            type="text"
                            placeholder="Введите название. Например: Новинка, Хит продаж, Акция"
                            value={editLabel.label.labelName || ""}
                            name="labelName"
                            onChange={handleLabel}
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group
                                controlId="formLabelColor"
                                className="mt-3"
                            >
                                <AddColorComponent
                                    color={editLabel.label.labelColor}
                                    colorHandler={(hex) =>
                                        handleLabelColor({
                                            hex,
                                            field: "labelColor",
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                controlId="formLabelColor"
                                className="mt-3"
                            >
                                <AddColorComponent
                                    color={editLabel.label.labelTextColor}
                                    colorHandler={(hex) =>
                                        handleLabelColor({
                                            hex,
                                            field: "labelTextColor",
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>{" "}
                    <Row className="mt-3">
                        <Col sm={7}>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Доступно в тарифе PRO.SHOP
                                    </Tooltip>
                                }
                            >
                                <Form.Select aria-label="Анимация" disabled>
                                    <option>Анимация</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </OverlayTrigger>
                        </Col>
                        <Col sm={5}>
                            <div className="mt-2">
                                <Form.Group controlId="formLabelCheckbox">
                                    <Form.Check
                                        type="switch"
                                        label="Показывать"
                                        name="labelShow"
                                        onChange={() => handleLabelShow()}
                                        checked={
                                            editLabel.label.labelShow || false
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default PageShopLabelSetting;
