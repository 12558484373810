import React from "react";
import { NavLink } from "react-router-dom";

import { Alert, Button } from "react-bootstrap";
import styles from "./NoneShop.module.scss";
import { BsPlusCircle } from "react-icons/bs";

function NoneShop() {
    return (
        <Alert variant="secondary" className={styles.container}>
            <Alert.Heading>У Вас нет созданных магазинов!</Alert.Heading>
            <Button as={NavLink} to="create" variant="primary" type="submit">
                {" "}
                <BsPlusCircle />
                <span> Создать проект</span>
            </Button>
            <Alert.Heading>Пора это исправить!</Alert.Heading>
        </Alert>
    );
}

export default NoneShop;
