import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
    getMultilink,
    currentMultilink,
} from "../../../../redux/multilink/multilink.action";

import defaultOptions from "./defaultOptions";

import styles from "./PageMultilink.module.scss";
import { Row, Col, Button, Spinner } from "react-bootstrap";

import {
    PageSocial,
    PageMessanger,
    PageTitle,
    PageText,
    PageLink,
    PagePriceList,
    PageList,
    PageFAQ,
    PageVideo,
    PageSvgTransition,
} from "./";
import ViewMultilink from "../ViewMultilink/ViewMultilink";
import { NavLink, useParams } from "react-router-dom";
import MainProjectInfo from "./MainProjectInfo/MainProjectInfo";

function PageMultilink() {
    const params = useParams()
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userAuthInfo);
    const loading = useSelector(({ multilink }) => multilink.multilinkLoading);
    const project = useSelector(({ multilink }) => multilink.multilink);
    const currentItem = useSelector(
        ({ multilink }) => multilink.multilinkItemCurrent
    );
    const [mainInfo, setMainInfo] = useState(project.secondMultilink);

    // get data multilink project in redux by id
    useEffect(() => {
        dispatch(getMultilink(params.id, user.id));
    }, [user]);


    
    // оригинальный стейт
    useEffect(() => {
        setMainInfo(project.secondMultilink);
    }, [project]);

    // data from component PageSocial.jsx

    const changeHandler = (event) => {
        // setItems(mainInfo.social);
    };

    const [showCanvas, setShowCanvas] = useState({
        social: false,
        messanger: false,
        title: false,
        text: false,
        blockLink: false,
        priceList: false,
        faqBlock: false,
        videoBlock: false,
        svgBlock: false,
    });

    const [arrayItem, setArrayItem] = useState(null);

    // const [defaultOptions] = useState({
    //     social: {
    //         setting: {
    //             mode: true,
    //             animate: "no",
    //             animationSpeed: "standart",
    //         },
    //         design: {
    //             target: "_blank",
    //             type_button: "logo",
    //             style_button: "bg",
    //             shape_button: "none",
    //             color_button: "brand",
    //         },
    //         items: [
    //             {
    //                 _id: "6235fd3bfee9302874c3bb9e",
    //                 name: "FB",
    //                 title: "Facebook",
    //                 site: "https://fb.com/",
    //                 color: "#3b5998",
    //                 index: 1,
    //                 order: 1,
    //             },
    //         ],
    //         design_block: {
    //             background: "",
    //             background_color: "#ffffff",
    //             background_img: "",
    //             background_padding: {
    //                 top: 6,
    //                 right: 6,
    //                 bottom: 6,
    //                 left: 6,
    //             },
    //             background_margin: {
    //                 top: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 left: 0,
    //             },
    //             blur: 0,
    //             brightness: 100,
    //             opacity: 100,
    //         },
    //     },
    //     messanger: {
    //         setting: {
    //             mode: true,
    //             animate: "no",
    //             animationSpeed: "standart",
    //         },
    //         design: {
    //             target: "_blank",
    //             type_button: "logo",
    //             style_button: "bg",
    //             shape_button: "none",
    //             color_button: "brand",
    //         },
    //         items: [
    //             {
    //                 _id: "6235fd3bfee9302874c3bb9e",
    //                 name: "FB",
    //                 title: "Facebook",
    //                 site: "https://fb.com/",
    //                 color: "#3b5998",
    //                 index: 1,
    //                 order: 1,
    //             },
    //         ],
    //         design_block: {
    //             background: "",
    //             background_color: "#ffffff",
    //             background_img: "",
    //             background_padding: {
    //                 top: 6,
    //                 right: 6,
    //                 bottom: 6,
    //                 left: 6,
    //             },
    //             background_margin: {
    //                 top: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 left: 0,
    //             },
    //             blur: 0,
    //             brightness: 100,
    //             opacity: 100,
    //         },
    //     },
    // });

    //   useEffect(() => {
    //     dispatch(currentMultilink(defaultOptions))

    //   }, [project])

    const showCanvasHandler = async (loadParams, canvas, index) => {

        await dispatch(currentMultilink({ ...loadParams, index }));
        setShowCanvas({ ...showCanvas, ...canvas });
        // showCanvas(true);
        setArrayItem(null);
    };


    if ((loading && project) || mainInfo === undefined) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    return (
        <>
            <Row>
                <Col sm={7}>
                    <div className={styles.main__container}>
                        
                        <MainProjectInfo mainInfo={mainInfo} user={user} />

                        <div className={styles.statistics}>
                            <div className={styles.statistics__item}>
                                <div className={styles.statistics__item__text}>
                                    Переходов сегодня
                                </div>
                                <div className={styles.statistics__item__count}>
                                    10
                                </div>
                            </div>
                            <div className={styles.statistics__item}>
                                <div className={styles.statistics__item__text}>
                                    Переходов за месяц
                                </div>
                                <div className={styles.statistics__item__count}>
                                    372
                                </div>
                            </div>
                            <Button
                                as={NavLink}
                                to={`/multilink/stats/${project.mainMultilink._id}`}
                                variant="light"
                                size="sm"
                            >
                                Статистика
                            </Button>
                        </div>
                    </div>

                    {/* <p>
                        Здесь будет настройка дизайна и остальные настройки
                        проекта
                    </p> */}
                    <PageSocial
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.social}
                        social={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageMessanger
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.messanger}
                        messanger={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageTitle
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.title}
                        title={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageText
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.text}
                        text={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageLink
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.blockLink}
                        blockLink={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PagePriceList
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.priceList}
                        priceList={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageList
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.listBlock}
                        listBlock={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageFAQ
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.faqBlock}
                        faqBlock={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageVideo
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.videoBlock}
                        videoBlock={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <PageSvgTransition
                        setShowCanvas={setShowCanvas}
                        showCanvas={showCanvas.svgBlock}
                        svgBlock={currentItem}
                        projectId={mainInfo._id}
                        user={user}
                    />
                    <div className={styles.button__container}>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.social,
                                    { social: true },
                                    mainInfo.content.length
                                )
                            }
                        >
                            Соц. сети
                        </Button>{" "}
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.messanger, // загружаем дефолтные данные для канваса
                                    { messanger: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Мессенджеры
                        </Button>
                        {/* Обычные блоки */}
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.title, // загружаем дефолтные данные для канваса
                                    { title: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Заголовок
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.text, // загружаем дефолтные данные для канваса
                                    { text: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Обычный текст
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.blockLink, // загружаем дефолтные данные для канваса
                                    { blockLink: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Внешняя ссылка
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.listBlock, // загружаем дефолтные данные для канваса
                                    { listBlock: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Список
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.faqBlock, // загружаем дефолтные данные для канваса
                                    { faqBlock: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            FAQ
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.priceList, // загружаем дефолтные данные для канваса
                                    { priceList: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Прайс-лист
                        </Button>{" "}
                        {/* акулас */}
                        {/* медиа блоки */}
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.svgBlock, // загружаем дефолтные данные для канваса
                                    { svgBlock: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Переход между блоками
                        </Button>{" "}
                        {/* акулас */}
                        <Button variant="success">Изображение</Button>
                        <Button variant="success">Галерея</Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                showCanvasHandler(
                                    defaultOptions.videoBlock, // загружаем дефолтные данные для канваса
                                    { videoBlock: true }, // указываем какой канвас открыть
                                    mainInfo.content.length // указываем порядковый номер блока в случае сохранения
                                )
                            }
                        >
                            Видео
                        </Button>
                        <Button variant="success">Галерея видео</Button>
                        <Button variant="success">Карта</Button>
                        <Button variant="success">Баннер</Button> {/* аспро */}
                        {/* контентные блоки */}
                        <Button variant="success">Пожертвования</Button>
                        <Button variant="success">Блок отзывов</Button>
                        <Button variant="success">Преимущества</Button>
                        {/*  */}
                        {/* сложные блоки */}
                        <Button variant="success">Информация </Button>{" "}
                        {/* политика конфидациальности */}
                        <Button variant="success">Календарь</Button>
                        {/* простейшие блоки */}
                        <Button variant="success">Разделитель</Button>
                        {/* платные */}
                        <Button variant="warning">Визитка</Button>
                        <Button variant="warning">Магазин</Button>
                        <Button variant="warning">Обратный отсчет</Button>
                        <Button variant="warning">Контактная форма</Button>
                    </div>

                    {/* {mainInfo 
                    ? <PageSocialLink 
                        social={mainInfo.social} 
                        setSocial={setMainInfo}  
                    />
                    : 'loading' 
                } */}
                    {/* <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon3">
                        https://yokit.com/go/
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl id="basic-url" aria-describedby="basic-addon3" />
                </InputGroup> */}
                </Col>
                <Col sm={5}>
                    <ViewMultilink
                        paid={project.mainMultilink.paid}
                        data={mainInfo}
                        setData={setMainInfo}
                        setArrayItem={setArrayItem}
                        showCanvasHandler={showCanvasHandler}
                    />
                </Col>
            </Row>
        </>
    );
}

export default PageMultilink;
