import React from "react";
import { Routes, Route } from "react-router-dom";
import {
    CountLetters,
    Duplicates,
    OpenAiTextGenerator,
    OpenAiTextItem,
    PasswordGenerator,
    QrCreator,
    Tools,
    Translit,
    UTMGenerator,
} from "../App/Pages";
import { PATH_TOOLS } from "../constants/path";

export default function ToolsRoutes() {
    return (
<Routes>
  <Route path={PATH_TOOLS.root} element={<Tools />} />
  <Route path={PATH_TOOLS.qr} element={<QrCreator />} />
  <Route path={PATH_TOOLS.countLetters} element={<CountLetters />} />
  <Route path={PATH_TOOLS.duplicates} element={<Duplicates />} />
  <Route path={PATH_TOOLS.utmGenerator} element={<UTMGenerator />} />
  <Route path={PATH_TOOLS.passwordGenerator} element={<PasswordGenerator />} />
  <Route path={PATH_TOOLS.translit} element={<Translit />} />
  <Route path={PATH_TOOLS.textGenerator} element={<OpenAiTextGenerator />} />
  <Route path={PATH_TOOLS.textGeneratorItem} element={<OpenAiTextItem />} />
</Routes>


    );
}

