// online.action.js
import store from "../store";
import OnlineActionTypes from "./online.types";
import OnlineService from "../../services/OnlineService";

export const fetchProjectOnline = (id) => async (dispatch) => {

    console.log("fetchProjectOnline", id);
  // кэширование
//   const cachedServices = store.getState().online.cache.services;
//   const cacheTimeout = 60 * 1000; // 1 минута

//   // проверка кэша
//   if (cachedServices && Date.now() - cachedServices < cacheTimeout) {
//     return;
//   }

  try {
    const response = await OnlineService.fetchProjects(id);
        dispatch({
            type: OnlineActionTypes.FETCH_PROJECT,
            payload: { projects: response.data, cacheTime: Date.now() },
        });
    } catch (error) {
        console.log(error);
    }
};

export const createProjectOnline = (project) => async (dispatch) => {
    try {
        const response = await OnlineService.createProject({ project });
        dispatch({
            type: OnlineActionTypes.CREATE_PROJECT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const getProjectOnlineItem = (id, userId) => async (dispatch) => {
    try {
        const response = await OnlineService.getProject(id, userId);
        dispatch({
            type: OnlineActionTypes.GET_PROJECT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
}

export const copyProjectOnline = (project) => async (dispatch) => {
    try {
        const response = await OnlineService.copyProject({ project });
        dispatch({
            type: OnlineActionTypes.COPY_PROJECT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
}

export const updateProject = (projectId, projectData) => async (dispatch) => {
    try {
        const response = await OnlineService.updateProject(
            projectId,
            projectData
        );
        dispatch({
            type: OnlineActionTypes.UPDATE_PROJECT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteProject = (projectId) => async (dispatch) => {
    try {
        await OnlineService.deleteProject(projectId);
        dispatch({
            type: OnlineActionTypes.DELETE_PROJECT,
            payload: projectId,
        });
    } catch (error) {
        console.error(error);
    }
};

export const fetchServices = () => async (dispatch) => {
    try {
        const response = await OnlineService.getServices();
        dispatch({
            type: OnlineActionTypes.FETCH_SERVICES,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const createService = (userId, service) => async (dispatch) => {
    console.log(userId, service);
    try {
        const response = await OnlineService.createService({ userId, service });
        dispatch({
            type: OnlineActionTypes.CREATE_SERVICE,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const updateService = (serviceId, serviceData) => async (dispatch) => {
    try {
        const response = await OnlineService.updateService(
            serviceId,
            serviceData
        );
        dispatch({
            type: OnlineActionTypes.UPDATE_SERVICE,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteService = (serviceId) => async (dispatch) => {
    try {
        await OnlineService.deleteService(serviceId);
        dispatch({
            type: OnlineActionTypes.DELETE_SERVICE,
            payload: serviceId,
        });
    } catch (error) {
        console.error(error);
    }
};
///////
// Schedules actions
export const fetchSchedules = () => async (dispatch) => {
    try {
        const response = await OnlineService.getSchedules();
        dispatch({
            type: OnlineActionTypes.FETCH_SCHEDULES,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const createSchedule = (schedule) => async (dispatch) => {
    try {
        const response = await OnlineService.createSchedule(schedule);
        dispatch({
            type: OnlineActionTypes.CREATE_SCHEDULE,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const updateSchedule =
    (scheduleId, scheduleData) => async (dispatch) => {
        try {
            const response = await OnlineService.updateSchedule(
                scheduleId,
                scheduleData
            );
            dispatch({
                type: OnlineActionTypes.UPDATE_SCHEDULE,
                payload: response.data,
            });
        } catch (error) {
            console.error(error);
        }
    };

export const deleteSchedule = (scheduleId) => async (dispatch) => {
    try {
        await OnlineService.deleteSchedule(scheduleId);
        dispatch({
            type: OnlineActionTypes.DELETE_SCHEDULE,
            payload: scheduleId,
        });
    } catch (error) {
        console.error(error);
    }
};
/////////////
// Bookings actions
export const fetchBookings = () => async (dispatch) => {
    try {
        const response = await OnlineService.getBookings();
        dispatch({
            type: OnlineActionTypes.FETCH_BOOKINGS,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const createBooking = (booking) => async (dispatch) => {
    try {
        const response = await OnlineService.createBooking(booking);
        dispatch({
            type: OnlineActionTypes.CREATE_BOOKING,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const updateBooking = (bookingId, bookingData) => async (dispatch) => {
    try {
        const response = await OnlineService.updateBooking(
            bookingId,
            bookingData
        );
        dispatch({
            type: OnlineActionTypes.UPDATE_BOOKING,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteBooking = (bookingId) => async (dispatch) => {
    try {
        await OnlineService.deleteBooking(bookingId);
        dispatch({
            type: OnlineActionTypes.DELETE_BOOKING,
            payload: bookingId,
        });
    } catch (error) {
        console.error(error);
    }
};

////////////////

// Blacklist actions
export const fetchBlacklist = () => async (dispatch) => {
    try {
        const response = await OnlineService.getBlacklist();
        dispatch({
            type: OnlineActionTypes.FETCH_BLACKLIST,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const createBlacklist = (blacklist) => async (dispatch) => {
    try {
        const response = await OnlineService.createBlacklist(blacklist);
        dispatch({
            type: OnlineActionTypes.CREATE_BLACKLIST,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const updateBlacklist =
    (blacklistId, blacklistData) => async (dispatch) => {
        try {
            const response = await OnlineService.updateBlacklist(
                blacklistId,
                blacklistData
            );
            dispatch({
                type: OnlineActionTypes.UPDATE_BLACKLIST,
                payload: response.data,
            });
        } catch (error) {
            console.error(error);
        }
    };

export const deleteBlacklist = (blacklistId) => async (dispatch) => {
    try {
        await OnlineService.deleteBlacklist(blacklistId);
        dispatch({
            type: OnlineActionTypes.DELETE_BLACKLIST,
            payload: blacklistId,
        });
    } catch (error) {
        console.error(error);
    }
};
