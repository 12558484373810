import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { Alert, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styles from "../Tools.module.scss";

import {
    createOpenAi,
    dataOpenAi,
} from "../../../../redux/openai/openai.action";
import UiPaginationComponent from "../../../Components/Ui/UiPaginationComponent/UiPaginationComponent";
import { capitalazeFirstLetter } from './../../../../utils/capitalazeFirstLetter';

function OpenAiTextGenerator() {
    const dispatch = useDispatch();


    const [data, setData] = useState({
        user: "",
        response: "Пожалуйста напиши сео оптимизированное описание товара",
        text: "",
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const textHandler = useCallback((e) => {
        setData((prevData) => ({
            ...prevData,
            [e.target.name]: ` ${e.target.value}`,
        }));
    }, []);

    const { id: userId } = useSelector(({ user }) => user.userAuthInfo);
    const loading = useSelector(({ openAi }) => openAi.openaiLoading);
    const info = useSelector(({ openAi }) => openAi.openAiData);
    const error = useSelector(({ openAi }) => openAi.openaiError);

    useEffect(() => {
        setData({ ...data, user: userId });
        dispatch(dataOpenAi({ user: userId }));
    }, [userId]);

    const getTextHandler = useCallback(() => {
        dispatch(createOpenAi(data));
    }, [data, dispatch]);

    const handlePageChange = useCallback((pageNumber) => {
        setCurrentPage(pageNumber);
    }, []);



    return (
        <Row>
            <Col sm={7}>
                {error && error.error && (
                    <Alert variant="danger">{error.msg}</Alert>
                )}
                {loading ? (
                    <>
                        <div className={styles.container}>
                            <div>
                                <Form>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="openAiAddText"
                                    >
                                        <Form.Label>
                                            Введите названия товара
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Название"
                                            name="text"
                                            onChange={textHandler}
                                        />
                                        <Form.Text className="text-muted">
                                            При генерации описания нам
                                            понадобится немного времени
                                        </Form.Text>
                                    </Form.Group>
                                </Form>
                                {data.text.length > 0 ? (
                                    <Button onClick={getTextHandler}>
                                        Сгенерировать
                                    </Button>
                                ) : (
                                    <Button disabled>Сгенерировать</Button>
                                )}
                            </div>
                        </div>
                        {info?.textItems  !== undefined && (
                            <>
                                {info.textItems
                                    .slice(
                                        (currentPage - 1) * itemsPerPage,
                                        currentPage * itemsPerPage
                                    )
                                    .map((item, index) => (
                                        <Card key={index} className="mt-2">
                                            <Card.Body>
                                                <Row>
                                                    <Col sm={9}>
                                                        {capitalazeFirstLetter(item.textRequest)}
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Button
                                                            size="sm"
                                                            as={NavLink}
                                                            to={item._id}
                                                            className={
                                                                styles.btn
                                                            }
                                                        >
                                                            Прочитать
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                <UiPaginationComponent
                                    itemsPerPage={itemsPerPage}
                                    setItemsPerPage={setItemsPerPage}
                                    totalItems={info.textItems.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <div>
                        <Alert variant="secondary" className={styles.loader} >
                          
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </Spinner>{" "}

                                Генерируем описание, пожалуйста не закрывайте
                                страницу
                        </Alert>
                    </div>
                )}
            </Col>
            <Col sm={5}>
                <Alert variant="info">
                    Для получения лучшего результат попробуйте расширить вводные
                    данные.
                </Alert>
            </Col>
        </Row>
    );
}

export default OpenAiTextGenerator;
