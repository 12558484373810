import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    getMultilink,
    editSettingMultilink,
} from "../../../../../redux/multilink/multilink.action";

function SettingMultilinkInfo() {
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userAuthInfo);
    const project = useSelector(
        ({ multilink }) => multilink.multilink.mainMultilink
    );
    const loading = useSelector(
        ({ multilink }) => multilink.multilinkLoading
    );

    const projectId = window.location.pathname.toString().split("/");

    useEffect(() => {
        dispatch(getMultilink(projectId[3], user.id));
    }, [user.id]);

    const [data, setData] = useState({
        title: null,
        desc: null,
        id: projectId[3],
    });

    useMemo(() => setData({...data, title: project ? project.title : '', desc: project ? project.desc : ''}),
        [project]
    );

    const editInfoHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const postInfoHandler = (e) => {
        e.preventDefault()
        dispatch(editSettingMultilink(data, user.id));
    };


    // if (!project) {
    //     return (
    //         <Spinner animation="border" role="status">
    //             <span className="visually-hidden">Loading...</span>
    //         </Spinner>
    //     );
    // }
    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Название проекта</Form.Label>
                <Form.Control
                    type="text"
                    name="title"
                    placeholder="Введите название"
                    defaultValue={project.title}
                    onChange={editInfoHandler}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Описание проекта</Form.Label>
                <Form.Control
                    type="text"
                    name="desc"
                    placeholder="Введите описание проекта"
                    defaultValue={project.desc}
                    onChange={editInfoHandler}
                />
            </Form.Group>

            {data.title && (
                <Button
                    variant="primary"
                    type="submit"
                    onClick={postInfoHandler}
                >
                    Сохранить
                </Button>
            )}
        </Form>
    );
}

export default SettingMultilinkInfo;
