import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { setLanguage } from '../../../redux/language/language.action'

import { useTranslation } from "react-i18next";

import {
    Button,
    Col,
    Container,
    Modal,
    Nav,
    Navbar,
    Row,
} from "react-bootstrap";
import { Auth, Join, SocialAuth } from "../index";
import styles from "./Header.module.scss";

function Header() {

    const dispatch = useDispatch()


    // пакет локализации
    const { t, i18n } = useTranslation();
    // кнопки смены языка



    const changeLanguage = (language) => {
        i18n.changeLanguage(language);

        dispatch(setLanguage(language))
    };

    // всплывающие формы
    const [show, setShow] = useState({ open: false, form: false });
    const handleClose = () => setShow({ open: false, form: false });
    const handleShowJoin = () => setShow({ open: true, form: "Join" });
    const handleShowLogin = () => setShow({ open: true, form: "login" });

    return (
        <>
            <Navbar className={styles.navbar}>
                <Container>
                    <Navbar.Brand href="#home">ClICKED</Navbar.Brand>

                    {i18n.language === "ru" ? (
                        <div
                            className={styles.language}
                            onClick={() => changeLanguage("en")}
                        >
                            EN
                        </div>
                    ) : (
                        <div
                            className={styles.language}
                            onClick={() => changeLanguage("ru")}
                        >
                            RU
                        </div>
                    )}
                    

                    <Nav className={`me-auto ${styles.navblock}`}>
                        <Nav.Link href="#multilink">{t("landing.menu.link1")}</Nav.Link>
                        <Nav.Link href="#instalending">{t("landing.menu.link2")}</Nav.Link>
                        <Nav.Link href="#instashop">{t("landing.menu.link3")}</Nav.Link>
                    </Nav>



                    <div className={styles.nav__auth__btn}>
                        <Button variant="light" onClick={handleShowLogin}>
                            {t("landing.auth.login")}
                        </Button>{" "}
                        {/* <Button variant="warning" onClick={handleShowJoin}>
                            {t("landing.auth.signup")}
                        </Button> */}
                    </div>


                </Container>
            </Navbar>

            <Modal show={show.open} onHide={handleClose} size="lg">
                {/* <div closeButton></div> */}

                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {show.form === "login" ? <Auth /> : ""}
                            {/* {show.form === "Join" ? <Join /> : ""}    */}
                        </Col>
                        <Col>
                            {/* <SocialAuth /> */}
                        </Col>
                    </Row>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default Header;
