import React from "react";
import { useSelector} from 'react-redux'
import ProjectCountMultilink from "./ProjectCountMultilink/ProjectCountMultilink";
import { Col, Row } from "react-bootstrap";

function ProjectCount({multilinksFetch}) {


    return (<ProjectCountMultilink count={multilinksFetch.length} />

    );
}

export default ProjectCount;
