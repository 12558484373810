import React from "react";
import { useSelector } from "react-redux";

import BalanceHistory from "./BalanceHistory/BalanceHistory";
import ProfileAddBalance from "./ProfileAddBalance/ProfileAddBalance";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

function Balance() {
    const user = useSelector(({ user }) => user.userInfo);

    return (
        <Row>
            <Col sm={8}>
                <ProfileAddBalance user={user} />

                <Tabs
                    defaultActiveKey="pay"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="pay" title="Ваши пополнения">
                        <BalanceHistory user={user} />
                    </Tab>
                    <Tab eventKey="profile" title="Ваши расходы">
                        ыыы
                    </Tab>
                    <Tab eventKey="longer-tab" title="Реферальный доход">
                        вввв
                    </Tab>
                </Tabs>

                
            </Col>

            <Col sm={4}></Col>
        </Row>
    );
}

export default Balance;
