import React from "react";
import { Container, Item, Text, PriceBlock, Price } from "./ViewPriceList.styled";

function ViewPriceList({ priceList }) {
    return (
        <Container container={priceList}>
            {priceList.items.map((item, index) => (
                <Item
                    key={`${index}_priceList_${item.title}`}
                    container={priceList}
                >
                    <Text>{item.title}</Text>
                    <PriceBlock>
                        <div>
                            {(item.price_type === "equally" && '') || (item.price_type === "from" && 'От') || (item.price_type === "before" && 'До') }
                        </div>
                        <Price>
                            <span>{item.price}</span>
                            <span>{item.currency === "Rub" && "₽"}</span>
                        </Price>

                         
                    </PriceBlock>
                </Item>
            ))}
        </Container>
    );
}

export default ViewPriceList;
