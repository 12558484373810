import React from "react";

import { Row, Col, Button } from "react-bootstrap";
import styles from "./SaveComponent.module.scss";

function SaveComponent({ save, updateHandler }) {

    
    return (
        <>
            {save && (
                <>
                    <div className={styles.save__container}>
                        <Row>
                            <Col sm={6}>
                                Чтобы изменения вступили в силу, нажмите:
                            </Col>
                            <Col sm={6}>
                                <div className={styles.save__block}>
                                    {/* <Button
                                        className={styles.save__btn}
                                        onClick={() => updateHandler(false)}
                                        variant="outline-primary"
                                    >
                                        Сохранить
                                    </Button> */}
                                    <Button
                                        className={styles.save__btn}
                                        onClick={() => updateHandler(true)}
                                        variant="primary"
                                    >
                                        Сохранить и закрыть
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.save__ribborn}></div>
                </>
            )}
        </>
    );
}

export default SaveComponent;
