export const TOOLS_RU = {
    tools: {
        title: "Инструменты",
        QrCreator: {
            title: "Создание QR-кода",
            desc: "Создайте QR-код для своего профиля в социальных сетях, чтобы увеличить количество подписчиков и продаж",
            btn: "Создать QR-код",
        },
        CountLetters: {
            title: "Подсчет символов",
            desc: "Подсчитайте количество букв в тексте, чтобы узнать, сколько символов вам нужно для создания поста",
            btn: "Подсчитать символы",
            text: {
                text: "Укажите текст для подсчета",
                letters: "Символов",
                lettersWhitespace: "Символов без пробелов",
                words: "Слова",
                sentences: "Предложения",
                paragraphs: "Абзацы",
                submit: "Подсчитать",
                warning:"Внимание: в данном инструменте не учитываются символы переноса строки, табуляции и пробелы в начале и конце строки.",
            }
        },
        Duplicates: {
            title: "Поиск дубликатов",
            desc: "Найдите дубликаты в тексте, чтобы узнать, какие слова повторяются в тексте",
            btn: "Найти дубликаты",
        },
        UTMGenerator: {
            title: "Генератор UTM-меток",
            desc: "Создайте UTM-метки для своих ссылок, чтобы узнать, какие ссылки работают лучше",
            btn: "Создать UTM-метки",
        },
        Translit: {
            title: "Транслитерация",
            desc: "Транслитерируйте текст, чтобы узнать, как он будет выглядеть в латинице",
            btn: "Транслитерировать",
        },
        PasswordGenerator: {
            password: "Пароль",
            title: "Генератор паролей",
            desc: "Создайте пароль, чтобы узнать, какой пароль будет надежным",
            btn: "Создать пароль",
            length: "Длина пароля",
            generate: "Сгенерировать пароль",
            copy: "Скопировать",
            error: "Пожалуйста, выберите хотя бы один тип символов для включения в пароль.",
            settings: {
                uppercase: "Большие буквы",
                lowercase: "Маленькие буквы",
                numbers: "Цифры",
                symbols: "Символы",
            }

        },
        Transliteration: {
            title: "Транслитерация",
            desc: "Транслитерируйте текст, чтобы узнать, как он будет выглядеть в латинице",
            btn: "Транслитерировать",
        },
        TextGenerator: {
            title: "Ai Генератор текста",
            desc: "Сгенерируйте текстовое описание товара с помощью нейросети",
            itemDesc: "Этот текст создан нейросетью",
            btn: "Создать текст",
            generate: "Сгенерировать текст",
            copy: "Скопировать",
            error: ""

        }


    },
};
