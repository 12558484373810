import React, { useMemo, useState } from "react";

import styles from "./PageListItem.module.scss";
import {
    Button,
    Form,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";

function PageListItem({ listBlockItems, setListBlockItems }) {

    // сортировка после изменения расположения полей

    const listBlockHandler = (event, item) => {
        setListBlockItems({
            ...listBlockItems,
            items: listBlockItems.items.map((c) => {
                if (c.index === item.index) {
                    return { ...c, [event.target.name]: event.target.value };
                }
                return c;
            }),
        });
    };

    //// включаем возможность сортировки, работает только при нажиме на элемент. Для того чтобы при выделении инпута не включалось перетаскивание
    const [draggbleState, setDraggbleState] = useState(true);

    const [currentItemDraggble, setCurrentItemDraggble] = useState(null);

    const sortHandler = (item) => {
        setListBlockItems({
            ...listBlockItems,
            items: listBlockItems.items.map((c) => {
                if (c.index === item.index) {
                    return { ...c, order: Number(currentItemDraggble.order) };
                }
                if (c.index === currentItemDraggble.index) {
                    return { ...c, order: Number(item.order) };
                }
                return c;
            }),
        });
    };

    const onDragStartHandler = (event, item) => {
        event.currentTarget.classList.add(styles.start);
        setCurrentItemDraggble(item);
    };

    const onDragLeaveHandler = (event) => {
        event.preventDefault();
        event.currentTarget.classList.remove(styles.active);
    };
    const onDragEndHandler = (event) => {
        event.preventDefault();
        // event.target.style.background = '#fff'
        event.currentTarget.classList.remove(styles.active);
    };

    const onDragOverHandler = (event) => {
        event.preventDefault();
        event.currentTarget.classList.add(styles.active);
    };

    const onDropHandler = (event, item) => {
        event.preventDefault();
        sortHandler(item);

        event.currentTarget.classList.remove(styles.active);
    };

    
    const sortItems = (a, b) => {

                if (a.order > b.order && b) {
                    return 1;
                } else {
                    return -1;
                }
            };
    /// модульное окно для удаления элемента
    const [modal, setModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    const modalCloseHandler = () => setModal(false);
    const modalShowHandler = (item) => {
        setModal(true);
        setCurrentItem(item);
    };

    const itemDelHandler = () => {
        // нужно менять index и order по индексу текущего itema
        // каждый раз должна происходить перезапись всех объектов в массиве
        setListBlockItems({
            ...listBlockItems,
            items: listBlockItems.items.filter(
                (c) => c.order !== currentItem.order
            ),
        });
        modalCloseHandler();
    };

    const itemAddHandler = () => {
        let item = {
            title: "",
            description: "",
            list_type: "circle", //
        };
        let idItem = listBlockItems.items.length;
        let addItem = {
            ...item,
            index: idItem + 1,
            order: idItem + 1,
        };

        setListBlockItems({
            ...listBlockItems,
            items: [...listBlockItems.items, addItem],
        });
    };

    const itemCopyHandler = (item) => {
        let idItem = listBlockItems.items.length;
        let addItem = {
            ...item,
            index: idItem + 1,
            order: idItem + 1,
        };

        setListBlockItems({
            ...listBlockItems,
            items: [...listBlockItems.items, addItem],
        });
    };


    return (
        <>
            <Modal show={modal} onHide={modalCloseHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Удалить?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите удалить элемент?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalCloseHandler}>
                        Нет
                    </Button>
                    <Button variant="primary" onClick={itemDelHandler}>
                        Да, удалить!
                    </Button>
                </Modal.Footer>
            </Modal>

            <Form className="parent">
                {listBlockItems.items &&
                    listBlockItems.items.sort(sortItems).map((item, index) => (
                        <div 
                            key={`${index}_listItem_${item.name}`}
                            className={`${styles.drop}  ${styles.item}`}
                            draggable={draggbleState}
                            axis={"y"}
                            containment={"parent"}

                            onDragStart={(event) =>
                                onDragStartHandler(event, item)
                            }
                            onDragLeave={(event) => onDragLeaveHandler(event)}
                            onDragEnd={(event) => onDragEndHandler(event)}
                            onDragOver={(event) => onDragOverHandler(event)}
                            onDrop={(event) => onDropHandler(event, item)}
                           
                        >
                            <Row className={`${styles.input}`}>
                                <Col sm={6}>
                                    <Form.Control
                                        size="sm"
                                        onMouseDown={() =>
                                            setDraggbleState(false)
                                        }
                                        onMouseUp={() => setDraggbleState(true)}
                                        onChange={(event) =>
                                            listBlockHandler(event, item)
                                        }

                                        type="text"
                                        name="title"
                                        defaultValue={item.title}
                                        placeholder="Заголовок"
                                        key={`title_listBlock_`}
                                    />

                                    <Form.Control
                                        size="sm"
                                        className={`${styles.input} mt-2`}

                                        onMouseDown={() =>
                                            setDraggbleState(false)
                                        }
                                        onMouseUp={() => setDraggbleState(true)}
                                        onChange={(event) =>
                                            listBlockHandler(event, item)
                                        }

                                        type="text"
                                        name="description"
                                        defaultValue={item.description}
                                        placeholder="Описание"
                                        key={`title_description_`}
                                        // as="textarea" rows={2}
                                    />
                                </Col>
                                <Col sm={4}>

                                </Col>
                                <Col sm={2} className={styles.del_edit}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Копировать
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="dark"
                                            size="sm"
                                            onClick={() =>
                                                itemCopyHandler(item)
                                            }
                                        >
                                            <AiOutlineCopy />
                                        </Button>
                                    </OverlayTrigger>{" "}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Удалить
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() =>
                                                modalShowHandler(item)
                                            }
                                        >
                                            <AiOutlineDelete />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </div>
                    ))}

                <Button size="sm" onClick={itemAddHandler}>
                    Добавить
                </Button>
            </Form>
        </>
    );
}

export default PageListItem;
