export const SIDEBAR_EN = {
    sidebar: {
        brand: {
            desc: "Your SMM assistant",
        },
        menu: {
            dashboard: "Dashboard",
            multilink: "Multilink",
            online: 'On-line ',
            storilanding: "Storilanding",
            minishop: "Instashop",
            widget: "Widgets",
            tools: "Tools",
            profile: "Profile",
        },
    },
    sidebarCRM: {
        menu: {
            bid: 'Bid',
            tasks: 'Tasks',
            team: 'Team',
            stat: 'Statistics',
            base: 'Client base'
        }
    }
};
