
import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
// import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


const persistConfig = {
  key: 'root',
  storage,  
  whitelist: ['storyLanding.projects'],
  blacklist: ['user', 'profile', 'multilink', 'minishop', 'openAi', 'online'],
  sameSite: 'none'
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(initialState = {}) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return {
    store,
    persistor
  };
}

// export default function configureStore() {

//   let store = createStore(
//     persistedReducer,
//     composeWithDevTools(
//         applyMiddleware(thunk)
//     )
//   )

//   let persistor = persistStore(store)
//   return { store, persistor }

// }


// import { createStore, compose, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'

// import rootReducer from './rootReducer'

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store = createStore(
//     rootReducer, 
//     composeEnhancers(
//         applyMiddleware(thunk)
//     )
//     )




// export default store
