import React from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Nav } from "react-bootstrap";
import styles from "./Sidebar.module.scss";
import {
    BsUiChecksGrid,
    BsReverseLayoutTextWindowReverse,
    BsTools,
    BsCart4,
    BsPip,
    BsPhone,
    BsCalendarDate,
} from "react-icons/bs";

function SidebarMenu() {
    const { t } = useTranslation("sidebar");

    return (
        <Nav className={`${styles.test} flex-column`}>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/"
            >
                <span>
                    <BsReverseLayoutTextWindowReverse />
                </span>{" "}
                {t("sidebar.menu.dashboard")}
            </NavLink>

            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/multilink"
            >
                <span>
                    <BsUiChecksGrid />
                </span>{" "}
                {t("sidebar.menu.multilink")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/minishop"
            >
                <span>
                    <BsCart4 />
                </span>{" "}
                {t("sidebar.menu.minishop")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/online"
            >
                <span>
                    <BsCalendarDate />
                </span>{" "}
                {t("sidebar.menu.online")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/storylanding"
            >
                <span>
                    <BsPhone />
                </span>{" "}
                {t("sidebar.menu.storilanding")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/widget"
            >
                <span>
                    <BsPip />
                </span>{" "}
                {t("sidebar.menu.widget")}
            </NavLink>

            {/* <NavLink eventKey="disabled" disabled>
                Disabled
            </NavLink> */}

            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/tools"
            >
                <span>
                    <BsTools />
                </span>{" "}
                {t("sidebar.menu.tools")}
            </NavLink>
        </Nav>
    );
}

export default SidebarMenu;
