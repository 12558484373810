import React from 'react'
import { useDispatch } from "react-redux";
import {  logout } from '../../../redux/user/user.action'
import { Outlet  } from 'react-router-dom';


function Profile() {

	const dispatch = useDispatch()


	// const store = useSelector(({ user }) => {

	// 	return { 
	// 		checkAuth: user.userAuth, 
	// 		user: user.userInfo, 
    //         users: user.userAll
    //     }
	  
	// })

    // const getUsers = (e) => {
    //     e.preventDefault()
    //     dispatch(usersGet())
    // }

    return (
        <div>            
            <Outlet /> 
{/* <SettingPage /> */}

            {/* <h1>Пользователь авторизован {store.user ? store.user.email: ''}</h1>
            <h2>{store.user && store.user.isActivated === true ? 'Аккаунт подтвержден по почте' : 'ПОДТВЕРДИТЕ АККАУНТ!!!!'}</h2>
             */}
             <div style={{marginTop: "60px"}}>
             Временная кнопка <button onClick={() => dispatch(logout())}>Выйти</button>

             </div>

            {/* <div>
                <button onClick={getUsers}>Получить пользователей</button>
            </div>
            <div>
                <button onClick={() => dispatch(usersAll({}))}>Очистить</button>
            </div>

            {store.users.length > 0 
                ? store.users.map(user => <div key={user.email}>{user.email} {user.roles}</div>) 
                : 'нет данных'
            } */}

        </div>
    )
}

export default Profile
