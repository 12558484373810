import React from "react";
import { Routes, Route } from "react-router-dom";
import {
    AllMultilink,
    CreateMultilink,
    Multilink,
    PageMultilink,
    SettingMultilink,
    SettingMultilinkAccess,
    SettingMultilinkInfo,
    SettingMultilinkIntegration,
    StatMultilink,
} from "../App/Pages";
import { PATH_MULTILINK } from "../constants/path";


export default function MultilinkRoutes()  {
    return (
        <Routes>
            <Route path={PATH_MULTILINK.root} element={<Multilink />} />
            <Route path={PATH_MULTILINK.page} element={<PageMultilink />} />
            <Route path={PATH_MULTILINK.stats} element={<StatMultilink />} />
            <Route path={PATH_MULTILINK.setting} element={<SettingMultilink />}>
                <Route path={PATH_MULTILINK.settingInfo} element={<SettingMultilinkInfo />} />
                <Route path={PATH_MULTILINK.settingAccess} element={<SettingMultilinkAccess />} />
                <Route path={PATH_MULTILINK.settingIntegration} element={<SettingMultilinkIntegration />} />
            </Route>
            <Route path={PATH_MULTILINK.all} index element={<AllMultilink />} />
            <Route path={PATH_MULTILINK.create} element={<CreateMultilink />} />
        </Routes>
    );
};
