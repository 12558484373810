export const PATH_STORYLANDING = {
    root: "",
    create: "create",
    id: ":id",
};

export const PATH_TOOLS = {
    root: "",
    qr: "qr",
    countLetters: "countletters",
    duplicates: "duplicates",
    utmGenerator: "utm_generator",
    passwordGenerator: "password_generator",
    translit: "translit",
    textGenerator: "text_generator",
    textGeneratorItem: "text_generator/:id",
};

export const PATH_MINISHOP = {
    root: ":id",
    index: "",
    create: "create",
};

export const PATH_MULTILINK = {
    root: "",
    page: ":id",
    stats: "stats/:id",
    setting: "setting/:id",
    settingInfo: "",
    settingAccess: "access",
    settingIntegration: "integration",
    all: "",
    create: "create",
};

export const PATH_PROFILE = {
    root: '',
    edit: 'edit',
    partners: 'partners',
    balance: 'balance',
    balanceHistory: 'balance/history',
    invoice: 'balance/invoice/:id',
  };