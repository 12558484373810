import React from "react";

import { useDispatch } from "react-redux";
import { setLanguage } from "../../../../redux/language/language.action";

import { useTranslation } from "react-i18next";

import styles from "./HeaderLanguage.module.scss";

function HeaderLanguage() {
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const changeLanguage = (event, language) => {
        event.preventDefault();
        i18n.changeLanguage(language);
        dispatch(setLanguage(language))
    };

    return (
        <>
            {i18n.language === "ru" ? (
                <div
                    className={styles.language}
                    onClick={(event) => changeLanguage(event, "en")}
                >
                    EN
                </div>
            ) : (
                <div
                    className={styles.language}
                    onClick={(event) => changeLanguage(event, "ru")}
                >
                    RU
                </div>
            )}
        </>
    );
}

export default HeaderLanguage;
