import React from "react";
import { Route, Routes } from "react-router-dom";

function HeaderDashboardRoutes() {
    return (
        <Routes>
            <Route path="/profile" element={<h2>Личный кабинет</h2>} />
            <Route path="/profile/edit" element={<h2>Основные настройки</h2>} />
        </Routes>
    );
}

export default HeaderDashboardRoutes;
