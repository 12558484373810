import React, { useEffect } from "react";
import { fetchMultilinks } from "../../../redux/multilink/multilink.action";
import { useSelector, useDispatch } from "react-redux";

import ProjectCount from "./ProjectCount/ProjectCount";
import { Col, Row } from "react-bootstrap";
import SummaryStatistics from "./SummaryStatistics/SummaryStatistics";
import CarouselComponent from "./CarouselComponent/CarouselComponent";
import ShopDashboard from "./ShopDashboard/ShopDashboard";
import { fetchMinishops } from "../../../redux/minishop/minishop.action";

function Dashboard() {
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userAuthInfo);
    useEffect(() => {
        dispatch(fetchMultilinks(user));
        dispatch(fetchMinishops(user));
    }, []);

    const multilinksFetch = useSelector(
        ({ multilink }) => multilink.multilinksFetch
    );
    const minishopsFetch = useSelector(({ minishop }) => minishop.minishopsFetch);

    return (
        <div>
            <h2>Мультиссылки</h2>
            <Row>
                <Col sm={4}>
                    <ProjectCount multilinksFetch={multilinksFetch} />
                    <CarouselComponent multilinksFetch={multilinksFetch} />
                </Col>
                <Col sm={8}>
                    <SummaryStatistics />
                </Col>
            </Row>
            <h2>Минимагазины</h2>
            <ShopDashboard minishopsFetch={minishopsFetch} />

        </div>
    );
}

export default Dashboard;
