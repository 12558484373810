import React, { useState } from "react";
import {
    ViewMultilinkSocial,
    ViewMultilinkMessanger,
    ViewMultilinkTitle,
    ViewMultilinkText,
    ViewMultilinkLink,
    ViewCopyright,
    ViewPriceList,
    ViewMultilinkList,
    ViewMultilinkFAQ,
    ViewMultilinkVideo,
} from "./";
import styles from "./ViewMultilink.module.scss";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
    BsGripVertical,
    BsPencilSquare,
    BsTrash,
    BsStickiesFill,
} from "react-icons/bs";
import { Scrollbars } from "react-custom-scrollbars-2";

function ViewMultilink({
    paid,
    data,
    setData,
    setArrayItem,
    showCanvasHandler,
}) {

    const dispatch = useDispatch();
    const [draggbleItem] = useState(true);
    // loadParams, canvas, index
    const editHandler = async (item, index) => {
        showCanvasHandler(item[item.name], { [item.name]: true }, index);
        // // //setMainInfo(data)
        // await dispatch(currentMultilink({ ...item[item.name], index: index }));
        // await setArrayItem(index);
        // await setShowCanvas({...showCanvas, social: true});
    };

    const copyHandler = (item, index) => {
        setArrayItem(index);
        // setShowCanvas(true);
    };

    const removeHandler = (item, index) => {
        setArrayItem(index);
        // setShowCanvas(true);
    };

    const [switchTools, setSwitchTools] = useState(true);

    //..............................................................................

    //// включаем возможность сортировки, работает только при нажиме на элемент. Для того чтобы при выделении инпута не включалось перетаскивание
    const [draggbleState, setDraggbleState] = useState(true);

    const [currentItemDraggble, setCurrentItemDraggble] = useState(null);

    const sortHandler = (item) => {
        setData({
            ...data,
            content: data.content.map((c) => {
                if (c.index === item.index) {
                    return { ...c, order: Number(currentItemDraggble.order) };
                }
                if (c.index === currentItemDraggble.index) {
                    return { ...c, order: Number(item.order) };
                }
                return c;
            }),
        });
    };

    const onDragStartHandler = (event, item) => {
        event.currentTarget.classList.add(styles.start);
        setCurrentItemDraggble(item);
    };

    const onDragLeaveHandler = (event) => {
        event.preventDefault();
        event.currentTarget.classList.remove(styles.active);
    };
    const onDragEndHandler = (event) => {
        event.preventDefault();
        // event.target.style.background = '#fff'
        event.currentTarget.classList.remove(styles.active);
    };

    const onDragOverHandler = (event) => {
        event.preventDefault();
        event.currentTarget.classList.add(styles.active);
    };

    const onDropHandler = (event, item) => {
        event.preventDefault();
        sortHandler(item);

        event.currentTarget.classList.remove(styles.active);
    };

    // сортировка после изменения расположения полей
    const sortItems = (a, b) => {
        if (a.order > b.order) {
            return 1;
        } else {
            return -1;
        }
    };

    return (
        <>
            <div className={styles.switchTools}>
                <Form.Check
                    type="switch"
                    id="tools-switch"
                    defaultChecked={switchTools}
                    onClick={() => setSwitchTools(!switchTools)}
                    label="Включить инструменты"
                />
            </div>

            <div
                style={{
                    // position: "relative",
                    background: `url(/phone.png)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    padding: "14px 15px 14px 14px",
                    display: "block",
                    height: "93%",
                    minHeight: "506px",
                    width: "256px",
                    overflow: "hidden",
                    zIndex: 3,
                }}
            >
                <Scrollbars
                    style={{ height: 464, zIndex: 0 }}
                    className={styles.multilink}
                >
                    {data.content.sort(sortItems).map((item, index) => (
                        <div
                            className={styles.container}
                            draggable={draggbleState}
                            axis={"y"}
                            containment={"parent"}
                            onDragStart={(event) =>
                                onDragStartHandler(event, item)
                            }
                            onDragLeave={(event) => onDragLeaveHandler(event)}
                            onDragEnd={(event) => onDragEndHandler(event)}
                            onDragOver={(event) => onDragOverHandler(event)}
                            onDrop={(event) => onDropHandler(event, item)}
                            key={`${index}__block_${item.name}`}
                        >
                            {item.social && (
                                <ViewMultilinkSocial
                                    social={item.social}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                    // key={`ViewMultilinkSocial_${item.index}`}
                                />
                            )}
                            {item.messanger && (
                                <ViewMultilinkMessanger
                                    messanger={item.messanger}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                    // key={`ViewMultilinkMessanger_${item.index}`}
                                />
                            )}
                            {item.title && (
                                <ViewMultilinkTitle
                                    title={item.title}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                    // key={`ViewMultilinkTitle_${item.index}`}
                                />
                            )}
                            {item.text && (
                                <ViewMultilinkText
                                    text={item.text}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                    // key={`ViewMultilinkText_${item.index}`}
                                />
                            )}
                            {item.blockLink && (
                                <ViewMultilinkLink
                                    blockLink={item.blockLink}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                />
                            )}
                            {item.priceList && (
                                <ViewPriceList
                                    priceList={item.priceList}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                />
                            )}
                            {item.listBlock && (
                                <ViewMultilinkList
                                    listBlock={item.listBlock}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                />
                            )}
                            {item.faqBlock && (
                                <ViewMultilinkFAQ
                                    faqBlock={item.faqBlock}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                />
                            )}
                            {item.videoBlock && (
                                <ViewMultilinkVideo
                                    videoBlock={item.videoBlock}
                                    onMouseDown={() => setDraggbleState(false)}
                                    onMouseUp={() => setDraggbleState(true)}
                                />
                            )}
                            {switchTools && (
                                <div
                                    className={styles.setting}
                                    // onClick={() => settingHandler(item, index)}
                                >
                                    <div>
                                        <Button size="sm" variant="light">
                                            <BsGripVertical />
                                        </Button>{" "}
                                    </div>
                                    <div>
                                        <Button
                                            size="sm"
                                            variant="light"
                                            id={item.name}
                                            onClick={(event) =>
                                                editHandler(item, index)
                                            }
                                        >
                                            <BsPencilSquare />
                                        </Button>{" "}
                                        <Button
                                            size="sm"
                                            variant="light"
                                            onClick={() =>
                                                copyHandler(item, index)
                                            }
                                        >
                                            <BsStickiesFill />
                                        </Button>{" "}
                                        <Button
                                            size="sm"
                                            variant="light"
                                            onClick={() =>
                                                removeHandler(item, index)
                                            }
                                        >
                                            <BsTrash />
                                        </Button>{" "}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}

                    <ViewCopyright paid={paid} data={data} />
                </Scrollbars>
            </div>
        </>
    );
}

export default ViewMultilink;
