import React, { useEffect } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOpenAi } from "../../../../../redux/openai/openai.action";
import { capitalazeFirstLetter } from './../../../../../utils/capitalazeFirstLetter';

function OpenAiTextItem({ item }) {
    const currentItem = useSelector(({ openAi }) => openAi.openaiCurrent);
    const { id: userId } = useSelector(({ user }) => user.userAuthInfo);

    const dispatch = useDispatch();

    useEffect(() => {
        const projectId = window.location.pathname
            .toString()
            .split("/tools/text_generator/")[1];

        const data = {
            projectId,
            userId,
        };

        dispatch(getOpenAi(data));
    }, [dispatch, userId]);

    return (
        <>
            <Row>
                <Col sm={7}>
                    {currentItem ? (
                        <div>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        {capitalazeFirstLetter(currentItem.textResponse)}
                                    </Card.Text>
                                    <div>
                                        <Button>
                                            Сгенерировать новое описание
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ) : (
                        "loading"
                    )}
                </Col>
                <Col sm={5}>
                        <Alert variant="info">
                        Сгенерированные тексты могут иметь небольшие недочёты. Наш бот со временем станет умнее, но уже сейчас он может как минимум сократить рутину
                        </Alert>
                </Col>
            </Row>
        </>
    );
}

export default OpenAiTextItem;
