import React from "react";
import { useSelector } from "react-redux";
import StoryLandingElementView from "../StoryLandingSlide/StoryLandingElement/StoryLandingElementView";

function ViewStorylanding({ project }) {

    const slide = useSelector(({ storyLanding }) => storyLanding.currentSlide);
    const elements = useSelector(({ storyLanding }) => storyLanding.elements);


    const styles = {
        container: {
            height: "380px",
            width: "100%",
            maxWidth: "calc(9 / 16 * 380px)",
            backgroundColor: "#e9ecef",
            borderRadius: "8px",
            overflow: "hidden",
        },
        block: {            padding: "10px",

            height: "100%",
            width: "100%",
            ...(slide?.background.type === "image"
                ? { backgroundImage: `url(${slide?.background.data})` }
                : {}),
            ...(slide?.background.type === "color"
                ? { backgroundColor: slide?.background.data }
                : {}),
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.block}>
            
                {slide && elements.map((element) => {

                    return (
                        <StoryLandingElementView
                            key={element._id}
                            element={element}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default ViewStorylanding;
