import ProfileActionTypes from './profile.types'

const INITIAL_STATE = {
    profile:{},
	profileInvoices:[],
	profileInvoice:{}

}


const profileReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		// case ProfileActionTypes.PROFILE_EDIT:
		// 	return {
		// 		...state,
		// 		profileEdit: action.payload
		// 	}
		case ProfileActionTypes.PROFILE_INVOICES:
			return {
				...state,
				profileInvoices: action.payload
			}
		case ProfileActionTypes.PROFILE_INVOICE:
			return {
				...state,
				profileInvoice: action.payload
			}

		default:
			return state;
	}
}

export default profileReducer