import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Button, Form } from "react-bootstrap";
import styles from "../QrCreator.module.scss";

function QrCreatorVcard({
    qrData,
    setQrData,
    qrDataVcardDefault,
    setQrDataVcardDefault,
}) {
    console.log("qrDataVcardDefault", qrDataVcardDefault);
    const defaultVcard = {
        name: "",
        org: "",
        job: "",
        adress: "",
        addressWeb: "",
        phoneMain: "",
        phoneMobile: "",
        email: "",
        note: "",
    };

    const [vcard, setVcard] = useState(defaultVcard);

    const testHandler = (e) => {
        setVcard({ ...vcard, [e.target.name]: e.target.value });
    };

    const vcardDataHandler = (vcard) => {
        setQrData({
            ...qrData,
            text:
                vcard &&
                ` BEGIN:VCARD\r\nVERSION:3.0\r\n${
                    vcard.name ? "N:;" + vcard.name + "\r\n" : ""
                }${vcard.org ? "ORG:" + vcard.org + ";;\r\n" : ""}${
                    vcard.job ? "TITLE:" + vcard.job + "\r\n" : ""
                }${
                    vcard.phoneMain
                        ? "TEL;TYPE=WORK,VOICE:" + vcard.phoneMain + "\r\n"
                        : ""
                }${
                    vcard.phoneMobile
                        ? "TEL;TYPE=CELL,VOICE:" + vcard.phoneMobile + "\r\n"
                        : ""
                }${
                    vcard.adress
                        ? "ADR;TYPE=WORK:;;" + vcard.adress + "\r\n"
                        : ""
                }${vcard.addressWeb ? "URL:" + vcard.addressWeb + "\r\n" : ""}${
                    vcard.email ? "EMAIL:" + vcard.email + "\r\n" : ""
                }${vcard.note ? "NOTE:" + vcard.note + "\r\n" : ""}END:VCARD`,
        });
    };
    console.log("vcard", vcard);

    // костыль который надо исправить
    // суть проблемы в том что при клике по табам в qrCreator должно происходить очищение полей и возвращение к дефолтным значениям
    // но при этом при первом рендере useEffect срабатывает дважды и второй раз перезаписывает дефолтные значения
    // поэтому пришлось сделать такой костыль
    // надо найти другое решение
    //////////////////////////////////////////
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            setVcard(defaultVcard);
        } else {
            if (
                vcard.name.length > 0 ||
                vcard.org.length > 0 ||
                vcard.job.length > 0 ||
                vcard.phoneMain.length > 0 ||
                vcard.phoneMobile.length > 0 ||
                vcard.adress.length > 0 ||
                vcard.addressWeb.length > 0 ||
                vcard.email.length > 0 ||
                vcard.note.length > 0
            ) {
                vcardDataHandler(vcard);
            }
        }
    }, [vcard]);

    useEffect(() => {
        if (qrDataVcardDefault) {
            setVcard(defaultVcard);
            setQrDataVcardDefault(false);
        }
    }, [qrDataVcardDefault && vcard !== defaultVcard]);
    //////////////////////////////////////////

    return (
        <Form>
            <Form.Group controlId="formName" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Введите имя"
                    name="name"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.name}
                />
            </Form.Group>
            <Form.Group controlId="formPhone" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Телефон"
                    name="phoneMain"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.phoneMain}
                />
            </Form.Group>
            {/* <Form.Group controlId="formPhoneMobile" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Телефон"
                    name="phoneMobile"
                    onChange={testHandler}
                />
            </Form.Group> */}

            <Form.Group controlId="formEmail" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Email"
                    name="email"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.email}
                />
            </Form.Group>
            <Form.Group controlId="formOrg" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Организация"
                    name="org"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.org}
                />
            </Form.Group>
            <Form.Group controlId="formJob" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Должность"
                    name="job"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.job}
                />
            </Form.Group>
            <Form.Group controlId="formAdress" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Адрес"
                    name="adress"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.adress}
                />
            </Form.Group>
            <Form.Group controlId="formAddressWeb" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Адрес сайта"
                    name="addressWeb"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.addressWeb}
                />
            </Form.Group>
            <Form.Group controlId="formNote" className={styles.block}>
                <Form.Control
                    type="text"
                    placeholder="Заметка"
                    name="note"
                    onChange={testHandler}
                    size="sm"
                    value={vcard && vcard.note}
                />
            </Form.Group>
        </Form>
    );
}

// QrCreatorVcard.defaultProps = {
//     qrData: { text: "Alan" },
// };

QrCreatorVcard.propTypes = {
    qrData: PropTypes.object.isRequired,
    setQrData: PropTypes.func.isRequired,
};

export default QrCreatorVcard;
