import $api from "../http";

export default class MoneyService {
    static async addMoney(amount, id) {
        return $api.post(`/money/add/${id}`, {amount, id})
    } 
    
    static async fetchInvoices(id) {
        return $api.get(`/money/fetch/invoices/${id}`)
    } 
    
    static async checkInvoice(data, id) {
        console.log('checkInvoice', data);
        return $api.put(`/money/check/invoice/${data._id}`, {data, id})
    } 

    static async getInvoice(user, link) {
        return $api.post(`/money/get/invoice/${link}`, {user, link})
    }
    
}