import React, { useEffect, useState } from "react";
import { ListGroupItem, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    getSlides,
    setCurrentSlide,
    getElement
} from "../../../../redux/storyLanding/storyLanding.action.js";
import StoryLandingSlideView from "./StoryLandingSlideView.jsx";
import { useOffcanvas } from "../../../../hook/Context/OffcanvasContext.js";
import styles from "./StoryLandingElement/StoryLandingElement.module.scss";

const StoryLandingSlideList = ({ styles }) => {
    const projectId = useParams().id;

    const dispatch = useDispatch();

    const slides = useSelector(({ storyLanding }) => storyLanding.slides);
    const currentSlide = useSelector(
        ({ storyLanding }) => storyLanding.currentSlide
    );
    const currentElement = useSelector(
        ({ storyLanding }) => storyLanding.currentElement
    )
    useEffect(() => {
        if (slides.length === 0) {
            dispatch(getSlides(projectId));
        }
    }, [slides]);

    // const [showOffCanvas, setShowOffCanvas] = useState(false);

    // const handleShowOffCanvas = () => setShowOffCanvas(true);

    const [isActive, setIsActive] = useState(null);


    const handleClick = (key, slide) => {
        if (isActive === key) {          

          setIsActive(null);
        //   closeOffcanvas();
          dispatch(setCurrentSlide(null));

        } else {
          setIsActive(key);
        //   openOffcanvas();
          dispatch(setCurrentSlide(slide));
        }
      };

    
    
    return (
        <>





            {slides.map((slide) => (
                <ListGroupItem
                    key={slide._id}
                    className={`${styles.slide} ${
                        isActive === slide._id ? styles.active : ""
                    } `}
                    onClick={() => handleClick(slide._id, slide)}
                >
                    {slide.background.data}
                </ListGroupItem>
            ))}
        </>
    );
};

export default StoryLandingSlideList;
