import languageTypes from './language.types'

const INITIAL_STATE = {
	language: {},

}

const languageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case languageTypes.CURRENT_LANGUAGE:
            return {
				...state,
				language: action.payload
			}
        case languageTypes.SET_LANGUAGE:
            return {
				...state,
				language: action.payload
			}
        default:
            return state;
    }
}

export default languageReducer