// PageShopAddCat.js
import React, { useEffect, useState } from "react";
import { Card, Button, Modal, Tabs, Tab, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { minishopCategoryAdd } from "../../../../../../redux/minishop/minishop.action";
import AddCategory from "./AddCategory/AddCategory";


function PageShopAddCat({shop, userId}) {    
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();

    useEffect(() => {
        setCategories(shop.labels || []);
    }, [shop]);


    const handleCategory = (e) => {
        e.preventDefault();
        dispatch(minishopCategoryAdd({ category }));
    };



    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Категории</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs id="uncontrolled-tab-example" variant="pills">
                        <Tab eventKey="add-category" title="Добавить">
                            <AddCategory category={category} setCategory={setCategory} />
                        </Tab>
                        <Tab eventKey="all-categories" title="Все категории">
                            <p>All Categories will be listed here</p>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card>
                <Card.Body>
                    <Card.Title>
                        Категории: <b>0</b>
                    </Card.Title>
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleShowModal}
                    >
                        Добавить категорию
                    </Button>
                </Card.Body>
            </Card>


        </>
    );
}

export default PageShopAddCat;
