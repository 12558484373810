import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoice } from "../../../../../../../redux/profile/profile.action";
import { ListGroup, Row, Col, Spinner } from "react-bootstrap";

function ProfileInvoice() {
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userInfo);
    const invoice = useSelector(({ profile }) => profile.profileInvoice);

    const link = window.location.pathname.split("/")[4];

    useEffect(() => {
        dispatch(getInvoice(user, link));
    }, []);

    if (!invoice) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    return (
        <Row>
            <Col sm={5}>
                <ListGroup>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                        <div>Статус платежа</div>{" "}
                        <div>
                            {invoice.status === "succeeded" && "Оплачено"}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                        <div>ID</div> <div>{invoice.paymentId}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                        <div>Сумма пополнения</div> <div>{invoice.amount} ₽</div>
                    </ListGroup.Item>
                    {invoice.paymentMethod &&
                        invoice.paymentMethod.method === "bank_card" && (
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <div>Банковская карта </div>{" "}
                                <div>
                                    *{invoice.paymentMethod.cardnumber}{" "}
                                    <img
                                        src={`/paysystem/${invoice.paymentMethod.cardtype}.svg`}
                                        width="32px"
                                        alt={invoice.paymentMethod.cardtype}
                                    />
                                </div>
                            </ListGroup.Item>
                        )}

                </ListGroup>
            </Col>
            <Col></Col>
        </Row>
    );
}

export default ProfileInvoice;
