import StoryLandingActionTypes from "./storyLanding.types";

const initialState = {
    projects: [],
    currentProject: null,
    slides: [],
    currentSlide: null,
    elements: [],
    currentElement: null,
};

const storyLandingReducer = (state = initialState, action) => {
    switch (action.type) {
        case StoryLandingActionTypes.CREATE_PROJECT:
            return { ...state, projects: [...state.projects, action.payload] };
        case StoryLandingActionTypes.GET_PROJECTS:
            return { ...state, projects: action.payload };
        case StoryLandingActionTypes.FETCH_PROJECTS:
            return { ...state, projects: action.payload };
        case StoryLandingActionTypes.GET_PROJECT:
            return { ...state, currentProject: action.payload };
        case StoryLandingActionTypes.UPDATE_PROJECT:
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project._id === action.payload._id
                        ? action.payload
                        : project
                ),
            };
        case StoryLandingActionTypes.DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(
                    (project) => project._id !== action.payload
                ),
            };
        //////////////////////////
        case StoryLandingActionTypes.CREATE_SLIDE:
            return { ...state, slides: [...state.slides, action.payload] };
        case StoryLandingActionTypes.GET_SLIDES:
            return { ...state, slides: action.payload };
        case StoryLandingActionTypes.SET_CURRENT_SLIDE:
            return { ...state, currentSlide: action.payload };
        case StoryLandingActionTypes.GET_SLIDE:
            return { ...state, currentSlide: action.payload };
        case StoryLandingActionTypes.UPDATE_SLIDE:
            return {
                ...state,
                slides: state.slides.map((slide) =>
                    slide._id === action.payload._id ? action.payload : slide
                ),
            };
        case StoryLandingActionTypes.DELETE_SLIDE:
            return {
                ...state,
                slides: state.slides.filter(
                    (slide) => slide._id !== action.payload
                ),
            };
        //////////////////////////
        case StoryLandingActionTypes.CREATE_ELEMENT:
            return { ...state, elements: [...state.elements, action.payload] };
 
        case StoryLandingActionTypes.GET_ELEMENTS:
            return { ...state, elements: action.payload };
        case StoryLandingActionTypes.GET_ELEMENT:
            return { ...state, currentElement: action.payload };
        case StoryLandingActionTypes.UPDATE_ELEMENT:
            return {
                ...state,
                elements: state.elements.map((element) =>
                    element._id === action.payload._id
                        ? action.payload
                        : element
                ),
            };
        case StoryLandingActionTypes.DELETE_ELEMENT:
            return {
                ...state,
                elements: state.elements.filter(
                    (element) => element._id !== action.payload
                ),
            };

        default:
            return state;
    }
};
export default storyLandingReducer;

// export const slideReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case StoryLandingActionTypes.CREATE_SLIDE:
//             return { ...state, slides: [...state.slides, action.payload] };
//         case StoryLandingActionTypes.GET_SLIDES:
//             return { ...state, slides: action.payload };
//         case StoryLandingActionTypes.GET_SLIDE:
//             return { ...state, currentSlide: action.payload };
//         case StoryLandingActionTypes.UPDATE_SLIDE:
//             return {
//                 ...state,
//                 slides: state.slides.map((slide) =>
//                     slide._id === action.payload._id ? action.payload : slide
//                 ),
//             };
//         case StoryLandingActionTypes.DELETE_SLIDE:
//             return {
//                 ...state,
//                 slides: state.slides.filter(
//                     (slide) => slide._id !== action.payload
//                 ),
//             };
//         default:
//             return state;
//     }
// };

// export const elementReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case StoryLandingActionTypes.CREATE_ELEMENT:
//             return { ...state, elements: [...state.elements, action.payload] };
//         case StoryLandingActionTypes.GET_ELEMENTS:
//             return { ...state, elements: action.payload };
//         case StoryLandingActionTypes.GET_ELEMENT:
//             return { ...state, currentElement: action.payload };
//         case StoryLandingActionTypes.UPDATE_ELEMENT:
//             return {
//                 ...state,
//                 elements: state.elements.map((element) =>
//                     element._id === action.payload._id
//                         ? action.payload
//                         : element
//                 ),
//             };
//         case StoryLandingActionTypes.DELETE_ELEMENT:
//             return {
//                 ...state,
//                 elements: state.elements.filter(
//                     (element) => element._id !== action.payload
//                 ),
//             };
//         default:
//             return state;
//     }
// };
