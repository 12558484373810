import React from 'react';

const ButtonElement = ({ content, styles }) => {

  const defaultStyle = {
    padding: '10px',
  }

  // сделать content.label
  // content.link
  // типы ссылки почта, мыло, телефон

  return (
    <button style={styles ? styles : defaultStyle} onClick={content.onClick}>
      {content}
    </button>
  );
};

export default ButtonElement;