import React, { useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
    createElement,
    updateElement,
} from "../../../../../redux/storyLanding/storyLanding.action.js";
import {
    AddColorComponent,
    InputComponent,
} from "../../../../Components/index.js";

import { useMemo } from "react";
import stylesComponent from "./StoryLandingElement.module.scss";
const StoryLandingElementForm = ({ slideId, element = null, type, setType }) => {
    const [content, setContent] = useState(element ? element.content : "");
    const [styles, setstylesElement] = useState(element ? element.styles : {});

    const [animations, setAnimations] = useState(
        element ? element.animations : {}
    );


    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (element) {
            dispatch(
                updateElement({
                    ...element,
                    slideId,
                    type,
                    content,
                    styles,
                    animations,
                })
            );
        } else {
            dispatch(
                createElement({ slideId, type, content, styles, animations })
            );
        }
    };

    const handleChangeStyle = (e) => {
        setstylesElement((prevstylesElement) => ({
            ...prevstylesElement,
            [e.target.name]: {
                value: Number(e.target.value),
                units: e.target.dataset.units,
            },
        }));
    };

    const colorBgHandler = (hex) => {
        setstylesElement((prevstylesElement) => ({
            ...prevstylesElement,
            backgroundColor: hex,
        }));
    };
    const colorTextHandler = (hex) => {
        setstylesElement((prevstylesElement) => ({
            ...prevstylesElement,
            color: hex,
        }));
    };

    useMemo(() => {
        if (element) {
            setType(element.type);
            setContent(element.content);
            setstylesElement(element.styles);
            setAnimations(element.animations);
        }
    }, [element]);

    return (

        <>




        <Form onSubmit={handleSubmit}>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="Home">
                    {/* <Form.Group controlId="elementType">
                        <Form.Label>Тип элемента:</Form.Label>
                        <Form.Select
                            as="select"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="text">Текст</option>
                            <option value="button">Кнопка</option>
                            <option value="image">Изображение</option>
                            <option value="video">Видео</option>
                            <option value="form">Форма</option>
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Group controlId="elementContent">
                        <Form.Label>Контент элемента:</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </Form.Group>
                </Tab>
                <Tab eventKey="design" title="Дизайн">
                    <InputComponent
                        data={{
                            title: "Наружный отступ",
                            units: "px",
                            label: {
                                top: "Сверху",
                                right: "Справа",
                                bottom: "Снизу",
                                left: "Слева",
                            },
                        }}
                        elementStyles={{
                            marginTop: styles?.marginTop || 0,
                            marginRight: styles?.marginRight || 0,
                            marginBottom: styles?.marginBottom || 0,
                            marginLeft: styles?.marginLeft || 0,
                        }}
                        handler={handleChangeStyle}
                    />

                    <InputComponent
                        data={{
                            title: "Внутренний отступ",
                            units: "px",
                            label: {
                                top: "Сверху",
                                right: "Справа",
                                bottom: "Снизу",
                                left: "Слева",
                            },
                        }}
                        elementStyles={{
                            paddingTop: styles?.paddingTop || 0,
                            paddingRight: styles?.paddingRight || 0,
                            paddingBottom: styles?.paddingBottom || 0,
                            paddingLeft: styles?.paddingLeft || 0,
                        }}
                        handler={handleChangeStyle}
                    />

                    <InputComponent
                        data={{
                            title: "Закругление элемента",
                            units: "px",
                            label: {
                                top: "Сверху слева",
                                right: "Сверху справа",
                                bottom: "Снизу справа",
                                left: "Снизу слева",
                            },
                        }}
                        elementStyles={{
                            borderTopLeftRadius:
                                styles?.borderTopLeftRadius || 0,
                            borderTopRightRadius:
                                styles?.borderTopRightRadius || 0,
                            borderBottomRightRadius:
                                styles?.borderBottomRightRadius || 0,
                            borderBottomLeftRadius:
                                styles?.borderBottomLeftRadius || 0,
                        }}
                        handler={handleChangeStyle}
                    />

                    <div className={stylesComponent.block}>
                        <div>
                            Цвет фона
                            <AddColorComponent
                                color={styles && styles.backgroundColor}
                                colorHandler={colorBgHandler}
                            />
                        </div>
                        <div>
                            Цвет текста
                            <AddColorComponent
                                color={styles && styles.color}
                                colorHandler={colorTextHandler}
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>

            {/* <Form.Group controlId="elementstylesElement">
                <Form.Label>Стили элемента (в формате JSON):</Form.Label>
                <Form.Control
                    as="textarea"
                    value={JSON.stringify(styles)}
                    onChange={(e) => setstylesElement(JSON.parse(e.target.value))}
                />
            </Form.Group>
            <Form.Group controlId="elementAnimations">
                <Form.Label>Анимации элемента (в формате JSON):</Form.Label>
                <Form.Control
                    as="textarea"
                    value={JSON.stringify(animations)}
                    onChange={(e) => setAnimations(JSON.parse(e.target.value))}
                />
            </Form.Group> /*/}
            {content.length > 0 && 
                <Button type="submit">
                    {element ? "Обновить элемент" : "Создать элемент"}
                </Button>            
            }

        </Form> 


        </>


    );
};

export default StoryLandingElementForm;
