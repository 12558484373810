import React from "react";
import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
} from "recharts";
import styles from "./SummaryStatistics.module.scss";
import { Col, Row } from 'react-bootstrap';

function SummaryStatistics() {
    const data = [
        {
            name: "02.10.22",
            hit: 100,
            host: 240,
        },
        {
            name: "03.10.22",
            hit: 100,
            host: 139,
        },
        {
            name: "04.10.22",
            hit: 200,
            host: 480,
        },
        {
            name: "05.10.22",
            hit: 278,
            host: 390,
        },
        {
            name: "06.10.22",
            hit: 189,
            host: 480,
        },
        {
            name: "07.10.22",
            hit: 239,
            host: 380,
        },
        {
            name: "08.10.22",
            hit: 349,
            host: 430,
        },
    ];

    const host = data.reduce((accum,item) => accum + item.hit, 0)
    const hit = data.reduce((accum,item) => accum + item.host, 0)
    const oneDayHit = data[data.length - 1].hit

    // https://recharts.org/en-US/api/BarChart
    return (
        <div className={styles.container}>
            <h4>Статистика по всем проектам за 7 дней</h4>

            <Row>
                <Col sm={3}>
                    <div className={styles.count_block}>
                        <p>Всего переходов: </p>
                        <div className={styles.count_text}>{host}<span>+16%</span></div>
                        
                    </div>                
                </Col>
                <Col sm={3}>
                    <div className={styles.count_block}>
                        <p>Уникальных переходов: </p>
                        <div className={styles.count_text}>{hit}<span>+16%</span></div>
                        
                    </div>                   
                </Col>
                <Col sm={3}>
                    <div className={styles.count_block}>
                        <p>Суточная статистика: </p>
                        <div className={styles.count_text}>{oneDayHit}<span>+1%</span></div>
                        
                    </div>                   
                </Col>
            </Row>


            <ResponsiveContainer width="100%" height={230}>
                <BarChart
                    data={data}
                    margin={{ top: 0, right: 30, left: -24, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="host" name="Все клики" fill="#10062b" />
                    <Bar dataKey="hit" name="Уникальные клики" fill="#ad69d5" />
                </BarChart>
            </ResponsiveContainer>
			<small>На графике показаны переходы пользователей на все ваши проекты мультиссылок</small>
        </div>
    );
}

export default SummaryStatistics;
