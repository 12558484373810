import React from "react";

import { Logo, Container, Item, Button, Title } from './ViewMultilinkMessanger.styled'


function ViewMultilinkMessanger({ messanger }) {
  return (
    <>

    {messanger.setting.mode && (
        <Container
            container={messanger.design_block} 
            typeButton={messanger.design.type_button}
        >
            {messanger.items.map((item, index) => (
                <Item 
                    key={`${index}_messanger_${item.name}`} 
                    nameSocial={item.name}
                    color={item.color}
                    styleButton={messanger.design.style_button}
                    shapeButton={messanger.design.shape_button}
                >
                    <Button 
                        styleButton={messanger.design.style_button}
                        shapeButton={messanger.design.shape_button}
                        typeButton={messanger.design.type_button}
                    >
                        {(messanger.design.type_button === 'logo' || messanger.design.type_button ===  'all') &&
                            <Logo >
                                <img
                                    src={`/social/brand/${messanger.design.style_button === 'bg' ? `${item.name}-W` : item.name}.png`}
                                    width="26"
                                    alt={`${item.title}`}
                                />
                            </Logo>
                        }
                        {(messanger.design.type_button === 'text' || messanger.design.type_button === 'all') &&
                            <Title
                                typeButton={messanger.design.type_button}
                            >{item.title}</Title>                      
                        }                                     
                    </Button>

                </Item>
            ))}

        </Container>
    )}
</>
  )
}

export default ViewMultilinkMessanger
