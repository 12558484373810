import $api from '../http';

export default class StoryLandingService {
  static async createProject(data) {
    console.log('createProject', data);
    return await $api.post('/storylanding/project/create', data);
  }

  static async fetchProjects() {
    console.log('fetchProjects');
    return await $api.get("/storylanding/projects");
  }

  static async getProjects() {
    return await $api.get('/storylanding/project/list');
  }

  static async getProject(projectId) {
    return await $api.get(`/storylanding/project/${projectId}`);
  }

  static async updateProject(projectId, data) {
    return await $api.put(`/storylanding/project/${projectId}`, data);
  }

  static async deleteProject(projectId) {
    return await $api.delete(`/storylanding/project/${projectId}`);
  }


  // slides
  static async createSlide(data) {
    ///project/:projectId/slide
    return await $api.post(`/storylanding/project/${data.projectId}/slide/create`, data);
  }

  static async getSlides(projectId) {
    return await $api.get(`/storylanding/project/${projectId}/slides`);
  }

  static async getSlide(slideId) {
    return await $api.get(`/storylanding/slide/${slideId}`);
  }

  static async updateSlide(slideId, data) {
    return await $api.put(`/storylanding/slide/${slideId}`, data);
  }

  static async deleteSlide(slideId) {
    return await $api.delete(`/storylanding/slide/${slideId}`);
  }

  // elements  
  static async getElements(slideId) {
    console.log('slideId', slideId);
    return await $api.get(`/storylanding/slide/${slideId}/elements`);
  }
  
  static async createElement(data) {
    return await $api.post('/storylanding/element/create', data);
  }



  static async getElement(elementId) {
    return await $api.get(`/storylanding/element/${elementId}`);
  }

  static async updateElement(data) {
    return await $api.put(`/storylanding/element/${data._id}`, data);
  }

  static async deleteElement(elementId) {
    return await $api.delete(`/storylanding/element/${elementId}`);
  }


}
