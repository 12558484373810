import $api from "../http";

export default class MiniShopService {


    static async create(data) {
        console.log('/minishop/create', data);
        return await $api.post('/minishop/create', {data})
    }   

    static async fetch(data) {
        console.log('/minishop/fetch', data);
        return $api.post(`/minishop/fetch`, {data})
    } 

    static async get(projectId, userId) {
        // console.log('projectId, userId', projectId, userId);
        return $api.post(`/minishop/get/${projectId}`, {projectId, userId})
    } 

    // работаем с лэйблами
    static async minishopLabelAdd(data) {
        console.log(`/minishop/addShopItemsLabel/${data.minishopId}`, data);
        return $api.post(`/minishop/addShopItemsLabel/${data.minishopId}`, {data})
    }

    static async minishopLabelRemove(data) {
        return $api.post(`/minishop/label/remove/${data.minishopId}`, {data})       
    }

    static async minishopLabelCopy(data) {
        return $api.post(`/minishop/label/copy/${data.minishopId}`, {data})
    }

    static async minishopLabelSwitch(data) {
        return $api.post(`/minishop/label/switch/${data.minishopId}`, {data})
    }
}




