import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    Balance,
    BalanceHistory,
    EditPage,
    Partners,
    ProfileInvoice,
    ProfilePage,
} from "../App/Pages/Profile";
import { PATH_PROFILE } from "../constants/path";

export default function ProfileRoutes() {
    return (
        <Routes>
            <Route path={PATH_PROFILE.root} element={<ProfilePage />} />
            <Route path={PATH_PROFILE.edit} element={<EditPage />} />
            <Route path={PATH_PROFILE.partners} element={<Partners />} />
            <Route path={PATH_PROFILE.balance} element={<Balance />} />
            <Route path={PATH_PROFILE.balanceHistory} element={<BalanceHistory />} />
            <Route path={PATH_PROFILE.invoice} element={<ProfileInvoice />} />
        </Routes>
    );
}
