import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Row, Col, Button } from "react-bootstrap";
import styles from "../Header.module.scss";
import HeaderOutlet from "../HeaderOutlet/HeaderOutlet";
import HeaderMultilink from "../HeaderMultilink/HeaderMultilink";
import { BsPlusCircle } from "react-icons/bs";

function HeaderRoutes() {
    const { t } = useTranslation("multilink");

    const project = useSelector(({ multilink }) => multilink.multilink);

    return (
        <Row>
            <Col sm={8}>
                <Routes>
                    <Route path="/" element={<HeaderOutlet />} />
                    <Route path="multilink" element={<HeaderOutlet />}>
                        <Route
                            index
                            element={
                                <HeaderMultilink
                                    info={{
                                        title: t("multilink.header.list"),
                                    }}
                                />
                            }
                        />
                        <Route
                            path="create"
                            element={
                                <HeaderMultilink
                                    info={{
                                        title: t("multilink.header.create"),
                                    }}
                                />
                            }
                        />
                        <Route
                            index
                            path=":id"
                            element={
                                <div className={styles.multilink}>
                                    <div className={styles.title}>
                                        <h1 className={styles.h1}>
                                            Проект:{" "}
                                            {project.mainMultilink &&
                                                project.mainMultilink.title}
                                        </h1>
                                        <p className={styles.desc}>
                                            {project.mainMultilink &&
                                                project.mainMultilink.desc}
                                        </p>
                                    </div>
                                    {project.mainMultilink &&
                                    project.mainMultilink.paid ? (
                                        <div className={styles.plan}>
                                            <div className={styles.plan__desc}>
                                                Ваш тариф
                                            </div>
                                            <div className={styles.plan__title}>
                                                PRO
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.plan}>
                                            <div className={styles.plan__desc}>
                                                Ваш тариф
                                            </div>
                                            <div className={styles.plan__title}>
                                                FREE
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        />
                        <Route
                            path="stats/:id"
                            element={
                                <HeaderMultilink
                                    info={{
                                        title: t("multilink.header.stat"),
                                    }}
                                />
                            }
                        />
                        <Route
                            path="setting/:id"
                            element={
                                <HeaderMultilink
                                    info={{
                                        title: t(
                                            "multilink.header.settingMain"
                                        ),
                                    }}
                                />
                            }
                        />
                        <Route
                            path="setting/:id/access"
                            element={
                                <HeaderMultilink
                                    info={{
                                        title: t(
                                            "multilink.header.settingAccess"
                                        ),
                                    }}
                                />
                            }
                        />
                        <Route
                            path="setting/:id/integration"
                            element={
                                <HeaderMultilink
                                    info={{
                                        title: t(
                                            "multilink.header.settingIntegration"
                                        ),
                                    }}
                                />
                            }
                        />
                    </Route>
                </Routes>
            </Col>
            <Col sm={4}>
                <Routes>
                    <Route
                        path="multilink"
                        element={
                            <Button
                                as={NavLink}
                                variant="dark"
                                type="submit"
                                to="create"
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    alignContent: 'flex-start',
                                    gap:'5px',
                                }}
                            >
                                
                                <BsPlusCircle />{" "} Новый проект
                            </Button>
                        }
                    >
                        <Route path="create" />
                        <Route path=":id" />
                    </Route>
                </Routes>
            </Col>
        </Row>
    );
}

export default HeaderRoutes;
