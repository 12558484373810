import styled  from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.container.design_block.background_color};

    font-size: 21px;
    padding: ${(props) => props.container.design_block.background_padding.top}px
        ${(props) => props.container.design_block.background_padding.right}px
        ${(props) => props.container.design_block.background_padding.bottom}px
        ${(props) => props.container.design_block.background_padding.left}px;
    margin: ${(props) => props.container.design_block.background_margin.top}px
        ${(props) => props.container.design_block.background_margin.right}px
        ${(props) => props.container.design_block.background_margin.bottom}px
        ${(props) => props.container.design_block.background_margin.left}px;

    &:after {
        content: "";
        filter: brightness(
                ${(props) => props.container.design_block.brightness}%
            )
            blur(${(props) => props.container.design_block.blur}px)
            opacity(${(props) => props.container.design_block.opacity}%);
    }
`;

export const Header = styled.div`
    h1,
    h2,
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        text-align: ${(props) => props.container.main.textAlign};
        text-transform: ${(props) => props.container.main.transform};
        font-size: ${(props) => props.container.main.size};
        font-weight: ${(props) => props.container.main.weight};
        color: ${(props) => props.container.main.color};
    }
`;
