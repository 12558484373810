import React from 'react'
import { NavLink } from 'react-router-dom'

import { Nav } from 'react-bootstrap'
import styles from './Sidebar.module.scss'
import { BsPersonCircle } from 'react-icons/bs'
import { useTranslation } from 'react-i18next';

function SidebarSetting() {

    const { t } = useTranslation("sidebar");


    return (
        <Nav className={`${styles.setting} flex-column`} >

            <NavLink
             className={({ isActive }) => (isActive ? `${styles.link} ${styles.active}`: `${styles.link}` )}
             to="/profile">
              <span><BsPersonCircle /> </span> {t("sidebar.menu.profile")}
            </NavLink>
        </Nav>
    )
}

export default SidebarSetting
