import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteProject,
    fetchProjects,
} from "../../../redux/storyLanding/storyLanding.action";
import { NoneProject, ProjectList } from "../../Components";

const StoryLandingProjectList = () => {
    const dispatch = useDispatch();
    const projects = useSelector(({ storyLanding }) => storyLanding.projects);

    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    if (projects.length === 0) {
        return (
            <Row>
                <Col sm={7}>
                    <NoneProject />
                </Col>
                <Col sm={5}></Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col sm={7}>
                <h1>Список проектов</h1>
                {projects.map((project) => (
                    <ProjectList
                        project={project}
                        removeProject={deleteProject}
                        key={project._id}
                    />
                ))}
            </Col>
            <Col sm={5}></Col>
        </Row>
    );
};

export default StoryLandingProjectList;
