import React from "react";

import { Alert, Button } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import styles from './NoneProject.module.scss'
import { NavLink } from 'react-router-dom';

function NoneProject() {
    return (
        <Alert variant="secondary" className={styles.container}>
            <Alert.Heading>У Вас нет созданных проектов!</Alert.Heading>
            <Button as={NavLink} to='create' variant="primary" type="submit"> <BsPlusCircle /><span> Создать проект</span></Button>
            <Alert.Heading>Пора это исправить!</Alert.Heading>       
        </Alert>
    );
}

export default NoneProject;
