import React from "react";
import styles from "./ProjectCountMultilink.module.scss";
import { NavLink } from 'react-router-dom';
import { getNoun } from "../../../../../utils/getNoun";

function ProjectCountMultilink({ count }) {


    return (
        <div className={styles.container}>
            <NavLink className={styles.info} to="/multilink">
                <div className={styles.count} >{count}</div>
                <div>
                    {getNoun(count, "Мультиссылка", "Мультиссылки", "Мультиссылок")}
                </div>
            </NavLink>

            <NavLink className={styles.add} to="/multilink/create"><span> + </span><span className={styles.add_txt} >Создать мультиссылку</span> </NavLink>
        </div>
    );
}

export default ProjectCountMultilink;
