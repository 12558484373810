import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchInvoices,
    checkInvoice,
    getInvoice,
} from "../../../../../../../redux/profile/profile.action";

function BalanceHistory({ user }) {
    const dispatch = useDispatch();
    const invoices = useSelector(({ profile }) => profile.profileInvoices);

    useMemo(() => {
        dispatch(fetchInvoices(user._id));
    }, [user._id]);

    const checkInvoiceHandler = (invoice) => {

        dispatch(getInvoice(user, invoice.link));
    };

    const toData = (date) => {
        return new Date(date).toLocaleDateString();
    };

    const toTime = (date) => {
        return new Date(date).toLocaleTimeString();
    };

    return (
        <div>
            {invoices &&
                invoices.map((invoice) => (
                    <Card key={invoice._id} className="mt-3">
                        <Card.Body>
                            {invoice.status !== "canceled" ? (
                                <Row>
                                    <Col sm={2}>
                                        {toData(invoice.updatedAt)}
                                        <div style={{ fontSize: "10px" }}>
                                            {toTime(invoice.updatedAt)}
                                        </div>
                                    </Col>
                                    <Col sm={2}>
                                        {invoice.amount} {invoice.currency}
                                    </Col>
                                    <Col>
                                        {invoice.pay
                                            ? "Оплачено"
                                            : "Не оплачено"}
                                    </Col>
                                    {!invoice.pay ? (
                                        <Col>
                                            <Button
                                                onClick={() =>
                                                    checkInvoiceHandler(invoice)
                                                }
                                            >
                                                Проверить
                                            </Button>{" "}
                                        </Col>
                                    ) : (
                                        <Col>
                                            *{invoice.paymentMethod.cardnumber}{" "}
                                            <img
                                                src={`/paysystem/${invoice.paymentMethod.cardtype}.svg`}
                                                width="32px"
                                                alt={
                                                    invoice.paymentMethod
                                                        .cardtype
                                                }
                                            />
                                        </Col>
                                    )}
                                    <Col>
                                        <NavLink
                                            to={`/profile/balance/invoice/${invoice.link}`}
                                        >
                                            Подробнее
                                        </NavLink>
                                    </Col>
                                    {!invoice.pay && (
                                        <Col>
                                            <Button
                                                href={`https://yoomoney.ru/checkout/payments/v2/contract?orderId=${invoice.paymentId} `}
                                            >
                                                Оплатить
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            ) : (
                                <Row>
                                    <Col>Платеж отменён</Col>
                                </Row>
                            )}
                        </Card.Body>
                    </Card>
                ))}
        </div>
    );
}

export default BalanceHistory;
