export const TOOLS_EN = {
    tools: {
        title: "Tools",
        QrCreator: {
            title: "QR code creation",
            desc: "Create a QR code for your profile in social networks to increase the number of subscribers and sales",
            btn: "Create QR code",
        },
        CountLetters: {
            title: "Letter count",
            desc: "Count the number of letters in the text to find out how many characters you need to create a post",
            btn: "Count letters",
            text: {
                text: "Specify the text to count",
                letters: "Letters",
                lettersWhitespace: "Letters without spaces",
                words: "Words",
                sentences: "Sentences",
                paragraphs: "Paragraphs",
                submit: "Count",
                warning:"Warning: line breaks, tabs, and spaces at the beginning and end of the line are not taken into account in this tool.",
            }
        },
        Duplicates: {
            title: "Duplicate search",
            desc: "Find duplicates in the text to find out which words are repeated in the text",
            btn: "Find duplicates",
        },
        UTMGenerator: {
            title: "UTM tag generator",
            desc: "Create UTM tags for your links to find out which links work better",
            btn: "Create UTM tags",
        },
        Translit: {
            title: "Transliteration",
            desc: "Transliterate text from Russian to English",
            btn: "Transliterate",
        },

        Transliteration: {
            title: "Transliteration",
            desc: "Transliterate text from Russian to English",
            btn: "Transliterate",
        },
        PasswordGenerator: {
            password: "Password",
            title: "Password generator",
            desc: "Generate a password to protect your account",
            btn: "Generate password",
            length: "Password length",
            generate: "Generate password",
            copy: "Copy password",
            error: "Please select at least one character type to include in the password.",
            settings: {
                uppercase: "Uppercase letters",
                lowercase: "Lowercase letters",
                numbers: "Numbers",
                symbols: "Symbols",
            }
        },
        TextGenerator: {
            title: "Ai Text generator",
            desc: "Generate a text description of the product using a neural network",
            itemDesc: "This text description is generated using a neural network",
            btn: "Generate text",
        }

        
    },
};
