export const DASHBOARD_RU = {
    dashboard: {
        header: {
            list: 'Дашборд',
        },
        no_create_shop: 'Вы можете начать продавать свои товары, создав магазин. Нажмите на кнопку "Создать магазин" ниже.',
        create_shop: 'Создать магазин',
        info_alert: 'Вы можете создать минимагазин и встроить его в свою мультиссылку. А так же у Вас есть возможность использовать минимагазин как отдельный продукт.',
        error_fetch: 'Произошла ошибка при получении данных минимагазинов.',
    },
    shopDashboardItems: {
        edit: 'Редактировать',
        not_attached: 'Не привязан к мультиссылке',
        another_link: 'Другая ссылка',
    },

};