import React from 'react'
// import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userCheck } from '../../../redux/user/user.action';



function Success(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch()


    React.useEffect(() => {
         
        navigate('/', dispatch(userCheck()))

    }, [dispatch, navigate])

    return (
        
        <div>


            <p>Загрузка</p>
            {/* <p>
                Если Главная страница не загрузилась автоматически нажвите эту кнопку
                <NavLink to="/">
                    На главную
                </NavLink>            
            </p>  */}
        </div>
    )
}

export default Success
