// сделать так что при выбори иконки меню, но без её загрузки, в данных iconType: грузилось 'no_icon',

import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";

import { Col, Form, Row } from "react-bootstrap";

function PageVideoMain({ videoBlock, setBlockVideo }) {

    const [typeVideo, setTypeVideo] = useState(null);

    const checkurl = async (url) => {
        // проверка на ютьюб
        setTypeVideo(null)
        // ниже небольшой checked на соответствие добавленной ссылки
        // подходит и youtu.be и youtube.com
        let checkYouTube =
            url
                .replace("http://", "")
                .replace("https://", "")
                .replace("www.", "")
                .replace("youtu.be/", "youtube.com/watch?v=")
                .slice(0, 20) === "youtube.com/watch?v=";

        if (checkYouTube) {
            setTypeVideo("youtube");
            return checkYouTube;
        }
    };

    useEffect(() => {
        if (!videoBlock.main.type) {
            typeHandler();
        }
        
    }, [videoBlock.main.linkVideo]);

    const typeHandler = () => {
        setBlockVideo({
            ...videoBlock,
            main: {
                ...videoBlock.main,
                type: typeVideo,
            },
        });
    };

    const videoHandler = (event) => {
        let newVideo = event.target.value.replace(/ /g, "");
        setBlockVideo({
            ...videoBlock,
            main: {
                ...videoBlock.main,
                linkVideo: checkurl(newVideo) ? newVideo : null,
            },
        });
    };

    const textHandler = (event) => {
        setBlockVideo({
            ...videoBlock,
            main: {
                ...videoBlock.main,
                [event.target.name]: event.target.value,
            },
        });
    };

    return (
        <div>
            <Row>
                <Col sm={7}>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Текст заголовка</Form.Label> */}
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Ссылка на видео"
                            name="linkVideo"
                            defaultValue={
                                videoBlock.main ? videoBlock.main.linkVideo : ""
                            }
                            onChange={videoHandler}
                        />
                        <Form.Text className="text-muted">
                            Укажите ссылку на видео youtube{" "}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Текст заголовка</Form.Label> */}
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Описание"
                            name="description"
                            defaultValue={
                                videoBlock.main
                                    ? videoBlock.main.description
                                    : ""
                            }
                            onChange={textHandler}
                        />
                        {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text> */}
                    </Form.Group>
                </Col>
                <Col sm={5}></Col>
            </Row>
        </div>
    );
}

export default PageVideoMain;
