import React, { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../Tools.module.scss";

function CountLetters() {
    const { t } = useTranslation("tools");

    const [originalText, setOriginalText] = useState(null);
    console.log(originalText);

    const [text, setText] = useState({
        words: 0,
        letters: 0,
        lettersWhitespace: 0,
        sentences: 0,
        paragraphs: 0,
        spaces: 0,
        commas: 0,
        semicolons: 0,
        colons: 0,
        questionMarks: 0,
        exclamationMarks: 0,
        dashes: 0,
    });

    const countHandler = (e) => {
        const text = originalText;
        const words = text
            .replace(/(^\s*)|(\s*$)/gi, "")
            .replace(/[ ]{2,}/gi, " ")
            .replace(/\n /, "\n")
            .split(" ").length;
        const letters = text
            .replace(/(^\s*)|(\s*$)/gi, "")
            .replace(/[ ]{2,}/gi, " ")
            .replace(/[\r\n]+/g, "\n").length;
        const sentences = text.split(/[.?!]+/).length;
        const paragraphs = text.replace(/\n$/gm, "").split(/\n/).length;
        const spaces =
            text
                .replace(/(^\s*)|(\s*$)/gi, "")
                .replace(/[ ]{2,}/gi, " ")
                .split(" ").length - 1;
        const commas = text.split(",").length - 1;
        const semicolons = text.split(";").length - 1;
        const colons = text.split(":").length - 1;
        const questionMarks = text.split("?").length - 1;
        const exclamationMarks = text.split("!").length - 1;
        const dashes = text.split("-").length - 1;
        const lettersWhitespace = letters - spaces;
        setText({
            words,
            letters,
            lettersWhitespace,
            sentences,
            paragraphs,
            spaces,
            commas,
            semicolons,
            colons,
            questionMarks,
            exclamationMarks,
            dashes,
        });
        // setCount(count + 1);
    };

    return (
        <Row>
            <Col sm={8}>
                <Form className={styles.form}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>
                            {t("tools.CountLetters.text.text")}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="14"
                            name="text"
                            onChange={(e) => setOriginalText(e.target.value)}
                        />
                    </Form.Group>
                    <small>{t("tools.CountLetters.text.warning")}</small>
                </Form>
            </Col>
            <Col sm={4}>
                <div className={styles.info}>
                    <Row>
                        <Col sm={7}>
                            {t("tools.CountLetters.text.letters")}:{" "}
                        </Col>
                        <Col sm={5}> {text.letters}</Col>
                    </Row>
                    <Row>
                        <Col sm={7}>
                            {t("tools.CountLetters.text.lettersWhitespace")}:{" "}
                        </Col>
                        <Col sm={5}> {text.lettersWhitespace}</Col>
                    </Row>
                    <Row>
                        <Col sm={7}>{t("tools.CountLetters.text.words")}: </Col>
                        <Col sm={5}> {text.words}</Col>
                    </Row>

                    <Row>
                        <Col sm={7}>
                            {t("tools.CountLetters.text.sentences")}:{" "}
                        </Col>
                        <Col sm={5}> {text.sentences}</Col>
                    </Row>
                    <Row>
                        <Col sm={7}>
                            {t("tools.CountLetters.text.paragraphs")}:{" "}
                        </Col>
                        <Col sm={5}> {text.paragraphs}</Col>
                    </Row>
                </div>{" "}
                <div className={styles.info}>
                    <div className="d-grid gap-2">
                        {originalText ? (
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={countHandler}
                            >
                                {t("tools.CountLetters.text.submit")}
                            </Button>
                        ) : (
                            <Button variant="primary" type="submit" disabled>
                                {t("tools.CountLetters.text.submit")}
                            </Button>
                        )}
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default CountLetters;
