import $api from "../http";

export default class OpenAiService {
    static async create(data) {
        console.log('OpenAiService отправляем данные');
        return $api.post(`/openai/create`, data)
    } 
    static async data(data) {
        return $api.post(`/openai/data`, data)
    }   

}