import React, { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    StoryLandingSlideForm,
    StoryLandingSlideList,
    StoryLandingSlideView,
} from "./StoryLandingSlide";

import { Col, ListGroup, ListGroupItem, Modal, Row } from "react-bootstrap";
import { fetchProjects } from "../../../redux/storyLanding/storyLanding.action";
import styles from "./StoryLandingProjectView.module.scss";
import ViewStorylanding from "./ViewStorylanding/ViewStorylanding";

const StoryLandingProjectView = () => {
    const dispatch = useDispatch();

    const projectId = useParams().id;
    const projects = useSelector(({ storyLanding }) => storyLanding.projects);
    const project = projects.find((project) => project._id === projectId);
    const currentSlide = useSelector(
        ({ storyLanding }) => storyLanding.currentSlide
    );

    useEffect(() => {
        if (projects.length === 0) {
            dispatch(fetchProjects());
        }
    }, [project]);

    const [showModal, setShowModal] = React.useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    if (!project) {
        return <div>Проект не найден</div>;
    }

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} backdrop="true">
                <Modal.Header closeButton>
                    <Modal.Title>Добавить слайд</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StoryLandingSlideForm project={project} />
                </Modal.Body>
            </Modal>

            <Row>
                <Col sm={7}>
                    <h1>Просмотр и настройка проекта: {project.name}</h1>
                    {/* <StoryLandingProjectForm project={project} /> */}
                    {/* <StoryLandingSlideView /> */}
                    {/* <StoryLandingSlideView slideId={project._id} /> */}
                    <div
                        style={{
                            backgroundColor: "#f8f9fa",
                            borderRadius: "8px",
                            padding: "6px",
                        }}
                    >
                        <h2 className={styles.name_block}>1. Выберите слайд</h2>
                        <div
                            style={{
                                backgroundColor: "#e9ecef",
                                padding: "10px",
                                borderRadius: '8px'
                            }}
                        >
                            {" "}
                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                style={{ width: 'relative', height: 150 }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        flexWrap: "no-wrap",

                                        gap: "10px",
                                    }}
                                >
                                    <div
                                        className={styles.slide}
                                        onClick={handleShowModal}
                                    >
                                        {" "}
                                        +{" "}
                                    </div>
                                    <StoryLandingSlideList
                                        styles={styles}
                                    />
                                </div>
                            </Scrollbars>
                        </div>
                        <h2 className={styles.name_block}>2. Добавьте блок</h2>
                        <StoryLandingSlideView slide={currentSlide} />
                    </div>
                </Col>
                <Col sm={5}>
                    <ViewStorylanding project={project} />
                </Col>
            </Row>
        </>
    );
};

export default StoryLandingProjectView;
