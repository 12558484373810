import React, { useState } from "react";
import {
    Button,
    Card,
    Col,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    AiOutlineCopy,
    AiOutlineDelete,
    AiOutlineLineChart,
    AiOutlineSetting,
} from "react-icons/ai";
import styles from "./ProjectList.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

function ProjectList({
    project,
    removeProject,
    copyProject
}) {

    const dispatch = useDispatch();

    const removeHandler = () => {
        dispatch(removeProject(project._id))
        
        setShow(false);
    };
    const copyHandler = () => {
        dispatch(copyProject(project._id))
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Удалить проект?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите удалить Ваш проект мультиссылки{" "}
                    <strong>{project.title || project.name}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => removeHandler()}>
                        Да, удалить!
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Отменить удаление
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card className={styles.card}>
                <Card.Body>
                    <Row>
                        <Col sm={6}>
                            <h2 className={styles.h2}>{project.title || project.name} </h2>
                            <small className={styles.desc}>
                                {project.desc || project.description}
                            </small>
                        </Col>
                        <Col sm={6}>
                            <div className={styles.buttons}>
                                <Button
                                    as={NavLink}
                                    to={{
                                        pathname: project._id,
                                    }}
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    Редактировать
                                </Button>{" "}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            Копировать
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-dark"
                                        size="sm"
                                        onClick={() => copyHandler()}
                                    >
                                        <AiOutlineCopy />
                                    </Button>
                                </OverlayTrigger>{" "}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            Удалить
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleShow()}
                                    >
                                        <AiOutlineDelete />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer className={styles.card__footer}>
                    <div>
                        <AiOutlineLineChart />{" "}
                        <Card.Link
                            as={NavLink}
                            to={{
                                pathname: `/project/stats/${project._id}`,
                            }}
                            style={{ textDecoration: "none" }}
                        >
                            Статистика
                        </Card.Link>
                    </div>
                    <div>
                        <AiOutlineSetting />{" "}
                        <Card.Link
                            as={NavLink}
                            to={{
                                pathname: `/project/setting/${project._id}`,
                            }}
                            style={{ textDecoration: "none" }}
                            project={project}
                        >
                            Настройки
                        </Card.Link>
                    </div>
                </Card.Footer>
            </Card>
        </>
    );
}

export default ProjectList;
