import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_RU } from "./ru/translations";


import { SIDEBAR_EN } from "./en/sidebar";
import { SIDEBAR_RU } from "./ru/sidebar";


import { MULTILINK_EN } from "./en/multilink";
import { MULTILINK_RU } from "./ru/multilink";

import { DASHBOARD_EN } from "./en/dashboard";
import { DASHBOARD_RU } from "./ru/dashboard";

import { TOOLS_EN } from "./en/tools";
import { TOOLS_RU } from "./ru/tools";

import { ONLINE_EN } from "./en/online";
import { ONLINE_RU } from "./ru/online";


import { STORYLANDING_EN } from "./en/storyLanding";
import { STORYLANDING_RU } from "./ru/storyLanding";



i18n.use(LanguageDetector)
    .use(initReactI18next)
	.init({
		resources: {
		  en: {
			translation: TRANSLATIONS_EN,
			sidebar: SIDEBAR_EN,
			multilink: MULTILINK_EN,
			dashboard: DASHBOARD_EN,
			tools: TOOLS_EN,
			online: ONLINE_EN,
			storyLanding: STORYLANDING_EN
			 
		  },
		  ru: {
			translation: TRANSLATIONS_RU,
			sidebar: SIDEBAR_RU,
			multilink: MULTILINK_RU,
			dashboard: DASHBOARD_RU,
			tools: TOOLS_RU,
			online: ONLINE_RU,
			storyLanding: STORYLANDING_RU
		  }
		}
	  });

i18n.changeLanguage("ru");
