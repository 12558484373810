import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { minishopLabelAdd } from "../../../../../../redux/minishop/minishop.action";

import { Button, Card, Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import PageShopAddLabelList from "./PageShopLabelItems/PageShopAddLabelList";
import PageShopAddLabelSetting from "./PageShopLabelSetting/PageShopLabelSetting";

const defaultLabels = {
    index: null,
    label: {
        _id: null,
        labelName: "",
        slug: "",
        labelColor: "#000000",
        labelTextColor: "#ffffff",
        animate: "",
        labelShow: true,
    },
};

function PageShopAddLabel({ shop, userId }) {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        setLabels(shop.labels || []);
    }, [shop]);

    const loading = useSelector(({ minishop }) => minishop.minishopLoading);

    const [editLabel, setEditLabel] = useState(defaultLabels);

    const handleEditLabel = (index, label) => {
        setEditLabel({ index, label });
        setTabsSwitch("addlabelKey");
    };

    const saveEditLabel = () => {
        dispatch(
            minishopLabelAdd({
                label: editLabel.label,
                minishopId: shop._id,
                userId,
            })
        );
        setEditLabel(defaultLabels);
        // handleCloseModal();
        // написать что-то чтобы  было видно сохранение
    };

    const handleCloseModal = () => {
        setEditLabel(defaultLabels);
        setShowModal(false);
    };

    const [tabsSwitch, setTabsSwitch] = useState("addlabelKey");

    const handleShowModal = () => setShowModal(true);

    const isLabelDataComplete =
        editLabel.label.labelName &&
        editLabel.label.labelColor &&
        editLabel.label.labelTextColor;

    const spinner = (
        <Spinner animation="border" role="status" className="mt-2">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавить лэйбл</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={tabsSwitch}
                        id="uncontrolled-tab-example"
                        variant="pills "
                        onSelect={(k) => setTabsSwitch(k)}
                    >
                        <Tab eventKey="addlabelKey" title="Добавить лэйбл">
                            {loading ? (
                                spinner
                            ) : (
                                <PageShopAddLabelSetting
                                    editLabel={editLabel}
                                    setEditLabel={setEditLabel}
                                    labels={labels}
                                    setLabels={setLabels}
                                    minishopId={shop._id}
                                    userId={userId}
                                />
                            )}
                        </Tab>

                        {labels.length > 0 && (
                            <Tab
                                eventKey="labelsKey"
                                title={`Лэйблы: ${labels.length}`}
                            >
                                {loading
                                    ? spinner
                                    : labels.map((label, index) => {
                                          return (
                                              <PageShopAddLabelList
                                                  index={index}
                                                  label={label}
                                                  labels={labels}
                                                  setLabels={setLabels}
                                                  handleEditLabel={
                                                      handleEditLabel
                                                  }
                                                  minishopId={shop._id}
                                                  userId={userId}
                                                  key={`${index}_${label.slug}`}
                                              />
                                          );
                                      })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Отмена
                    </Button>
                    {editLabel.label._id ? (
                        <Button
                            variant="primary"
                            onClick={saveEditLabel}
                            disabled={!isLabelDataComplete}
                        >
                            Изменить
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={saveEditLabel}
                            disabled={!isLabelDataComplete}
                        >
                            Сохранить
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <Card>
                <Card.Body>
                    <Card.Title>
                        Лэйблы: <b>{labels.length}</b>
                    </Card.Title>

                    <Button
                        variant="secondary"
                        type="submit"
                        size="sm"
                        onClick={handleShowModal}
                    >
                        Добавить лэйбл
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
}

PageShopAddLabel.propTypes = {
    shop: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};

export default PageShopAddLabel;

// в этом компоненте мы добавляем лэйблы к товарам в магазине (пример: акция, новинка, хит продаж, скидка)
// технически это просто текстовые метки, которые можно добавить к товарам в магазине
