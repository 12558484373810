import React from "react";
import { Container, Item, Title, } from "./ViewMultilinkFAQ.styled";

function ViewMultilinkFAQ({ faqBlock }) {
    return (
        <Container container={faqBlock}>
            {faqBlock.items.map((item, index) => (
                <Item container={faqBlock} key={`faqBlock_item_${index}`}>
                    <section className="faq-section">
                        <input type="checkbox" id={`q-${index}`} />
                        <label htmlFor={`q-${index}`}>
                            <Title>{item.title}</Title>
                        </label>
                        <p>
                            {item.description}
                        </p>
                    </section>
                </Item>
            ))}
        </Container>
    );
}

export default ViewMultilinkFAQ;
