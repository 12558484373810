import React from "react";

import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "../Header.module.scss";

function HeaderToolsRoutes() {
    const titleAi = useSelector(({ openAi }) =>
        openAi.openaiCurrent ? openAi.openaiCurrent.textRequest : null
    );

    const { t } = useTranslation("tools");

    return (
        <Routes>
            <Route path="/tools/" element={<h2>{t("tools.title")}</h2>} />
            <Route
                path="/tools/qr"
                element={<h2>{t("tools.QrCreator.title")}</h2>}
            />
            <Route
                path="/tools/countletters"
                element={<h2>{t("tools.CountLetters.title")}</h2>}
            />
            <Route
                path="/tools/password_generator"
                element={<h2>{t("tools.PasswordGenerator.title")}</h2>}
            />
            <Route
                path="/tools/utm_generator"
                element={<h2>{t("tools.UTMGenerator.title")}</h2>}
            />
            <Route
                path="/tools/duplicates"
                element={<h2>{t("tools.Duplicates.title")}</h2>}
            />
            <Route
                path="/tools/translit"
                element={<h2>{t("tools.Transliteration.title")}</h2>}
            />
            <Route
                path="/tools/text_generator"
                element={
                    <div className={styles.title}>
                        <h2 className={styles.h1}>
                            {t("tools.TextGenerator.title")}
                        </h2>
                        <p className={styles.desc}>
                            {t("tools.TextGenerator.desc")}
                        </p>
                    </div>
                }
            />
            <Route
                path="/tools/text_generator/:id"
                element={
                    <div className={styles.title}>
                        <h2 className={styles.h1}>{titleAi ? titleAi : ""}</h2>
                        <p className={styles.desc}>
                            {t("tools.TextGenerator.itemDesc")}
                        </p>
                    </div>
                }
            />
        </Routes>
    );
}

export default HeaderToolsRoutes;
