import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
    AiOutlineFileText,
    AiOutlineMail,
    AiOutlinePicture,
    AiOutlinePlusSquare,
    AiOutlineVideoCamera,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useOffcanvas } from "../../../../hook/Context/OffcanvasContext";
import StoryLandingElementForm from "./StoryLandingElement/StoryLandingElementForm";
import styles from "./StoryLandingSlide.module.scss";

const StoryLandingSlideView = ({ slide }) => {
    const element = useSelector(
        ({ storyLanding }) => storyLanding.currentElement
    );

    const currentSlide = useSelector(
        ({ storyLanding }) => storyLanding.currentSlide
    );

    const dispatch = useDispatch();

    const [type, setType] = useState(element ? element.type : "text");
    console.log('tttttttttttttttttttttttt', type);
    const handleCloseOffCanvas = () => {
        closeOffcanvas();
    };

    const handleOpenOffCanvas = (data) => {
        setType(data);
        openOffcanvas();
    };

    const { showOffcanvas, openOffcanvas, closeOffcanvas } = useOffcanvas();

    // useEffect(() => {
    //     if (!showOffcanvas && slide) {
    //         // dispatch(setCurrentSlide(null));
    //         // dispatch(getElement(null));
    //     }
    // }, [showOffcanvas]);

    return (
        <>
            {/* <Offcanvas show={showOffCanvas} onHide={handleCloseOffCanvas} {...props}> */}
            <Offcanvas
                show={showOffcanvas}
                onHide={handleCloseOffCanvas}
                style={{ width: "40%" }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Добавить компонент</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {slide && (
                        <>
                            <StoryLandingElementForm
                                slideId={slide._id}
                                element={element}
                                type={type}
                                setType={setType}
                            />
                        </>
                    )}
                </Offcanvas.Body>
            </Offcanvas>

            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    padding: "4px",
                    margin: "10px 0px",
                }}
            >
                {/* <h2>{slide.name}</h2> */}
                {/* <img src={slide.background} alt="slide background" />*/}
                {currentSlide != null && (
                    <>
                        <div
                            className={styles.addButton}
                            onClick={() => handleOpenOffCanvas('text')}
                        >
                            <div className={styles.icon}>
                                <AiOutlineFileText />
                            </div>
                            <div>Текст</div>
                        </div>
                        <div
                            className={styles.addButton}
                            onClick={() => handleOpenOffCanvas("button")}
                        >
                            <div className={styles.icon}>
                                <AiOutlinePlusSquare />
                            </div>
                            <div>Кнопка</div>
                        </div>
                        <div
                            className={styles.addButton}
                            onClick={() => handleOpenOffCanvas("image")}
                        >
                            <div className={styles.icon}>
                                <AiOutlinePicture />
                            </div>
                            <div>Картинка</div>
                        </div>
                        <div
                            className={styles.addButton}
                            onClick={() => handleOpenOffCanvas("video")}
                        >
                            <div className={styles.icon}>
                                <AiOutlineVideoCamera />
                            </div>
                            <div>Видео</div>
                        </div>
                        <div
                            className={styles.addButton}
                            onClick={() => handleOpenOffCanvas("form")}
                        >
                            <div className={styles.icon}>
                                <AiOutlineMail />
                            </div>
                            <div>Форма</div>
                        </div>{" "}
                    </>
                )}
            </div>
        </>
    );
};

export default StoryLandingSlideView;
