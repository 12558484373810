import React, { useState, useEffect } from "react";
import { PageSocialLink, PageSocialDesign } from "./";

import { useDispatch } from "react-redux";
import { update } from "./../../../../../redux/multilink/multilink.action";

import { Offcanvas,  Tab, Tabs } from "react-bootstrap";

import { usePrevious } from "../../../../../hook/usePrevious";
import { SaveComponent, DesignComponent, SettingComponent, SaveModalComponent } from "../Component";

function PageSocial({
    showCanvas,
    setShowCanvas,
    social,
    projectId,
    user,
}) {
    const dispatch = useDispatch();
    const handleCanvasClose = () => setShowCanvas(false);


    const [data, setData] = useState(social);

    useEffect(() => {
       setData(social)
    }, [social]);


    // модальное окно с подтверждением сохранения
    const [showModal, setShowModal] = useState(false);

    const handleModalCloseNoSave = () => {
        handleCanvasClose();
        setShowModal(false);
        setData(social);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalShow = () => setShowModal(true);
    // модальное окно с подтверждением сохранения

    // появление кнопки сохранить
    const [save, setSave] = useState(false);
    const prevData = usePrevious(social);
    
    useEffect(() => {
        if (JSON.stringify(prevData) !== JSON.stringify(data)) {
            setSave(true);
        } else {
            setSave(false);
        }
    }, [data, prevData, social]);

    const updateHandler = async (close) => {
        const type = "social";
        await dispatch(update(user.id, projectId, type, data));
        if (close) {
            setShowCanvas(false);
            setData(social);
        }
                    

    };



    return (
        <>
        <SaveModalComponent 
            showModal={showModal} 
            handleModalClose={handleModalClose} 
            handleModalCloseNoSave={handleModalCloseNoSave}
        />



            <Offcanvas
                show={showCanvas}
                onHide={save ? handleModalShow : handleCanvasClose}
                style={{ width: "60%" }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Социальные сети</Offcanvas.Title>
                </Offcanvas.Header>
                {data && (
                    <Offcanvas.Body>
                        <Tabs
                            defaultActiveKey="main"
                            transition={false}
                            className="mb-3"
                        >
                            <Tab eventKey="main" title="Основное">
                                <PageSocialLink
                                    socialLinks={data}
                                    setSocialLinks={setData}
                                />
                            </Tab>
                            <Tab eventKey="design" title="Дизайн кнопок">
                                <PageSocialDesign
                                    socialDesign={data}
                                    setSocialDesign={setData}
                                />
                            </Tab>
                            <Tab eventKey="design_block" title="Дизайн блока">
                                <DesignComponent
                                    designComponent={data}
                                    setDesignComponent={setData}
                                />
                            </Tab>
                            <Tab eventKey="setting" title="Настройки">
                                <SettingComponent
                                    settingComponent={data}
                                    setSettingComponent={setData}
                                />
                            </Tab>
                        </Tabs>
                    </Offcanvas.Body>
                )}

                <SaveComponent updateHandler={updateHandler} save={save} />


            </Offcanvas>
        </>
    );
}

export default PageSocial;
