import React, { useState, useEffect, useRef } from "react";

// импорты дизайн
import { HexColorPicker } from "react-colorful";
import {
    Row,
    Col,
    ButtonGroup,
    Button,
    Form,
    OverlayTrigger,
    Popover,
    InputGroup,
} from "react-bootstrap";
import styles from "./DesignComponent.module.scss";

function DesignComponent({ designComponent, setDesignComponent }) {

    const buttonHandler = (event) => {
        setDesignComponent({
            ...designComponent,
            design_block: {
                ...designComponent.design_block,
                [event.target.name]: Number(event.target.value),
            },
        });
    };
    const marginHandler = (event) => {
        setDesignComponent({
            ...designComponent,
            design_block: {
                ...designComponent.design_block,
                background_margin: {
                    ...designComponent.design_block.background_margin,
                    [event.target.name]: Number(event.target.value),
                },
            },
        });
    };
    const paddingHandler = (event) => {
        setDesignComponent({
            ...designComponent,
            design_block: {
                ...designComponent.design_block,
                background_padding: {
                    ...designComponent.design_block.background_padding,
                    [event.target.name]: Number(event.target.value),
                },
            },
        });
    };

    // введён ли правильный hex код
    function isHexColor(hex) {
        let candidatHex = hex.replace("#", "");
        return (
            typeof candidatHex === "string" &&
            candidatHex.length === 6 &&
            !isNaN(Number("0x" + candidatHex))
        );
    }

    const [colorBg, setColorBg] = useState("");
    const [noColorBg, setNoColorBg] = useState("");
    const colorBghandler = (hex) => {
        if (isHexColor(hex)) {
            setColorBg(hex);
            setDesignComponent({
                ...designComponent,
                design_block: {
                    ...designComponent.design_block,
                    background_color: hex,
                },
            });
        }
        setNoColorBg(hex);
    };

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Укажите цвет</Popover.Header>
            <Popover.Body>
                <HexColorPicker color={colorBg} onChange={colorBghandler} />
            </Popover.Body>
            <Popover.Body></Popover.Body>
        </Popover>
    );

    // изменение цвета кнопки
    const [buttonVariant, setButtonVariant] = useState(false);
    const target = useRef(null);

    const handleClick = () => {
        setButtonVariant(!buttonVariant);
    };
//
    useEffect(() => {
        setColorBg(designComponent.design_block.background_color);
        setNoColorBg(designComponent.design_block.background_color);
    }, [designComponent]);


    return (
        <div className={styles.container}>
            <Row className={styles.background_block}>
                <Col sm={8}>Цвет фона</Col>
                <Col sm={4}>
                    <OverlayTrigger
                        target={target.current}
                        trigger="click"
                        placement="bottom"
                        overlay={popover}
                        rootClose
                        onHide={() => handleClick()}
                    >
                        <InputGroup >
                            <Button
                                ref={target}
                                onClick={handleClick}
                                variant={
                                    buttonVariant === true
                                        ? "dark"
                                        : "light"
                                }
                                style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignContent: "center",
                                }}
                            >
                                Выбрать{" "}
                                <div
                                    style={{
                                        backgroundColor: colorBg,
                                        width: "24px",
                                        height: "24px",
                                        border: "1px solid #000000",
                                    }}
                                ></div>
                            </Button>

                            <Form.Control
                                type="text"
                                // value={noColorBg || ''}
                                defaultValue={
                                    designComponent.design_block.background_color ||
                                    "#ffffff"
                                }
                                pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                                placeholder="Укажите цвет"
                                onChange={(e) => colorBghandler(e.target.value)}
                            />
                        </InputGroup >
                    </OverlayTrigger>
                </Col>
            </Row>

            <Row className={styles.row}>
                <Col sm={3}>Добавить фоновое изображение</Col>
                <Col sm={9}>
                    <div className={styles.image_block}>
                        <Form.Control type="file" id="bgImg" />
                    </div>

                    <Row className={styles.image_filter}>
                        <Col sm={4}>Затемнение</Col>
                        <Col sm={5}>
                            <Form.Range
                                size="sm"
                                max="200"
                                defaultValue={designComponent.design_block.brightness || ''}
                                id="brightness"
                                name="brightness"
                                onChange={buttonHandler}
                            />
                        </Col>
                        <Col sm={3}>
                            <InputGroup size="sm">
                                <Form.Control
                                    type="number"
                                    max="200"
                                    defaultValue={designComponent.design_block.brightness || ''}
                                    id="brightness"
                                    name="brightness"
                                    onChange={buttonHandler}
                                />
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className={styles.image_filter}>
                        <Col sm={4}>Размытие</Col>
                        <Col sm={5}>
                            <Form.Range
                                max="10"
                                defaultValue={designComponent.design_block.blur}
                                id="blur"
                                name="blur"
                                onChange={buttonHandler}
                            />
                        </Col>
                        <Col sm={3}>
                            <InputGroup size="sm">
                                <Form.Control
                                    size="sm"
                                    type="number"
                                    max="10"
                                    defaultValue={designComponent.design_block.blur || ''}
                                    id="blur"
                                    name="blur"
                                    onChange={buttonHandler}
                                />
                                <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className={styles.image_filter}>
                        <Col sm={4}>Прозрачность</Col>
                        <Col sm={5}>
                            <Form.Range
                                type="number"
                                max="100"

                                defaultValue={designComponent.design_block.opacity}
                                id="opacity"
                                name="opacity"
                                onChange={buttonHandler}
                            />
                        </Col>
                        <Col sm={3}>
                            <InputGroup size="sm">
                                <Form.Control
                                    type="number"
                                    max="100"
                                    defaultValue={designComponent.design_block.opacity || ''}
                                    id="opacity"
                                    name="opacity"
                                    onChange={buttonHandler}
                                />
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className={styles.row}>
                <Col sm={4}>Внешние отступы</Col>
                <Col sm={8}>
                    <Row>
                        <Col>
                            <Form.Label>Сверху</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_margin.top
                                }
                                id="top"
                                name="top"
                                onChange={marginHandler}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Справа</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_margin.right
                                }
                                id="right"
                                name="right"
                                onChange={marginHandler}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Снизу</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_margin.bottom
                                }
                                id="bottom"
                                name="bottom"
                                onChange={marginHandler}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Слева</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_margin.left
                                }
                                id="left"
                                name="left"
                                onChange={marginHandler}
                                size="sm"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className={styles.row}>
                <Col sm={4}>Внутренние отступы</Col>
                <Col sm={8}>
                    <Row>
                        <Col>
                            <Form.Label>Сверху</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_padding.top
                                }
                                id="top"
                                name="top"
                                onChange={paddingHandler}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Справа</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_padding.right
                                }
                                id="right"
                                name="right"
                                onChange={paddingHandler}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Снизу</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_padding.bottom
                                }
                                id="bottom"
                                name="bottom"
                                onChange={paddingHandler}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Слева</Form.Label>
                            <Form.Control
                                type="number"

                                defaultValue={
                                    designComponent.design_block.background_padding.left
                                }
                                id="left"
                                name="left"
                                onChange={paddingHandler}
                                size="sm"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {designComponent.design_block.color_button === "custom" && (
                <Row className={styles.row}>
                    <Col sm={5}></Col>
                    <Col sm={7}>
                        <div className={styles.user_color}>
                            <div className={styles.colorpicker}>
                                <Form.Label htmlFor="userColorButton">
                                    Цвет кнопок{" "}
                                </Form.Label>
                                <Form.Control
                                    type="color"
                                    id="userColorButton"
                                    defaultValue="#563d7c"
                                    title="Укажите цвет кнопки"
                                />
                            </div>
                            <div className={styles.colorpicker}>
                                <Form.Label htmlFor="userColorText">
                                    Цвет текста{" "}
                                </Form.Label>
                                <Form.Control
                                    type="color"
                                    id="userColorText"
                                    defaultValue="#000"
                                    title="Укажите цвет текста"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default DesignComponent;
