export const DASHBOARD_EN = {
    dashboard: {
        header: {
            list: 'Dashboard',
        },
        no_create_shop: 'You can start selling your products by creating a store. Click the "Create Store" button below.',
        create_shop: 'Create Store',
        info_alert: 'You can create a mini-shop and embed it in your multi-link. You also have the opportunity to use the mini-shop as a separate product.',
        error_fetch: 'An error has occurred while fetching the minishops.',
    },
    shopDashboardItems: {
        edit: 'Edit',
        not_attached: 'Not attached to multi-link',
        another_link: 'Another Link',
    },
};