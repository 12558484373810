import React, { useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
// // import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRoutes } from './routes';
import { useTranslation } from "react-i18next";
import { userCheck } from './redux/user/user.action';

function App() {
    const dispatch = useDispatch()

    const {i18n } = useTranslation();
    let lang = useSelector(
        ({ language }) => language.language
    );

    const loading = useSelector(({ user }) => user.userLoading)
	
    const isAuthenticated = useSelector(({ user }) => user.userAuth)
    const routes = useRoutes(isAuthenticated)

    
    useEffect(() => {
    	// проверка авторизации
    	if (!!localStorage.getItem('token')) {
    		dispatch(userCheck())
    	}

    }, [])

    useEffect(() => {
    		// проверка языка
    		if (typeof lang === 'string') {
    			i18n.changeLanguage(lang)
    		}else {
    			i18n.changeLanguage('ru')
    		}

    }, [i18n, lang])


    return (
        <div className="App">
            {/* <header className="App-header"> */}
            {loading ? (
				// <p>Loading...</p>
                <Router value={{ isAuthenticated }}>{routes}</Router>
            ) : (
                <p>Загрузка</p>
            )}
            {/* </header> */}
        </div>
    );
}

export default App;
