// сделать так что при выбори иконки меню, но без её загрузки, в данных iconType: грузилось 'no_icon',

import React, { useState } from "react";
// import { useForm } from "react-hook-form";

import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
} from "react-bootstrap";
import {
    BsTextCenter,
    BsTextRight,
    BsTextLeft,
    BsEmojiSmile,
    BsTrash,
    BsCardImage,
    BsCircle,
} from "react-icons/bs";
import { AiOutlinePicRight, AiOutlinePicLeft } from "react-icons/ai";

import { EmojiComponent, IconComponent } from "../../Component";

function PageLinkMain({ blockLink, setBlockLink }) {
    // const {
    //     register,
    //     handleSubmit,
    //     watch,
    //     formState: { errors },
    // } = useForm();


    const blockLinkHandler = (event) => {
        setBlockLink({
            ...blockLink,
            main: {
                ...blockLink.main,
                [event.target.name]: event.target.value,
            },
        });
    };


    const contactHandler = (event) => {
        let typesButton = [
            { value: "example", name: "Ссылка", mask: "Ссылка на сайт" },
            {
                value: "phone",
                name: "Телефон",
                mask: "+7 900 100 2030",
            },
            { value: "email", name: "Емайл", mask: "mail@mail.com" },
        ];

        setBlockLink({
            ...blockLink,
            main: {
                ...blockLink.main,
                type: typesButton[event.target.value],
            },
        });
    };

    const onEmojiClick = (emojiObject) => {
        setBlockLink({
            ...blockLink,
            main: {
                ...blockLink.main,
                icon: emojiObject,
            },
        });
    };

    const currentIconHandler = (event) => {
        setBlockLink({
            ...blockLink,
            main: {
                ...blockLink.main,
                [event.target.name]: event.target.value,
                icon: null
            },
        })
        
    };

    const onIconClick = (iconCurrent) => {
        setBlockLink({
            ...blockLink,
            main: {
                ...blockLink.main,
                icon: iconCurrent,
            },
        });
    };

    const [showEmoji, setShowEmoji] = useState(false);
    const handleCloseEmoji = () => setShowEmoji(false);
    const handleShowEmoji = () => setShowEmoji(true);

    const [showIcon, setShowIcon] = useState(false);
    const handleCloseIcon = () => setShowIcon(false);
    const handleShowIcon = () => setShowIcon(true);

    return (
        <div>
            <Row>
                <Col sm={7}>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Текст заголовка</Form.Label> */}
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Заголовок"
                            name="title"
                            defaultValue={blockLink.main.title}
                            onChange={blockLinkHandler}
                        />
                        {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text> */}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Текст заголовка</Form.Label> */}
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Подзаголовок"
                            name="description"
                            defaultValue={blockLink.main.description}
                            onChange={blockLinkHandler}
                        />
                        {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text> */}
                    </Form.Group>
                </Col>
                <Col sm={5}>
                    <ButtonGroup size="sm">
                        <Button
                            variant={
                                blockLink.main.textPosition === "left"
                                    ? "primary"
                                    : "light"
                            }
                            name="textPosition"
                            value="left"
                            onClick={blockLinkHandler}
                        >
                            <BsTextLeft style={{ pointerEvents: "none" }} />
                        </Button>
                        <Button
                            variant={
                                blockLink.main.textPosition === "center"
                                    ? "primary"
                                    : "light"
                            }
                            name="textPosition"
                            value="center"
                            onClick={blockLinkHandler}
                        >
                            <BsTextCenter style={{ pointerEvents: "none" }} />
                        </Button>
                        <Button
                            variant={
                                blockLink.main.textPosition === "right"
                                    ? "primary"
                                    : "light"
                            }
                            name="textPosition"
                            value="right"
                            onClick={blockLinkHandler}
                        >
                            <BsTextRight style={{ pointerEvents: "none" }} />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={7}>
                    <InputGroup className="mb-3" size="sm">
                        <Form.Select
                            style={{ maxWidth: "130px" }}
                            name="type"
                            onChange={contactHandler}
                        >
                            <option value="0">Ссылка</option>
                            <option value="1">Телефон</option>
                            <option value="2">Емайл</option>
                        </Form.Select>
                        <Form.Control
                            type="text"
                            name="inputText"
                            defaultValue={blockLink.main.inputText}
                            placeholder={blockLink.main.type.mask}
                            onChange={blockLinkHandler}
                        />
                    </InputGroup>
                </Col>
                <Col sm={5}></Col>
            </Row>

            <Row>
                <Col sm={7}>
                    <ButtonGroup size="sm" className="mb-3">
                        <Button
                            variant={
                                blockLink.main.iconType === "no_icon"
                                    ? "primary"
                                    : "light"
                            }
                            name="iconType"
                            value="no_icon"
                            onClick={(event) =>
                                currentIconHandler(event)
                                // onEmojiClick(null)
                            }
                        >
                            Нет
                        </Button>
                        <Button
                            variant={
                                blockLink.main.iconType === "emoji"
                                    ? "primary"
                                    : "light"
                            }
                            name="iconType"
                            value="emoji"
                            onClick={(event) =>  
                                currentIconHandler(event)
                                
                            }
                        >
                            Эмоджи
                        </Button>
                        <Button
                            variant={
                                blockLink.main.iconType === "icon"
                                    ? "primary"
                                    : "light"
                            }
                            name="iconType"
                            value="icon"
                            onClick={(event) =>
                                currentIconHandler(event)
                            }
                        >
                            Иконка
                        </Button>
                        <Button
                            variant={
                                blockLink.main.iconType === "img"
                                    ? "primary"
                                    : "light"
                            }
                            name="iconType"
                            value="img"
                            onClick={(event) => 
                                currentIconHandler(event)
                            
                            }
                            
                        >
                            Картинка
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col sm={5}>
                    <ButtonGroup size="sm">
                        <Button
                            variant={
                                blockLink.main.iconPosition === "left"
                                    ? "primary"
                                    : "light"
                            }
                            name="iconPosition"
                            value="left"
                            onClick={blockLinkHandler}
                        >
                            <AiOutlinePicLeft
                                style={{ pointerEvents: "none" }}
                            />
                        </Button>
                        <Button
                            variant={
                                blockLink.main.iconPosition === "right"
                                    ? "primary"
                                    : "light"
                            }
                            name="iconPosition"
                            value="right"
                            onClick={blockLinkHandler}
                        >
                            <AiOutlinePicRight
                                style={{ pointerEvents: "none" }}
                            />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {blockLink.main.iconType === "emoji" && (
                <Row>
                    <Col sm={7}>
                        <InputGroup className="mb-3" size="sm">
                            <InputGroup.Text id="basic-addon1">
                                <BsEmojiSmile />
                            </InputGroup.Text>
                            <FormControl
                                placeholder={
                                    (blockLink.main.icon &&
                                        blockLink.main.icon.emoji) ||
                                    null
                                }
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                disabled
                            />
                            <Button
                                onClick={handleShowEmoji}
                                variant="light"
                            >
                                Изменить
                            </Button>
                            {blockLink.main.icon &&
                                blockLink.main.iconType === "emoji" && (
                                    <Button
                                        onClick={() => onEmojiClick(null)}
                                        variant="dark"
                                    >
                                        <BsTrash />
                                    </Button>
                                )}
                        </InputGroup>
                    </Col>
                    <Col sm={5}></Col>
                </Row>
            )}
            {blockLink.main.iconType === "icon" && (
                <Row>
                    <Col sm={7}>
                        <InputGroup className="mb-3" size="sm">
                            <InputGroup.Text id="basic-addon1">
                                <BsCircle />
                            </InputGroup.Text>
                            <InputGroup.Text
                                id="basic-addon1"
                                style={{ flex: "1 1 auto" }}
                            >
                                <i className={blockLink.main.icon}></i>
                            </InputGroup.Text>

                            <Button
                                onClick={handleShowIcon}
                                variant="light"
                            >
                                Изменить
                            </Button>
                            {blockLink.main.icon &&
                                blockLink.main.iconType === "icon" && (
                                    <Button
                                        onClick={() => onEmojiClick(null)}
                                        variant="dark"
                                    >
                                        <BsTrash />
                                    </Button>
                                )}
                        </InputGroup>
                    </Col>
                    <Col sm={5}></Col>
                </Row>
            )}
            {blockLink.main.iconType === "img" && (
                <Row>
                    <Col sm={7}>
                        <InputGroup className="mb-3" size="sm">
                            <InputGroup.Text id="basic-addon1">
                                <BsCardImage />
                            </InputGroup.Text>
                            <Form.Control type="file" id="thumbnailImg" />
                            {blockLink.main.icon &&
                                blockLink.main.iconType === "img" && (
                                    <Button
                                        onClick={() => onEmojiClick(null)}
                                        variant="dark"
                                    >
                                        <BsTrash />
                                    </Button>
                                )}
                        </InputGroup>
                    </Col>
                    <Col sm={5}></Col>
                </Row>
            )}

            <div>
                <EmojiComponent
                    onEmojiClick={onEmojiClick}
                    show={showEmoji}
                    handleClose={handleCloseEmoji}
                />

                <IconComponent
                    onEmojiClick={onIconClick}
                    show={showIcon}
                    handleClose={handleCloseIcon}
                />
            </div>
        </div>
    );
}

export default PageLinkMain;
