import React from 'react';

const FormElement = ({ content, styles }) => {
  const renderInputs = () => {
    return content.inputs.map((input, index) => (
      <div key={index}>
        <label>{input.label}</label>
        <input type={input.type} name={input.name} />
      </div>
    ));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь добавьте логику отправки формы
  };

  return (
    <form onSubmit={handleSubmit} style={styles}>
      {renderInputs()}
      <button type="submit">{content.submitLabel}</button>
    </form>
  );
};

export default FormElement;
