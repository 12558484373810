const OpenAiActionTypes = {
	OPENAI_CREATE: 'OPENAI_CREATE',
	OPENAI_DATA: 'OPENAI_DATA',
	OPENAI_LOAD: 'OPENAI_LOAD',
	OPENAI_REDIRECT: 'OPENAI_REDIRECT',
	OPENAI_GET: 'OPENAI_GET',
	OPENAI_ERROR: 'OPENAI_ERROR',
	OPENAI_LOADING: 'OPENAI_LOADING',
	OPENAI_CURRENT_TEXT: 'OPENAI_CURRENT_TEXT',
}

export default OpenAiActionTypes;