import React, { useState } from "react";

import styles from "./ViewCopyright.module.scss";
import { PaidModalComponent } from "../../PageMultilink/Component";

function ViewCopyright({ paid, data }) {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    return (
        <>
            <PaidModalComponent
                showModalPaid={showModal}
                handleCloseModalPaid={handleCloseModal}
            />

            {data.content.length > 0 && paid === false && (
                <div className={styles.copyright}>
                    <div
                        className={styles.copyright__block}
                        onClick={handleShowModal}
                    >
                        <small className={styles.copyright__block__text}>Сделано с помощью</small>
                        <small className={styles.copyright__block__deltext}>Убрать копирайт</small>
                        <div className={styles.copyright__block__site}>CLICKED</div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ViewCopyright;
