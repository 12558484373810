import { Formik } from "formik";
import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createProjectOnline } from "../../../../redux/online/online.action";

function OnlineCreate() {
    const { id: userId } = useSelector(({ user }) => user.userAuthInfo);

    const dispatch = useDispatch();

    const initialValues = {
        title: "",
        description: "",
        creator: userId,
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, "Слишком короткое название")
            .max(50, "Слишком длинное название")
            .required("Обязательное поле"),
        description: Yup.string().max(200, "Слишком длинное описание"),
    });

    const createProjectHandler = (values) => {
        dispatch(createProjectOnline(values));
    };

    return (
        <>
            <Row>
                <Col sm={7}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={createProjectHandler}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Заголовок</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            touched.title && !!errors.title
                                        }
                                        placeholder="Укажите название проекта"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title}
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        Придумайте название для on-line записи
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Описание</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            touched.description &&
                                            !!errors.description
                                        }
                                        placeholder="Краткое описание проекта"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.desc}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button
                                    className="mt-3"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Далее
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Col>
                <Col sm={5}>
                    <Alert variant="info">
                        Создавая новый проект вы соглашаетесь с правилами
                        использования сайта
                    </Alert>
                </Col>
            </Row>
        </>
    );
}

export default OnlineCreate;
