import React from "react";
import { Outlet } from "react-router-dom";
import { Main, Header } from "./Components";

function Landing() {
    return (
        <>
            <Header />
            <Outlet />
            <Main />
        </>
    );
}

export default Landing;
