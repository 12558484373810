import React, { createContext, useContext, useState } from 'react';

const OffcanvasContext = createContext();

export const useOffcanvas = () => useContext(OffcanvasContext);

export const OffcanvasProvider = ({ children }) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const openOffcanvas = () => setShowOffcanvas(true);
  const closeOffcanvas = () => setShowOffcanvas(false);

  return (
    <OffcanvasContext.Provider value={{ showOffcanvas, openOffcanvas, closeOffcanvas }}>
      {children}
    </OffcanvasContext.Provider>
  );
};
