import React, { useState } from "react";

import { Form, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import styles from "./PageMessangerDesign.module.scss";

function PageMessangerDesign({ messangerDesign, setMessangerDesign }) {


    const [checked, setChecked] = useState(
        true
        // messangerDesign.design.target === "_blank"
    );
    const checkedHandler = (event) => {
        setMessangerDesign({
            ...messangerDesign,
            design: {
                ...messangerDesign.design,
                [event.target.name]: event.target.checked ? "_blank" : "_self",
            },
        });
        setChecked(event.target.checked);
    };

    const buttonHandler = (event) => {
        setMessangerDesign({
            ...messangerDesign,
            design: {
                ...messangerDesign.design,
                [event.target.name]: event.target.value,
            },
        });
    };

    return (
        
        <>
        {messangerDesign && <div>
        <Row className={styles.row}>
                <Col sm={6}>Внешний вид кнопок</Col>
                <Col sm={6}>
                    <ButtonGroup aria-label="type_button" size="sm" active='logo'>
                        <Button
                            variant={
                                messangerDesign.design.type_button === "logo"
                                    ? "primary"
                                    : "light"
                            }
                            name="type_button"
                            value={"logo"}
                            
                            onClick={buttonHandler}
                        >
                            только лого
                        </Button>
                        <Button
                            variant={
                                messangerDesign.design.type_button === "all"
                                    ? "primary"
                                    : "light"
                            }
                            name="type_button"
                            value="all"
                            onClick={buttonHandler}
                        >
                            Лого и текст
                        </Button>
                        <Button
                            variant={
                                messangerDesign.design.type_button === "text"
                                    ? "primary"
                                    : "light"
                            }
                            name="type_button"
                            value="text"
                            onClick={buttonHandler}
                        >
                            Только текст
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col sm={6}>Стиль кнопок</Col>
                <Col sm={6}>
                    <ButtonGroup aria-label="style_button" size="sm">
                        <Button
                            variant={
                                messangerDesign.design.style_button === "bg"
                                    ? "primary"
                                    : "light"
                            }
                            name="style_button"
                            value="bg"
                            onClick={buttonHandler}
                        >
                            Заполненный
                        </Button>
                        <Button
                            variant={
                                messangerDesign.design.style_button === "outline"
                                    ? "primary"
                                    : "light"
                            }
                            name="style_button"
                            value="outline"
                            onClick={buttonHandler}
                        >
                            Контурный
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col sm={4}>Форма кнопок</Col>
                <Col sm={8}>
                    <ButtonGroup aria-label="shape_button" size="sm">
                        <Button
                            variant={
                                messangerDesign.design.shape_button === "none"
                                    ? "primary"
                                    : "light"
                            }
                            name="shape_button"
                            value="none"
                            onClick={buttonHandler}
                        >
                            Прямоугольная
                        </Button>
                        <Button
                            variant={
                                messangerDesign.design.shape_button === "rounded"
                                    ? "primary"
                                    : "light"
                            }
                            name="shape_button"
                            value="rounded"
                            onClick={buttonHandler}
                        >
                            Закруглённая
                        </Button>
                        <Button
                            variant={
                                messangerDesign.design.shape_button === "circle"
                                    ? "primary"
                                    : "light"
                            }
                            name="shape_button"
                            value="circle"
                            onClick={buttonHandler}
                        >
                            Круглая
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col sm={4}>Цвет кнопок</Col>
                <Col sm={8}>
                    <ButtonGroup aria-label="color_button" size="sm">
                        <Button
                            variant={
                                messangerDesign.design.color_button === "brand"
                                    ? "primary"
                                    : "light"
                            }
                            name="color_button"
                            value="brand"
                            onClick={buttonHandler}
                        >
                            Брендированный
                        </Button>
                        {/* <Button
                            variant={
                                messangerDesign.design.color_button ===
                                "monochrome"
                                    ? "primary"
                                    : "light"
                            }
                            name="color_button"
                            value="monochrome"
                            onClick={buttonHandler}
                        >
                            Одноцветные
                        </Button> */}
                        {/* <Button
                            variant={
                                messangerDesign.design.color_button === "theme"
                                    ? "primary"
                                    : "light"
                            }
                            name="color_button"
                            value="theme"
                            onClick={buttonHandler}
                        >
                            Цвет темы
                        </Button> */}
                        <Button
                            variant={
                                messangerDesign.design.color_button === "custom"
                                    ? "primary"
                                    : "light"
                            }
                            name="color_button"
                            value="custom"
                            onClick={buttonHandler}
                        >
                            Выбрать свой цвет
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {messangerDesign.design.color_button === "custom" && (
                <Row className={styles.row}>
                    <Col sm={5}>
                    
                    </Col>
                    <Col sm={7}>
                        <div className={styles.user_color}>
                            <div className={styles.colorpicker}>
                                <Form.Label htmlFor="userColorButton">
                                    Цвет кнопок{" "}
                                </Form.Label>
                                <Form.Control
                                    type="color"
                                    id="userColorButton"
                                    defaultValue="#563d7c"
                                    title="Choose your color button"
                                />
                            </div>
                            <div className={styles.colorpicker}>
                                <Form.Label htmlFor="userColorText">
                                    Цвет текста{" "}
                                </Form.Label>
                                <Form.Control
                                    type="color"
                                    id="userColorText"
                                    defaultValue="#000000"
                                    title="Choose your color text"
                                />
                            </div>                            
                        </div>

                    </Col>
                </Row>
            )}
            <Row className={styles.row}>
                <Col sm={8}>Открывать ссылки в новой вкладке</Col>
                <Col sm={4}>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            name="target"
                            // label="В новой вкладке"
                            // checked={messangerDesign.design.target === '_blank'}
                            checked={checked}
                            onChange={checkedHandler}
                        />
                    </Form>
                </Col>
            </Row>
        </div>}
        </>
    
    );
}

export default PageMessangerDesign;
