const StoryLandingActionTypes = {
    FETCH_PROJECTS: "FETCH_PROJECTS",
    CREATE_PROJECT: "CREATE_PROJECT",
    GET_PROJECTS: "GET_PROJECTS",
    GET_PROJECT: "GET_PROJECT",
    UPDATE_PROJECT: "UPDATE_PROJECT",
    DELETE_PROJECT: "DELETE_PROJECT",

    CREATE_SLIDE: "CREATE_SLIDE",
    GET_SLIDES: "GET_SLIDES",
    GET_SLIDE: "GET_SLIDE",
    UPDATE_SLIDE: "UPDATE_SLIDE",
    DELETE_SLIDE: "DELETE_SLIDE",
    SET_CURRENT_SLIDE: "SET_CURRENT_SLIDE",


    CREATE_ELEMENT: "CREATE_ELEMENT",
    GET_ELEMENTS: "GET_ELEMENTS",
    GET_ELEMENT: "GET_ELEMENT",
    UPDATE_ELEMENT: "UPDATE_ELEMENT",
    DELETE_ELEMENT: "DELETE_ELEMENT",
};

export default StoryLandingActionTypes


