import React from 'react';
import {createRoot} from 'react-dom/client';
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';

import "./translations/i18n";

export const { store, persistor } = configureStore();


const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);


