import React, { useRef, useMemo, useEffect } from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    PixelRatio,
} from "react-image-crop";

import { useDispatch, useSelector } from "react-redux";
import { editProfile } from "../../../../../redux/profile/profile.action";

import 'react-image-crop/dist/ReactCrop.css'
import styles from "./AvatarLoadCrop.module.scss";
import { Form, Col, Row, Modal, Button } from "react-bootstrap";





function AvatarLoadCrop({ show, setShow }) {

    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user.userInfo);


    const [stateAvatar, setStateAvatar] = React.useState({
        id: '',
        avatar: '',
        avatarType: '',
    });    
    const [crop, setCrop] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [file, setFile] = React.useState({});
    const canvasRef = useRef(null);


    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setFile(e.currentTarget);

        const cropCentr = centerCrop(
            makeAspectCrop(
                {
                    unit: "px",
                    width: 150,
                    height: 150,
                },
                1,
                width,
                height
            ),
            width,
            height
        );

        setCrop(cropCentr);
    }

    const pixelRatio = PixelRatio || 1;


    const loadImage = async (e) => {
        let avatarComplete = await getCroppedImg()
        setStateAvatar({
            ...stateAvatar,
            id: user._id,
            avatar: avatarComplete,
            avatarType: avatarComplete ? "local" : "",
        })
    }

    const getCroppedImg = () => {
        if (!canvasRef.current || !file) {
            return;
        }
        const canvas = canvasRef.current;
        const scaleX = file.naturalWidth / file.width;
        const scaleY = file.naturalHeight / file.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            file,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX, //crop.width
            crop.height * scaleY //crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    // reject(new Error("Canvas is empty"));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = "avatar.jpeg";
                resolve(blob);
            }, "image/jpeg");
        });
    };

    function onSelectFile(event) {
        if (event.target.files && event.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImage(reader.result.toString() || "")
            );
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    
    const handleClose = () => {
        setImage(null)
        setShow(false)
    };


    const handleSave = (event) => {
        event.preventDefault();
        let formData = new FormData();
        for (let dataKey in stateAvatar) {
            formData.append(dataKey, stateAvatar[dataKey]);
        }
        dispatch(editProfile(formData, user._id ));
        handleClose()
    }



    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Фотография профиля</Modal.Title>
            </Modal.Header>
            <div variant="secondary" className={styles.avatar_text__small}>
                Если у вас возникают проблемы с загрузкой, попробуйте выбрать
                фото меньшего размера. К загрузке допустимы форматы JPG, GIF или
                PNG.
            </div>
            <Modal.Body>
                <Row>
                    <Col sm={8}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="file"
                                placeholder="Аватар"
                                name="avatar"
                                accept="image*,.png,.jpg,.jpeg,.gif,.webp"
                                encType="multipart/form-data"
                                onChange={onSelectFile}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        {image ? (
                            <Button
                                variant="primary"
                                onClick={handleSave}
                                style={{ width: "100%" }}
                            >
                                Сохранить
                            </Button>
                        ) : (
                            <Button
                                disabled
                                variant="secondary"
                                style={{ width: "100%" }}
                            >
                                Сохранить
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        {image && (
                            <ReactCrop
                                aspect={1 / 1}
                                crop={crop}
                                onChange={(crop, percentCrop) => setCrop(crop)}
                                onComplete={loadImage}
                                // onComplete={(c) => setCompletedCrop(c)}
                                // onImageLoaded={getCroppedImg}
                            >
                                <img
                                    // onLoad={onImageLoad}
                                    src={image}
                                    alt="avatar"
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}
                    </Col>
                    <Col sm={4}>
                        {image && (
                            <>
                                <canvas
                                    style={{
                                        border: "1px solid black",
                                        objectFit: "contain",
                                        width: 140,
                                        height: 140,
                                    }}
                                    ref={canvasRef}
                                />

                                <div className={styles.avatar_text__small}>
                                    Выбранная область будет показываться на
                                    вашей странице.
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default AvatarLoadCrop;
