import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.container.design_block.background_color};

    font-size: 16px;
    line-height: 20px;
    padding: ${(props) => props.container.design_block.background_padding.top}px
        ${(props) => props.container.design_block.background_padding.right}px
        ${(props) => props.container.design_block.background_padding.bottom}px
        ${(props) => props.container.design_block.background_padding.left}px;
    margin: ${(props) => props.container.design_block.background_margin.top}px
        ${(props) => props.container.design_block.background_margin.right}px
        ${(props) => props.container.design_block.background_margin.bottom}px
        ${(props) => props.container.design_block.background_margin.left}px;

    &:after {
        content: "";
        filter: brightness(
                ${(props) => props.container.design_block.brightness}%
            )
            blur(${(props) => props.container.design_block.blur}px)
            opacity(${(props) => props.container.design_block.opacity}%);
    }
`;

export const Item = styled.div`
    padding: 4px;
    ${(props) => props.container.design.border === true && 
    'border-bottom: 1px solid' + props.container.design.color_border + ';'}
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    align-items: center;
    color: ${(props) => props.container.design.color};
    
`;

export const Text = styled.div`
    max-width: 64%;
    flex: 1 1 0;
`;

export const PriceBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap:3px;
    max-width: 34%;
    flex: 1 1 0;
    float: right;
    font-weight: 700;
    
`;

export const Price = styled.div`
    display: flex;
    justify-content: flex-end;
    gap:3px;
    
`;
