const UserActionTypes = {
	USER_AUTH: 'USER_AUTH',
	USER_AUTH_INFO: 'USER_AUTH_INFO',
	USER_CHECK: 'USER_CHECK',
	USER_INFO: 'USER_INFO',
	USER_LOGOUT: 'USER_LOGOUT',
	USER_GET: 'GET/USER_GET',
	USER_ALL: 'USER_ALL',
	USER_LOADING: 'USER_LOADING',
	ADD_USER: 'ADD_USER',
	FETCH_USER: 'FETCH_USER',
	CHECK_USER: 'CHECK_USER',

	REMOVE_USER: 'REMOVE_USER',
	INCREASE_USER: 'INCREASE_USER',
	DECREASE_USER: 'DECREASE_USER',
}

export default UserActionTypes;