import React from "react";

import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { AddColorComponent } from "../../../../../Components";
import styles from "./PageLinkDesign.module.scss";

function PageLinkDesign({ blockLink, setBlockLink }) {
    const blockLinkHandler = (event) => {
        setBlockLink({
            ...blockLink,
            design_link: {
                ...blockLink.design_link,
                [event.target.name]: event.target.value,
            },
        });
    };

    const colorTextHandler = (hex) => {
        setBlockLink({
            ...blockLink,
            design_link: {
                ...blockLink.design_link,
                color: hex,
            },
        });
    };
    const colorBgHandler = (hex) => {
        setBlockLink({
            ...blockLink,
            design_link: {
                ...blockLink.design_link,
                btn_color: hex,
            },
        });
    };

    return (
        <>
            <div className={styles.container}>
                <Row className={styles.background_block}>
                    <Col sm={8}>Цвет кнопки</Col>
                    <Col>
                        <AddColorComponent
                            color={blockLink.design_link.btn_color}
                            colorHandler={colorBgHandler}
                        />
                    </Col>
                </Row>
                <Row className={`${styles.background_block} ${styles.text}`}>
                    <Col sm={8}>Цвет текста кнопки</Col>
                    <Col>
                        <AddColorComponent
                            color={blockLink.design_link.color}
                            colorHandler={colorTextHandler}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col sm={5}>Форма кнопки</Col>
                    <Col sm={7}>
                        <ButtonGroup aria-label="shape_button" size="sm">
                            <Button
                                variant={
                                    blockLink.design_link.shape_button ===
                                    "none"
                                        ? "primary"
                                        : "light"
                                }
                                name="shape_button"
                                value="none"
                                onClick={blockLinkHandler}
                            >
                                Прямоугольная
                            </Button>
                            <Button
                                variant={
                                    blockLink.design_link.shape_button ===
                                    "rounded"
                                        ? "primary"
                                        : "light"
                                }
                                name="shape_button"
                                value="rounded"
                                onClick={blockLinkHandler}
                            >
                                Закруглённая
                            </Button>
                            <Button
                                variant={
                                    blockLink.design_link.shape_button ===
                                    "circle"
                                        ? "primary"
                                        : "light"
                                }
                                name="shape_button"
                                value="circle"
                                onClick={blockLinkHandler}
                            >
                                Круглая
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default PageLinkDesign;
