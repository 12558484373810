import React, { useEffect, useRef, useState } from "react";
import { InputGroup, OverlayTrigger, Button, Form, Popover } from 'react-bootstrap';
import { HexColorPicker } from 'react-colorful';

function AddColorComponent({color, colorHandler}) {

        // введён ли правильный hex код
        function isHexColor(hex) {
            let candidatHex = hex.replace("#", "");
            return (
                typeof candidatHex === "string" &&
                candidatHex.length === 6 &&
                !isNaN(Number("0x" + candidatHex))
            );
        }

        const [colorBg, setColorBg] = useState("");
        const [noColorBg, setNoColorBg] = useState("");
        const colorBghandler = (hex) => {
            if (isHexColor(hex)) {
                setColorBg(hex);

                colorHandler(hex)
            }
            setNoColorBg(hex);
        };


        const popover = (
            <Popover>
                <Popover.Header as="h3">Укажите цвет</Popover.Header>
                <Popover.Body>
                    <HexColorPicker color={colorBg} onChange={colorBghandler} />
                </Popover.Body>
            </Popover>
        );

    //     // изменение цвета кнопки
        const [buttonVariant, setButtonVariant] = useState(false);
        const target = useRef(null);

        const handleClick = () => {
            setButtonVariant(!buttonVariant);
        };
    // //
        useEffect(() => {
            setColorBg(color);
            setNoColorBg(color);
        }, [color]);

    return (
        <OverlayTrigger
            target={target.current}
            trigger="click"
            placement="auto-start"
            overlay={popover}
            rootClose
            onHide={() => handleClick()}
        >
            <InputGroup size='sm' style={{ width: 'auto'}}>
                <Button
                    ref={target}
                    onClick={handleClick}
                    variant={buttonVariant === true ? "dark" : "light"}
                    style={{
                        display: "flex",
                        gap: "5px",
                        alignContent: "center",
                        border: "1px solid #d4d9de"
                    }}
                >
                    {" "}
                    <div
                        style={{
                            backgroundColor: colorBg,
                            width: "24px",
                            height: "24px",
                            border: "1px solid #000000",
                        }}
                    ></div>
                </Button>

                <Form.Control
                    type="text"
                    name="color"
                    defaultValue={'' || noColorBg }
                    
                    pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                    placeholder="Укажите цвет"
                    onChange={(e) => colorBghandler(e.target.value)}
                />
                <Button style={{border: "1px solid #d4d9de"}} variant='light' onClick={() => colorHandler('')}>x</Button>
            </InputGroup>

        </OverlayTrigger>
    );
}

export default AddColorComponent;
