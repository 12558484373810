import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.container.design_block.background_color};

    font-size: 16px;
    line-height: 20px;
    padding: ${(props) => props.container.design_block.background_padding.top}px
        ${(props) => props.container.design_block.background_padding.right}px
        ${(props) => props.container.design_block.background_padding.bottom}px
        ${(props) => props.container.design_block.background_padding.left}px;
    margin: ${(props) => props.container.design_block.background_margin.top}px
        ${(props) => props.container.design_block.background_margin.right}px
        ${(props) => props.container.design_block.background_margin.bottom}px
        ${(props) => props.container.design_block.background_margin.left}px;

    &:after {
        content: "";
        filter: brightness(
                ${(props) => props.container.design_block.brightness}%
            )
            blur(${(props) => props.container.design_block.blur}px)
            opacity(${(props) => props.container.design_block.opacity}%);
    }
`;

export const Item = styled.div`
    margin-bottom: 10px;
    padding: 4px 4px 0px 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    color: ${(props) => props.container.design.color};

        .faq-section {
                width: 100%;
                }
        .faq-section input,
        .faq-section p{
                display: none;
                font-size: 13px!important;
                }


        .faq-section label p{
                display: block;
                color: #999;
                font-size: .85em;
                transition: all .15s ease-out;
                /* Clipping text */
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
        }
        
        .faq-section input[type=checkbox]:checked~p{
                display: block;
                color: #444;
                font-size: 1em;
                text-overflow: clip;
                white-space: normal;
                overflow: visible;
        }
        
        .faq-section label{
                font-size: 16px;
                cursor: pointer;
                background: rgb(255, 255, 255);
                border: #444;
                display: block;
                position: relative;
                padding: 7px 10px;
                font-weight: bold;
                /* border: 1px solid #ddd; */
                -webkit-box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.09);
                -moz-box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.09);
                box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.09);
                text-shadow: 0 1px 0 rgba(255,255,255,.5);
                transition: all .15s ease-out;
        }
        
        .faq-section label::selection{
                background: none;
        }
        
        .faq-section label:hover{
                background: #d8d8d8;
        }
        
        .faq-section input[type=checkbox]:checked~label{
                border-color: #9a9a9a;
                background: #e7e7e7;
                box-shadow: 0 0 1px rgba(0,0,0,.4);
        }
        .faq-section input[type=checkbox]:checked~label::before{
                transform: rotate(90deg);
        }
        
        .faq-section label::before{
                content: '';
                position: absolute;
                right: 4px;
                top: 50%;
                margin-top: -6px;
                border: 6px solid transparent;
                border-left-color: inherit;
                
        }



    }
`;

export const Title = styled.div`
    max-width: 96%;
`;

