import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function Auth() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [joinState, setjoinState] = useState({
        username: "",
        password: "",
    });

    const handleChange = (event) => {
        setjoinState({
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // userPostFetch(joinState)
    };

    return (

        <>
        <Form onSubmit={handleSubmit}>
        <div>
            <h2>{t("landing.auth.popup.login")}</h2>
            <Form.Text >{t("landing.auth.popup.login_signup")} {t("landing.auth.popup.login_signup_link")}</Form.Text>
        </div>

        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t("landing.auth.popup.email")}</Form.Label>
            <Form.Control 
            type="email" 
            placeholder="Enter email" 
            name="email"
            value={joinState.email}
            onChange={handleChange}
            />
            {/* <Form.Text className="text-muted">
            {t("landing.auth.popup.email")}
                
            </Form.Text> */}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{t("landing.auth.popup.password")}</Form.Label>
            <Form.Control 
            type="password"                     
            name="password"
            placeholder="Password"
            value={joinState.password}
            onChange={handleChange} />
        </Form.Group>

        <Button variant="primary" type="submit">
            {t("landing.auth.popup.login_btn")}
        </Button>
    </Form>
    <Alert variant='secondary'>
        Надёжный пароль

        Мы не банковский сервис, но у нас вам тоже нужнен сильный пароль, чтобы избежать взлома и рассылки спама, например.

        Чтобы придумать надёжный пароль, соблюдите несколько рекомендаций:

        Используйте заглавные и строчные буквы;
        Минимум один символ;
        Как минимум одну цифру. 
    </Alert>

        </>

    );
}

export default Auth;
