import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/user/user.action";
import { NavLink, useNavigate } from "react-router-dom";

import { Col, Dropdown, Row } from "react-bootstrap";
import styles from "./Header.module.scss";

import HeaderLanguage from "./HeaderLanguage/HeaderLanguage";
import {
    HeaderProfileRoutes,
    HeaderDashboardRoutes,
    HeaderMultilinkRoutes,
    HeaderToolsRoutes
} from "./HeaderRoutes";
import { BsArrowLeftShort, BsDot, BsFillPlusCircleFill } from "react-icons/bs";

function Header({ user }) {
    // let navigate = useNavigate();
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const urlLocation = window.location.pathname;
    return (
        <div className={styles.header}>
            
            <div
                onClick={() => navigate(-1)}
                className={`${styles.back__btn} ${
                    urlLocation === "/" ? "" : styles.active
                }`}
            >
                <BsArrowLeftShort />
            </div>
            <div className={`${styles.mainpage} ${
                    urlLocation === "/" && styles.active
                }`}><BsDot /></div>
                <div  className={`${styles.container} `}
                    >
                    <HeaderMultilinkRoutes />
                    <HeaderDashboardRoutes />
                    <HeaderProfileRoutes />
                    <HeaderToolsRoutes />
                </div>
            <div>
                <Row className={styles.test}>
                    <Col sm={9}>
                        <div className={styles.dropdown_container}>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="Light"
                                    id="dropdown-basic"
                                    style={{ padding: "0px" }}
                                >
                                    <img
                                        src={`${
                                            user.avatarType === "local"
                                                ? "/" + user.avatar
                                                : user.avatar
                                        }`}
                                        alt={user.login}
                                        width="35px"
                                        style={{ padding: "0px" }}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        as={NavLink}
                                        to="/profile/balance"
                                    >
                                        <div
                                            className={styles.dropdown_balance}
                                        >
                                            <div className="d-flex justify-content-between align-items-center" style={{lineHeight: '1px', padding: '8px 0px '}}>
                                                <div>
                                                    Баланс: {user.balance} ₽
                                                </div>
                                                <div> <BsFillPlusCircleFill/> </div>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to="/profile">
                                        Перейти в профиль
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => dispatch(logout())}
                                    >
                                        Выйти
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col sm={3} className={styles.language}>
                        <HeaderLanguage />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Header;
