import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'
import CreateShop from './CreateShop/CreateShop';
import AllShop from './AllShop/AllShop';






function Shop() {


  const user = useSelector(({ user }) => user.userAuthInfo);
  const minishops = useSelector(({ minishop }) => minishop.minishopsFetch);



  return (
    <>
    {minishops ? <AllShop /> :<CreateShop /> }
     {/* <Outlet />     */}
    </>

  )
  
}


export default Shop