import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    Col,
    FormControl,
    InputGroup,
    Row,
} from "react-bootstrap";
import { BsEmojiSmile, BsTrash } from "react-icons/bs";
import { AddColorComponent } from "../../../../../Components";
import { EmojiComponent } from "../../Component";
import styles from "./PageFAQDesign.module.scss";

function PageFAQDesign({ faqBlock, setListBlock }) {
    const faqBlockHandler = (event) => {
        setListBlock({
            ...faqBlock,
            design: {
                ...faqBlock.design,
                [event.target.name]: event.target.value,
                list_custum: "",
            },
        });
    };
    const colorTextHandler = (hex) => {
        setListBlock({
            ...faqBlock,
            design: {
                ...faqBlock.design,
                color: hex,
            },
        });
    };

    const onEmojiClick = (emojiObject) => {
        setListBlock({
            ...faqBlock,
            design: {
                ...faqBlock.design,
                list_custum: emojiObject.emoji,
            },
        });
    };


    const [showEmoji, setShowEmoji] = useState(false);
    const handleCloseEmoji = () => setShowEmoji(false);
    const handleShowEmoji = () => setShowEmoji(true);


    return (
        <>
            <EmojiComponent
                onEmojiClick={onEmojiClick}
                show={showEmoji}
                handleClose={handleCloseEmoji}
            />

            <div className={styles.block}>
                <Row className={`${styles.background_block} ${styles.text}`}>
                    <Col sm={8}>Цвет текста</Col>
                    <Col>
                        <AddColorComponent
                            color={
                                faqBlock.design
                                    ? faqBlock.design.color
                                    : "#000000"
                            }
                            colorHandler={colorTextHandler}
                        />
                    </Col>
                </Row>

            </div>

            <div className={styles.item}>
                <Row className={styles.marker}>
                    <Col sm={2}>Маркер:</Col>
                    <Col sm={10}>
                        <ButtonGroup aria-label="list_type" size="sm">
                            <Button
                                variant={
                                    faqBlock.design.list_type === "none"
                                        ? "primary"
                                        : "light"
                                }
                                name="list_type"
                                value="none"
                                onClick={faqBlockHandler}
                            >
                                Нет
                            </Button>
                            <Button
                                variant={
                                    faqBlock.design.list_type === "disc"
                                        ? "primary"
                                        : "light"
                                }
                                name="list_type"
                                value="disc"
                                onClick={faqBlockHandler}
                            >
                                Кружки
                            </Button>
                            <Button
                                variant={
                                    faqBlock.design.list_type === "decimal"
                                        ? "primary"
                                        : "light"
                                }
                                name="list_type"
                                value="decimal"
                                onClick={faqBlockHandler}
                            >
                                Нумерация
                            </Button>
                            <Button
                                variant={
                                    faqBlock.design.list_type === "upper-alpha"
                                        ? "primary"
                                        : "light"
                                }
                                name="list_type"
                                value="upper-alpha"
                                onClick={faqBlockHandler}
                            >
                                Латинские цифры
                            </Button>
                            <Button
                                variant={
                                    faqBlock.design.list_type === "upper-roman"
                                        ? "primary"
                                        : "light"
                                }
                                name="list_type"
                                value="upper-roman"
                                onClick={faqBlockHandler}
                            >
                                Римские цифры
                            </Button>
                            <Button
                                variant={
                                    faqBlock.design.list_type === "emoji"
                                        ? "primary"
                                        : "light"
                                }
                                name="list_type"
                                value="emoji"
                                onClick={faqBlockHandler}
                            >
                                Эмоджи
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {faqBlock.design.list_type === "emoji" && (
                    <Row className={`${styles.marker} ${styles.emoji}`}>
                        <Col sm={6}>
                            <small>
                                Вы можете выбрать эмоджи в качестве маркера:
                            </small>
                        </Col>
                        <Col sm={6}>
                            <InputGroup className="mt-2" size="sm">
                                <InputGroup.Text id="basic-addon1">
                                    <BsEmojiSmile />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder={
                                        faqBlock.design.list_custum || null
                                    }
                                    aria-label="emoji"
                                    aria-describedby="basic-addon1"
                                    disabled
                                />
                                <Button
                                    onClick={handleShowEmoji}
                                    variant="light"
                                >
                                    Изменить
                                </Button>
                                {faqBlock.design.list_type === "emoji" && (
                                    <Button
                                        onClick={() => onEmojiClick(null)}
                                        variant="dark"
                                    >
                                        <BsTrash />
                                    </Button>
                                )}
                            </InputGroup>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    );
}

export default PageFAQDesign;
