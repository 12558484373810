import React from "react";

import { Container, Header } from './ViewMultilinkTitle.styled'


function ViewMultilinkTitle({ title }) {

    const header =  React.createElement(
        title.main.header,
        [],
        title.main.text
      )
    return( 
        <>
            <Container
                container={title} 
            >
                {title.setting.mode && 
                
                    <Header container={title}>
                        {header}
                    </Header>
                }
            </Container>
            
        </>
    )
}

export default ViewMultilinkTitle;
