import React, { useEffect, useState } from "react";
import styles from "./PageShop.module.scss";
import {
    Button,
    Card,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
} from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import PageShopInfo from "./PageShopInfo/PageShopInfo";
import { useDispatch, useSelector } from "react-redux";
import { getMiniShop } from "./../../../../redux/minishop/minishop.action";
import { useParams } from "react-router-dom";

function PageShop() {
    const dispatch = useDispatch();
    const { id: userId } = useSelector(({ user }) => user.userAuthInfo);
    const loadshop = useSelector(({ minishop }) => minishop.minishopItems);


    const [loading, setLoading] = useState(true);
    const [shop, setShop] = useState(null);
    const [independent, setIndependent] = useState(false);


    const {id: minishopId} = useParams();
    useEffect(() => {
        dispatch(getMiniShop(minishopId, userId));
    }, [dispatch, minishopId, userId]);


    useEffect(() => {
        setShop(loadshop);
        setLoading(false);
    }, [loadshop]);



    return (
        <>
            {loading ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <Row>
                    <Col sm={7}>
                        <div className={styles.block}>
                            <Form>
                                <Form.Group>
                                    {/* <Form.Label>Название магазина</Form.Label> */}
                                    <Form.Control
                                        type="text"
                                        placeholder="Введите название магазина"
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <PageShopInfo shop={shop} userId={userId} />

                        <div className={styles.items_container}>
                            <div className={styles.header}>
                                <div>
                                    <Button variant="primary" type="submit">
                                        Добавить товар
                                    </Button>
                                </div>
                                <div>
                                    Вы создали 0 из 20 товаров Бесплатного
                                    тарифа <BsArrowLeftShort />
                                </div>
                            </div>
                            <div className={styles.items}>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col sm="7">
                                                <Card.Title>
                                                    Тестовый товар 1
                                                </Card.Title>
                                                <small className={styles.desc}>
                                                    Без категории
                                                </small>
                                                {/* <Card.Text>
                                    Some quick example text to build on the card
                                    title and make up the bulk of the card's
                                    content.
                                </Card.Text>                                     */}
                                            </Col>
                                            <Col sm="5">
                                                <div className={styles.buttons}>
                                                    <Button
                                                        variant="outline-primary"
                                                        type="submit"
                                                        size="sm"
                                                    >
                                                        Редактировать
                                                    </Button>{" "}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                Копировать
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            variant="outline-dark"
                                                            size="sm"
                                                            // onClick={() => copyHandler()}
                                                        >
                                                            <AiOutlineCopy />
                                                        </Button>
                                                    </OverlayTrigger>{" "}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                Удалить
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            variant="outline-danger"
                                                            size="sm"
                                                            // onClick={() => handleShow()}
                                                        >
                                                            <AiOutlineDelete />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className={styles.addMultilink}>
                            Вы можете привязать ваш магазин к мультиссылке
                            <Button variant="outline-primary" type="submit">
                                Привязать магазин к мультиссылке
                            </Button>
                            <div className={styles.info}>
                                <small>
                                    В тарифе <b>PRO.SHOP</b> магазин можно
                                    подключать одновременно к нескольким
                                    мультиссылкам
                                </small>
                            </div>
                        </div>
                        <div className={styles.independent}>
                            А ещё магазин может работать как отдельный сайт
                            <Form.Check
                                variant="secondary"
                                type="switch"
                                id="custom-switch"
                                label="Включить "
                                onChange={() => setIndependent(!independent)}
                            />
                            {independent && (
                                <div className={styles.info}>
                                    <Form>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>
                                                Адрес магазина
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Введите название магазина"
                                                value="https://clickmarket.top/shop/123456"
                                            />
                                        </Form.Group>
                                    </Form>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default PageShop;
