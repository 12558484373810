import React, { useState } from "react";


import styles from './PageSocialLink.module.scss'
import { Button, Form, Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import AddSocialLink from './AddSocialLink/AddSocialLink';



function PageSocialLink({socialLinks, setSocialLinks }) {


    const socialHandler = (event, item) => {
        setSocialLinks({...socialLinks, items: socialLinks.items.map(c => {
			if (c.index === item.index) {
				return {...c, [event.target.name]: event.target.value}
			}
			return c
		}) })
	};

    
	// { ...prevState, list: prevState.list = [...prevState.list, text] };
	// result => [...result, response]

    // const changeHandler = (event) => {
    //     setMainInfo({ ...mainInfo, [event.target.name]: event.target.value });
    // };




	// const [items, setItems] = useState(social)

	//// включаем возможность сортировки, работает только при нажиме на элемент. Для того чтобы при выделении инпута не включалось перетаскивание
	const [draggbleState, setDraggbleState] = useState(true)

	const [currentItemDraggble, setCurrentItemDraggble] = useState(null)

	const sortHandler = (item) => {
		setSocialLinks({...socialLinks, items: socialLinks.items.map(c => {
				if (c.index === item.index) {
					return {...c, order: Number(currentItemDraggble.order)}
				}
				if (c.index === currentItemDraggble.index) {
					return {...c, order: Number(item.order)}
				}
				return c
			})
		})
		
	
	}
	


	const onDragStartHandler = (event, item) => {
		event.currentTarget.classList.add(styles.start)
		setCurrentItemDraggble(item)
	}

	const onDragLeaveHandler = (event) => {
		event.preventDefault()
		event.currentTarget.classList.remove(styles.active)
	}
	const onDragEndHandler = (event) => {
		event.preventDefault()
		// event.target.style.background = '#fff'
		event.currentTarget.classList.remove(styles.active)
	}

	const onDragOverHandler = (event) => {
		event.preventDefault()
		event.currentTarget.classList.add(styles.active) 
	}

	const onDropHandler = (event, item) => {
		event.preventDefault()
		sortHandler(item)

		event.currentTarget.classList.remove(styles.active)
	}

	// сортировка после изменения расположения полей
	const sortItems = ( a,b ) => {
		if(a.order > b.order) {
			return 1
		} else {
			return -1
		}
	}


	/// модульное окно для удаления элемента
	const [modal, setModal] = useState(false);
	const [currentItem, setCurrentItem] = useState(null)

	const modalCloseHandler = () => setModal(false);
    const modalShowHandler = (item) => {
		setModal(true)
		setCurrentItem(item)
	}

	const itemDelHandler = () => {
		// нужно менять index и order по индексу текущего itema
		// каждый раз должна происходить перезапись всех объектов в массиве
		setSocialLinks({
			...socialLinks, items: socialLinks.items
			// удаляем с помощью filter объект 
			.filter((c) => c.order !== currentItem.order)
			// для draggable переписываем данные order и index в объекте
			.map((c, idx) => {
				return { ...c, order: idx, index: idx };
			}),
		})
		modalCloseHandler()
	}

	// setSocialLinks({
	// 	...socialLinks, 
	// 	items:[...socialLinks.items ,socialLinks.items.filter(
	// 		c => c._id !== currentItem._id)
	// 	]
	// })

	// setSocialLinks({
	// 	...socialLinks,
	// 	items: [...socialLinks.items, addItem],
	// });


	const itemCopyHandler = (item) => {
        
        let idItem = socialLinks.items.length;
        let addItem = {
            ...item,
            index: idItem + 1,
            order: idItem + 1,
        };

        setSocialLinks({
            ...socialLinks,
            items: [...socialLinks.items, addItem]
        });
    };


    return (
        <>
        
		<Modal show={modal} onHide={modalCloseHandler}>
            <Modal.Header closeButton>
            <Modal.Title>Удалить?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы действительно хотите удалить соц.сеть ?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={modalCloseHandler}>
                Нет
            </Button>
            <Button variant="primary" onClick={itemDelHandler}>
                Да, удалить!
            </Button>
            </Modal.Footer>
        </Modal>


		<AddSocialLink 
			socialLinks={socialLinks} 
			setSocialLinks={setSocialLinks}
		/>
		
		<Form className='parent'>
			{socialLinks.items 
				 &&  
				 socialLinks.items.sort(sortItems).map(
						(item, index) =>

						<div
							className={styles.drop}
							draggable={draggbleState}
							axis={'y'}
							containment={'parent'} 
							onDragStart={(event) => onDragStartHandler(event, item)}
							onDragLeave={(event) => onDragLeaveHandler(event)}
							onDragEnd={(event) => onDragEndHandler(event)}
							onDragOver={(event) => onDragOverHandler(event)}
							onDrop={(event) => onDropHandler(event, item)}

							key={`${index}_social_${item.name}`}

						> 

						
							<Row className={styles.input}>
								<Col sm={1}>
									<img src={`/social/brand/${item.name}.png`} width='27' alt={`${item.title}`} />
								</Col>
								<Col sm={5}>
									<Form.Control 
									 	size="sm"
										onMouseDown={() => setDraggbleState(false)}
										onMouseUp={() => setDraggbleState(true)}
										onChange={(event) => socialHandler(event, item)}
										id={item.index}
										name='site'
										defaultValue={item.site} 
										value={item.site}
									/>
								</Col>
								<Col sm={4}>
									<Form.Control 
										size="sm"
										className={styles.input}
										onMouseDown={() => setDraggbleState(false)}
										onMouseUp={() => setDraggbleState(true)}
										onChange={(event) => socialHandler(event, item)}
										id={item.index}
										name='title'
										defaultValue={item.title}
										value={item.title}
									/>
								</Col>
								<Col sm={2}>
								<OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Копировать
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="dark" 
                                            size="sm"
                                            onClick={() =>
                                                itemCopyHandler(item)
                                            }
                                        >
                                            <AiOutlineCopy />
                                        </Button>
                                    </OverlayTrigger>{" "}
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="tooltip-top">Удалить</Tooltip>}
									>
										<Button 
											variant="danger" 
											size="sm"  
											onClick={() => modalShowHandler(item)}
										>
											<AiOutlineDelete />
										</Button>
									</OverlayTrigger>
								</Col>
							</Row>

						</div>
					)
			
			}
		</Form>

			
        </>
    );
}

export default PageSocialLink;
