import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMultilinks } from "../../../../redux/multilink/multilink.action";

import styles from "./AllMultilink.module.scss";
import { Alert, Col, Row } from "react-bootstrap";
import MultilinkItems from "./MultilinkItems/MultilinkItems";
import NoneMultilink from "./../NoneMultilink/NoneMultilink";

function AllMultilink() {
    const dispatch = useDispatch();

    const user = useSelector(({ user }) => user.userAuthInfo);
    const multilinksFetch = useSelector(
        ({ multilink }) => multilink.multilinksFetch
    );

    useEffect(() => {
        dispatch(fetchMultilinks(user));
    }, []);

    return (
        <Row>
            <Col sm={7}>
                <div className={styles.multilinksFetch}>
                    {multilinksFetch.length > 0 ? (
                        multilinksFetch.map((multilink, index) => (
                            <MultilinkItems
                                multilink={multilink}
                                key={`${index}_MultilinkItems`}
                            />
                        ))
                    ) : (
                        <NoneMultilink />
                    )}
                </div>
            </Col>
            <Col sm={5}>
                {multilinksFetch.length > 0 && (
                    <>
                        <Alert variant="info">
                            Для того чтобы переименовать проект, перейдите в
                            раздел <strong>настроек</strong>.
                        </Alert>
                        <Alert variant="info">
                            Чтобы подключить google analytics, яндекс метрику
                            или другие сервисы, зайдите в <strong>настройки</strong> далее в <strong>интеграции</strong>.
                        </Alert>
                    </>
                )}
            </Col>
        </Row>
    );
}

export default AllMultilink;
