import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.container.background_color};
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    flex-wrap: wrap;
    // display: flex;
    // flex-flow: row wrap;
    // justify-content: flex-end;
    // align-items: flex-start;
    // align-content: flex-end;
    // gap: 40px;
    font-size: 20px;
    padding: ${(props) => props.container.background_padding.top}px 
    ${(props) => props.container.background_padding.right}px
    ${(props) => props.container.background_padding.bottom}px
    ${(props) => props.container.background_padding.left}px
    ;
    margin: ${(props) => props.container.background_margin.top}px 
    ${(props) => props.container.background_margin.right}px
    ${(props) => props.container.background_margin.bottom}px
    ${(props) => props.container.background_margin.left}px
    ;
    
    &:after {    
        // content: "🦄";
        content: "";
        filter: brightness(${(props) => props.container.brightness}%) 
        blur(${(props) => props.container.blur}px) 
        opacity(${(props) => props.container.opacity}%)  
    }
`;
export const Item = styled.div`
    flex: 1 1 0;
    align-self: stretch;
    margin: 6px;
    background: ${(props) => props.color};
    color:  ${(props) => 
        (props.styleButton === 'outline'  && props.color) 
        ||
        (props.styleButton === 'bg' && '#fff')
    };
    
    border-radius:  ${(props) => (props.shapeButton === 'none' && '0px') || (props.shapeButton === 'rounded' && '6px') || (props.shapeButton === 'circle' && '180px') };
    display:flex;
    // width: 100%;
    // min-width: calc(33%-8px);
    height: 48px;
`;

export const Button = styled.div`
    background: ${(props) => 
        props.styleButton === 'outline' && '#fff'};
    width: 100%;
    height: 100%;
    
    padding-left: ${(props) => (props.typeButton === 'all' && '5px')};
    display:flex;
    text-align:center;
    justify-content: ${(props) => (props.typeButton === 'all' ? 'left': 'center')}; 
    align-items: center;
    border-radius: ${(props) => (props.shapeButton === 'none' && '0px') || (props.shapeButton === 'rounded' && '6px') || (props.shapeButton === 'circle' && '180px') };
    `;

export const Logo = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 6px;

`;
export const Title = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 6px;

`;

