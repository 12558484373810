import React from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { createMultilink } from "../../../../redux/multilink/multilink.action";

import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import styles from "./CreateMultilink.module.scss";

function CreateMultilink() {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const user = useSelector(({ user }) => user.userAuthInfo);
    // const multilinkrRedirect = useSelector(({ multilink }) => multilink.multilinkRedirect);

    const [multilink, setMultilink] = React.useState({
        title: "",
        desc: "",
        creator: user.id,
    });

    const changeHandler = (event) => {
        setMultilink({ ...multilink, [event.target.name]: event.target.value });
    };

    // const [alert, setAlert] = React.useState({ msg: "", type: "" });
    // const [toastShow, setToastShow] = React.useState(false);

    const createMultilinkHandler = () => {
        dispatch(createMultilink(multilink));

        setTimeout(() => navigate(`/multilink/`), 1000);
        // setTimeout(() => dispatch(failureMultilink(null)), 3000);
    };

    return (
        <>
            <Row className={styles.container}>
                <Col sm={7}>
                    {/* 
                    {projectError.error === null ? "" : 
                        
                        <Alert
                            variant="danger"
                            onClose={() => dispatch(addTodoFailure(null))}
                            dismissible
                        >
                            <p>{projectError.error}</p>
                        </Alert>

                    } 
                */}

                    <Form>
                        <Form.Group>
                            <Form.Label>Заголовок</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={multilink.title}
                                onChange={changeHandler}
                                placeholder="Укажите название проекта"
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Описание</Form.Label>
                            <Form.Control
                                type="text"
                                name="desc"
                                value={multilink.desc}
                                onChange={changeHandler}
                                placeholder="Краткое описание проекта"
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            // disabled={loading}
                            // type="submit"
                            onClick={createMultilinkHandler}
                        >
                            Далее
                        </Button>
                    </Form>
                </Col>
                <Col sm={5}>
                    <Alert variant="info">
                        Создавая новый проект вы соглашаетесь с правилами
                        использования сайта
                    </Alert>
                </Col>
            </Row>
        </>
    );
}

export default CreateMultilink;
