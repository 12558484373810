import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
    removeMultilink,
    copyMultilink,
} from "../../../../../redux/multilink/multilink.action";
import { NavLink } from "react-router-dom";

import styles from "./MultilinkItem.module.scss";
import {
    Card,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";
import {
    AiOutlineCopy,
    AiOutlineDelete,
    AiOutlineLineChart,
    AiOutlineSetting,
} from "react-icons/ai";

function MultilinkItems({ multilink }) {
    const user = useSelector(({ user }) => user.userAuthInfo);
    const dispatch = useDispatch();

    const removeHandler = () => {
        dispatch(removeMultilink(multilink._id, user));
        setShow(false)
    };
    const copyHandler = () => {
        dispatch(copyMultilink(multilink._id, user));
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Удалить проект?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите удалить Ваш проект мультиссылки <strong>{multilink.title}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => removeHandler()}>
                        Да, удалить!
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Отменить удаление
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card>
                <Card.Body>
                    <Row>
                        <Col sm={6}>
                            <h2 className={styles.h2}>{multilink.title} </h2>
                            <small className={styles.desc}>
                                {multilink.desc && multilink.desc}
                            </small>
                        </Col>
                        <Col sm={6}>
                            <div className={styles.buttons}>
                                <Button
                                    as={NavLink}
                                    to={{
                                        pathname: multilink._id,
                                    }}
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    Редактировать
                                </Button>{" "}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            Копировать
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-dark"
                                        size="sm"
                                        onClick={() => copyHandler()}
                                    >
                                        <AiOutlineCopy />
                                    </Button>
                                </OverlayTrigger>{" "}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            Удалить
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleShow()}
                                    >
                                        <AiOutlineDelete />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer className={styles.card__footer}>
                    <div>
                        <AiOutlineLineChart />{" "}
                        <Card.Link
                            as={NavLink}
                            to={{
                                pathname: `/multilink/stats/${multilink._id}`,
                            }}
                            style={{ textDecoration: "none" }}
                        >
                            Статистика
                        </Card.Link>
                    </div>
                    <div>
                        <AiOutlineSetting />{" "}
                        <Card.Link
                            as={NavLink}
                            to={{
                                pathname: `/multilink/setting/${multilink._id}`,
                            }}
                            style={{ textDecoration: "none" }}
                            multilink={multilink}
                        >
                            Настройки
                        </Card.Link>
                    </div>
                </Card.Footer>
            </Card>
        </>
    );
}

export default MultilinkItems;
