import React from "react";
import icon from "./icon";

import styles from "./IconComponent.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function IconComponent({ onEmojiClick, show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Иконки</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.container}>
                    <div>

                        
                    </div>
                    <div className={styles.block}>
                        {icon.FontAwesome.map((em) => (
                            <i className={`${em} ${styles.emoji}`} 
                            onClick={() => onEmojiClick(em)}
                            key={`${em}_icon`}
                            ></i>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    );
}

export default IconComponent;
