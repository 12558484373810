export const ONLINE_RU = {
    
    online: {
        main: {
            service_name: "Название услуги",
            service_description: "Описание услуги",
            service_duration: "Длительность услуги",
            service_price: "Цена услуги",
            minutes: "минут",
            currency: "руб.",
            save: "Сохранить",
            edit: "Редактировать",
            delete: "Удалить",
        },
        service: {
            create_title: "Создать услугу",
            edit_title: "Редактировать услугу",
            list_title: "Список услуг",
            name: "Название",
            description: "Описание",
            duration: "Длительность",
            price: "Цена",
        },
        schedule: {
            create_title: "Создать расписание",
            edit_title: "Редактировать расписание",
            list_title: "Список расписаний",
            start_time: "Время начала",
            end_time: "Время окончания",
            status: "Статус",
        },
        booking: {
            create_title: "Создать запись",
            edit_title: "Редактировать запись",
            list_title: "Список записей",
            user_name: "Имя пользователя",
            user_phone: "Телефон пользователя",
            additional_info: "Дополнительная информация",
        },
        blacklist: {
            create_title: "Добавить в черный список",
            edit_title: "Редактировать черный список",
            list_title: "Список черного списка",
            user_name: "Имя пользователя",
            user_phone: "Телефон пользователя",
        },
    },
};
