import React, { useState } from "react";
import { Col, Row, Form, Button, InputGroup, Alert } from "react-bootstrap";
import { AiFillCopy } from "react-icons/ai";
import styles from "../Tools.module.scss";
// import Animate, { AnimateGroup } from "react-smooth";
import {
    FormLoadAnim,
    ButtonLoadAnim,
    // appear,
    // leave,
} from "../../../Animate/index";

function UTMGenerator() {
    const [utm, setUtm] = useState({
        site: "",
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_content: "",
        utm_term: "",
    });

    const [utmGenerate, setUtmGenerate] = useState(false);

    const [copyBtn, setCopyBtn] = useState(false);
    const utmGeneratorHandler = (e) => {
        // удаляем пробел
        let text = e.target.value.replace(/\s/g, "");

        setUtm({ ...utm, [e.target.name]: text });
        setUtmGenerate(true);

        if (utm.site && utm.utm_source && utm.utm_medium && utm.utm_campaign) {
            setCopyBtn(true);
        } else {
            setCopyBtn(false);
        }
    };

    const copyBtnHandler = () => {
        navigator.clipboard.writeText(
            `${utm.site}${utm.utm_source && "?utm_source=" + utm.utm_source}${
                utm.utm_medium && "&utm_medium=" + utm.utm_medium
            }${utm.utm_campaign && "&utm_campaign=" + utm.utm_campaign}${
                utm.utm_content && "&utm_content=" + utm.utm_content
            }${utm.utm_term && "&utm_term=" + utm.utm_term}`
        );
        // setShow(!show);
        // setTimeout(() => {
        //     setShow(show);
        // }, 2500);
    };


    return (
        <div>
            <Row>
                <Col md={8}>
                    <Form className={styles.form}>
                        <h5>Генератор UTM меток</h5>
                        {/* <AnimateGroup appear={appear} leave={leave}> */}
                        <Form.Group key={"1"}>
                            <Form.Label>Адрес страницы</Form.Label>
                            <Form.Control
                                name="site"
                                onChange={utmGeneratorHandler}
                                type="text"
                                placeholder="Введите адрес страницы"
                            />
                        </Form.Group>
                        <Form.Group key={"2"}>
                            <Form.Label>Источник трафика</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                    utm_source
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="utm_source"
                                    onChange={utmGeneratorHandler}
                                    placeholder="Источник трафика (google, yandex, vk, facebook) "
                                />
                            </InputGroup>
                        </Form.Group>
                        {/* </AnimateGroup> */}

                        <Form.Group>
                            <Form.Label>Тип трафика</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                    utm_medium
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="utm_medium"
                                    onChange={utmGeneratorHandler}
                                    placeholder="Тип трафика (cpc, cpm, email, sms, etc.)"
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Название кампании</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                    utm_campaign
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="utm_campaign"
                                    onChange={utmGeneratorHandler}
                                    placeholder="Название кампании (summer_sale, new_year, etc.)"
                                />
                            </InputGroup>
                        </Form.Group>

                        <Alert variant="secondary">
                            <h5>Необязательные параметры</h5>
                            
                            <Form.Group>
                                <Form.Label>Тип объявления</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Text>
                                        utm_content
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="utm_content"
                                        onChange={utmGeneratorHandler}
                                        placeholder="Тип объявления (text, image, video, etc.)"
                                    />
                                </InputGroup>
                            </Form.Group>
                            
                            <Form.Group>
                                <Form.Label>Ключевые слова</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Text>utm_term</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="utm_term"
                                        onChange={utmGeneratorHandler}
                                        placeholder="Введите ключевые слова (keyword1, keyword2, etc.)"
                                    />
                                </InputGroup>
                            </Form.Group>

                        </Alert>
                    </Form>
                </Col>
                <Col md={4}>
                    <Form.Group className={styles.info}>
                        <div className={styles.title_container}>
                            <Form.Label>Результат</Form.Label>
                            {copyBtn && (
                                // <Animate steps={ButtonLoadAnim}>
                                <Button
                                    variant="light"
                                    onClick={copyBtnHandler}
                                    size="sm"
                                    // className={styles.roll_out}
                                >
                                    <AiFillCopy /> Копировать{" "}
                                </Button>
                                // </Animate>
                            )}
                        </div>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={5}
                            // placeholder="Результат"
                            disabled
                            value={
                                utmGenerate
                                    ? `${utm.site}${
                                          utm.utm_source &&
                                          "?utm_source=" + utm.utm_source
                                      }${
                                          utm.utm_medium &&
                                          "&utm_medium=" + utm.utm_medium
                                      }${
                                          utm.utm_campaign &&
                                          "&utm_campaign=" + utm.utm_campaign
                                      }${
                                          utm.utm_content &&
                                          "&utm_content=" + utm.utm_content
                                      }${
                                          utm.utm_term &&
                                          "&utm_term=" + utm.utm_term
                                      }`
                                    : ""
                            }
                        />
                        {!(
                            utm.site &&
                            utm.utm_source &&
                            utm.utm_medium &&
                            utm.utm_campaign
                        ) && (
                            <small>Заполните все обязательные параметры</small>
                        )}
                    </Form.Group>

                    {/* <Button variant="primary" type="submit">
                        Сгенерировать
                    </Button> */}
                </Col>
            </Row>
        </div>
    );
}

export default UTMGenerator;
