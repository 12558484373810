import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { render, screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import styles from "../Tools.module.scss";
import { Button, Form, Row, Col, Alert, InputGroup } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

const PasswordGenerator = () => {
    const { t } = useTranslation("tools");

    const [password, setPassword] = useState("");
    const [passwordLength, setPasswordLength] = useState(8);
    const [lowercase, setLowercase] = useState(true);
    const [uppercase, setUppercase] = useState(true);
    const [numbers, setNumbers] = useState(true);
    const [symbols, setSymbols] = useState(false);
    const [error, setError] = useState("");

    const generatePassword = () => {
        // Build the character set to use for the password
        let charSet = "";
        if (lowercase) charSet += "abcdefghijklmnopqrstuvwxyz";
        if (uppercase) charSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        if (numbers) charSet += "0123456789";
        if (symbols) charSet += "!@#$%^&*";

        // Validate the character set
        if (charSet.length === 0) {
            setPassword("");
            setError(t("tools.PasswordGenerator.error"));
            return;
        }

        // Generate a random password
        let newPassword = "";
        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * charSet.length);
            newPassword += charSet[randomIndex];
        }
        setPassword(newPassword);
        setError("");
    };

    const copyPassword = () => {
        // Copy the password to the clipboard
        navigator.clipboard.writeText(password);
    };

    const fadeInProps = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 500,
    });

    useEffect(() => {
        generatePassword();
    }, []);

    return (
        <Row>
            <Col sm={8}>
                <Form className={styles.form}>
                    <animated.div style={fadeInProps}>
                        {error && <Alert variant="danger">{error}</Alert>}
                    </animated.div>
                    <InputGroup className="mb-3">
                        <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            value={password}
                        />
                       {!error &&  <Button variant="outline-secondary" onClick={copyPassword}>{t("tools.PasswordGenerator.copy")}</Button>}
                    </InputGroup>

                    <Button onClick={generatePassword}>
                        {t("tools.PasswordGenerator.generate")}
                    </Button>
                </Form>
                <div>
                    <p>
                        Используя интернет, мы часто должны регистрироваться на
                        различных сайтах, форумах, играх, социальных сетях,
                        wifi, и чтобы сделать это, нам нужно предоставить логин
                        и создать сложный пароль, который обычно состоит из 10
                        или 8 символов. Логин обычно не является проблемой для
                        пользователей, но что делать с паролем? Иногда нелегко
                        придумать сложный и безопасный пароль, а иногда у нас
                        просто нет времени. Вы можете сгенерировать сложный
                        пароль с 8-10 или более символами с помощью нашего
                        онлайн-генератора паролей. Вам даже не придется
                        заполнять простую и понятную форму на сайте passguru.ru.
                        Вы можете сгенерировать пароль одним нажатием кнопки на
                        сайте. Не забудьте сохранить или записать
                        сгенерированный код, чтобы защитит
                    </p>
                </div>
            </Col>
            <Col sm={4}>
                <div className={styles.info}>
                    <Row>
                        <Col>{t("tools.PasswordGenerator.length")}</Col>
                        <Col>
                            <Form.Control
                                type="number"
                                min="8"
                                max="20"
                                value={passwordLength}
                                onChange={(event) =>
                                    setPasswordLength(event.target.value)
                                }
                            />
                        </Col>
                        <div>
                            <Form.Check
                                type="switch"
                                checked={lowercase}
                                onChange={(event) =>
                                    setLowercase(event.target.checked)
                                }
                                label={t("tools.PasswordGenerator.settings.lowercase")}
                            />
                            <Form.Check
                                type="switch"
                                checked={uppercase}
                                onChange={(event) =>
                                    setUppercase(event.target.checked)
                                }
                                label={t("tools.PasswordGenerator.settings.uppercase")}
                            />
                            <Form.Check
                                type="switch"
                                checked={numbers}
                                onChange={(event) =>
                                    setNumbers(event.target.checked)
                                }
                                label={t("tools.PasswordGenerator.settings.numbers")}
                            />
                            <Form.Check
                                type="switch"
                                checked={symbols}
                                onChange={(event) =>
                                    setSymbols(event.target.checked)
                                }
                                label={t("tools.PasswordGenerator.settings.symbols")}
                            />
                        </div>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

PasswordGenerator.propTypes = {
    passwordLength: PropTypes.number,
    lowercase: PropTypes.bool,
    uppercase: PropTypes.bool,
    numbers: PropTypes.bool,
    symbols: PropTypes.bool,
    error: PropTypes.string,
};






export default PasswordGenerator;
