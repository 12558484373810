import { combineReducers } from 'redux'


// import filtersReducer from './filters'
// import personalReducer from './personal'
import userReducer from './user/user.reducers';
import multilinkReducer from './multilink/multilink.reducers';
import minishopReducer from './minishop/minishop.reducers';
import storyLandingReducer from './storyLanding/storyLanding.reducers';
import languageReducer from './language/language.reducers';
import profileReducer from './profile/profile.reducers';
import openaiReducer from './openai/openai.reducers';
import onlineReducer from './online/online.reducer';


const rootReducer = combineReducers({
    // filters: filtersReducer,
    // personal: personalReducer,
    multilink: multilinkReducer,
    minishop: minishopReducer,
    storyLanding:storyLandingReducer,
    user: userReducer,
    language: languageReducer,
    profile: profileReducer,
    openAi: openaiReducer,
    online: onlineReducer
})

export default rootReducer