import React, { useEffect } from "react";
import { Alert, Button, Card, Col, Nav, Row } from "react-bootstrap";
import { NavLink,  Routes, Route, Navigate, Outlet  } from 'react-router-dom';


function SettingMultilink() {




    return (
        <Row>
            <Col sm={7}>
                <Card >
                    <Card.Header>
                        <Nav variant="pills" >
                            <Nav.Item  >
                                <Nav.Link  as={NavLink} end to="">Основное</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="access">Доступ</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="integration">Интеграции</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    

                    <Card.Body>
                        <Outlet />
                    </Card.Body>

                </Card>            
            </Col>
            <Col sm={5}>
                <Alert >
                    В этом разделе находятся основные настройки проекта, интеграции к сторонним системам и настройки доступа.
                </Alert>
            </Col>


        </Row>
    );
}

export default SettingMultilink;
