import React from "react";

import { useTranslation } from "react-i18next";

import { Col, Container, Row } from "react-bootstrap";
import styles from "./Main.module.scss";

function Main() {

    const { t } = useTranslation();

    return (
        <Container className={styles.container} fluid>
            <Container>
                <Row>
                    <Col sm={8}>
                        <div className={styles.title}>
                            <h1 className={styles.h1}>{t("landing.title.part1")} <span>{t("landing.title.part2")}</span> {t("landing.title.part3")}</h1>
                        </div>
                        <p className={styles.desc}>{t("landing.desc")}</p>
                    </Col>
                    <Col sm={4}>

                        {/* <img src="/confetti-snapto-header.png" width='750' style={{position: 'absolute'}}/>                     */}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Main;
