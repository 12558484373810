import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    StoryLandingProjectForm,
    StoryLandingProjectList,
    StoryLandingProjectView,
} from "../App/Pages/StoryLanding";
import { OffcanvasProvider } from "../hook/Context/OffcanvasContext";
import { PATH_STORYLANDING } from "../constants/path";

export default function StoryLandingRoutes() {
    return (
<OffcanvasProvider>
  <Routes>
    <Route path={PATH_STORYLANDING.root} element={<StoryLandingProjectList />} />
    <Route path={PATH_STORYLANDING.create} element={<StoryLandingProjectForm />} />
    <Route path={PATH_STORYLANDING.id} element={<StoryLandingProjectView />} />
  </Routes>
</OffcanvasProvider>
    );
}
