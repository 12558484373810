import React from "react";
import { useDispatch } from "react-redux";
import ButtonElement from "./Element/ButtonElement";
import FormElement from "./Element/FormElement";
import ImageElement from "./Element/ImageElement";
import TextElement from "./Element/TextElement";
import VideoElement from "./Element/VideoElement";
import { getElement } from "../../../../../redux/storyLanding/storyLanding.action";
import { useOffcanvas } from "../../../../../hook/Context/OffcanvasContext";

const StoryLandingElementView = ({ element }) => {
    const { showOffcanvas, openOffcanvas, closeOffcanvas } = useOffcanvas();

    const dispatch = useDispatch();


    const parseStyles = (styles) => {
        let stylesObject = {};
        for (const key in styles) {
            const value = styles[key];
            let jsKey = key.replace(/(-\w)/g, matches => matches[1].toUpperCase());
            if (typeof value === "object" && value.hasOwnProperty("value") && value.hasOwnProperty("units")) {
                stylesObject[jsKey] = `${value.value}${value.units}`;
            } else {
                stylesObject[jsKey] = value;
            }
        }
        return stylesObject;
    };


    const renderElement = () => {
        switch (element.type) {
            case "text":
                return (
                    <TextElement
                        content={element.content}
                        styles={parseStyles(element?.styles)}
                    />
                );
            case "button":
                return (
                    <ButtonElement
                        content={element.content}
                        styles={parseStyles(element?.styles)}
                    />
                );
            case "image":
                return (
                    <ImageElement
                        content={element.content}
                        styles={parseStyles(element?.styles)}
                    />
                );
            case "video":
                return (
                    <VideoElement
                        content={element.content}
                        styles={parseStyles(element?.styles)}
                    />
                );
            case "form":
                return (
                    <FormElement
                        content={element.content}
                        styles={parseStyles(element?.styles)}
                    />
                );
            default:
                return null;
        }
    };

    const handleSubmit = (e) => {
        dispatch(getElement(null))
        e.preventDefault();
        openOffcanvas();
        dispatch(getElement(element));
    };

    return (
        <div onClick={handleSubmit}>
            {renderElement()}
            {/* Здесь можно добавить дополнительные настройки и кнопки для редактирования и удаления элемента */}
        </div>
    );
};

export default StoryLandingElementView;
