// services/OnlineService.js
import $api from "../http";

export default class OnlineService {
    /////
    static async fetchProjects(userId) {
        return await $api.get(`/online/projects/fetch/${userId}`);
    }

    static async createProject(project) {
        return await $api.post("/online/projects/add", project);
    }

    static async copyProject(project) {
        return await $api.post("/online/projects/copy", project);
    }

    static async updateProject(projectId, projectData) {
        return await $api.put(`/online/projects/${projectId}`, projectData);
    }

    static async deleteProject(projectId) {
        return await $api.delete(`/online/projects/${projectId}`);
    }
    //////
    
    static async fetchServices() {
        return await $api.get("/online/services");
    }

    static async createService(data) {
        console.log("createService", data);
        return await $api.post("/online/services/create", { data });
    }

    static async updateService(id, data) {
        return await $api.put(`/online/services/${id}`, { data });
    }

    static async deleteService(id) {
        return await $api.delete(`/online/services/${id}`);
    }

    static async fetchSchedules() {
        return await $api.get("/online/schedules");
    }

    static async createSchedule(data) {
        return await $api.post("/online/schedules", { data });
    }

    static async updateSchedule(id, data) {
        return await $api.put(`/online/schedules/${id}`, { data });
    }

    static async deleteSchedule(id) {
        return await $api.delete(`/online/schedules/${id}`);
    }

    static async fetchBookings() {
        return await $api.get("/online/bookings");
    }

    static async createBooking(data) {
        return await $api.post("/online/bookings", { data });
    }

    static async updateBooking(id, data) {
        return await $api.put(`/online/bookings/${id}`, { data });
    }

    static async deleteBooking(id) {
        return await $api.delete(`/online/bookings/${id}`);
    }

    static async fetchBlacklist() {
        return await $api.get("/online/blacklist");
    }

    static async addToBlacklist(data) {
        return await $api.post("/online/blacklist", { data });
    }

    static async updateBlacklistItem(id, data) {
        return await $api.put(`/online/blacklist/${id}`, { data });
    }

    static async removeFromBlacklist(id) {
        return await $api.delete(`/online/blacklist/${id}`);
    }
}
