import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./CarouselComponent.module.scss";
import { NavLink } from 'react-router-dom';
import { sliceString } from "../../../../utils";

function CarouselComponent({multilinksFetch}) {



    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        
        <Carousel responsive={responsive} className={styles.container}>
            {multilinksFetch.map((item, index) => (
                <NavLink to={`/multilink/${item._id}`} key={index} className={styles.carouselItem}>
                    <div className={styles.title}>{sliceString(item.title, 28)}</div>
                    
                </ NavLink>
            ))}

        </Carousel>
    );
}

export default CarouselComponent;
