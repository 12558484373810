import OpenAiService from "../../services/OpenAiService";
import OpenAiActionTypes from "./openai.types";


export const createOpenAi = (data) => {
    return async (dispatch) => {
        dispatch({
            type: OpenAiActionTypes.OPENAI_LOADING,
            payload: false,
        });
        
        const response = await OpenAiService.create(data);

        if ( response.data.error )
        {
            dispatch({
                type: OpenAiActionTypes.OPENAI_ERROR,
                payload: {error: true, msg: response.data.error},
            });
    
        } else {
            dispatch({
                type: OpenAiActionTypes.OPENAI_CURRENT_TEXT,
                payload: response.data.openAi.allOpenAi.textItems[0]
            })
            dispatch({
                type: OpenAiActionTypes.OPENAI_DATA,
                payload: response.data.openAi.allOpenAi // получили последний
            })            
        }


		dispatch({
            type: OpenAiActionTypes.OPENAI_LOADING,
            payload: true,
        });



        // dispatch({
        //     type: OpenAiActionTypes.OPENAI_CREATE,
        //     payload: response.data,
        // });
        dispatch({
            type: OpenAiActionTypes.OPENAI_REDIRECT,
            payload: true,
        });
    };
};

export const dataOpenAi = (user) => {

    return async (dispatch) => {

        console.log('redux createOpenAi', user );
		dispatch({
            type: OpenAiActionTypes.OPENAI_REDIRECT,
            payload: false,
        });

        const response = await OpenAiService.data(user);
        dispatch({
            type: OpenAiActionTypes.OPENAI_DATA,
            payload: response.data,
        });
    };
};

export const getOpenAi = (data) => {
    return async (dispatch, getState) => {
        // dispatch({
        //     type: OpenAiActionTypes.OPENAI_LOADING,
        //     payload: true,
        // });

        let items = getState().openAi.openAiData.textItems
        if (items === undefined) {
           await dispatch(dataOpenAi({ user: data.userId }))
           console.log('зашли в запрос');
                items = getState().openAi.openAiData.textItems

        } 
        const candidate = items.find(x => x._id === data.projectId);
        dispatch({
            type: OpenAiActionTypes.OPENAI_CURRENT_TEXT,
            payload: candidate
        })
        // await dispatch({
        //     type: OpenAiActionTypes.OPENAI_GET,
        //     payload: response.data.getOpenAi,
        // });
        // dispatch({
        //     type: OpenAiActionTypes.OPENAI_LOADING,
        //     payload: false,
        // });
    };
};

export const fetchShops = (store) => {
    // return async dispatch => {
    // 	const response = await OpenAiService.create(store)
    // 	dispatch({
    // 		type:OpenAiActionTypes.OPENAI_CREATE,
    // 		payload: response.data
    // 	})
    // 	dispatch({
    // 		type: OpenAiActionTypes.OPENAI_REDIRECT,
    // 		payload: response.data._id
    // 	})
    // }
};
export const copyShop = (store) => {
    // return async dispatch => {
    // 	const response = await OpenAiService.create(store)
    // 	dispatch({
    // 		type:OpenAiActionTypes.OPENAI_CREATE,
    // 		payload: response.data
    // 	})
    // 	dispatch({
    // 		type: OpenAiActionTypes.OPENAI_REDIRECT,
    // 		payload: response.data._id
    // 	})
    // }
};
export const removeShop = (store) => {
    // return async dispatch => {
    // 	const response = await OpenAiService.create(store)
    // 	dispatch({
    // 		type:OpenAiActionTypes.OPENAI_CREATE,
    // 		payload: response.data
    // 	})
    // 	dispatch({
    // 		type: OpenAiActionTypes.OPENAI_REDIRECT,
    // 		payload: response.data._id
    // 	})
    // }
};
