// Компонент всплывающего окна с предложением купить про
import React from 'react'
import { Modal, Button } from 'react-bootstrap';

function PaidModalComponent({showModalPaid, handleCloseModalPaid}) {
  return (
    <Modal show={showModalPaid} onHide={handleCloseModalPaid}>
    <Modal.Header closeButton>
        <Modal.Title>Оплатите тариф PRO</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        Ещё больше возможностей в тарифе про
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalPaid}>
            Оплатить месяц 190р
        </Button>
        <Button variant="primary" onClick={handleCloseModalPaid}>
            Оплатить год 1700р
        </Button>
    </Modal.Footer>
</Modal>
  )
}

export default PaidModalComponent