import React from "react";
import { Card, Button } from "react-bootstrap";
import styles from "./SettingMultilinkIntegration.module.scss";

function SettingMultilinkIntegration() {
    return (
        <div>
			<div className={styles.titleBlock}>Статистика</div>

            <div className={styles.container}>
                <Card style={{ width: "48%" }}>
                    <Card.Img variant="top" src="" />
                    <Card.Body>
                        <Card.Title>Яндекс метрика</Card.Title>
                        <Card.Text>
                            Небольшое описание яндекс метрики
                        </Card.Text>
                        <Button variant="primary">Подключить</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: "48%" }}>
                    <Card.Img variant="top" src="" />
                    <Card.Body>
                        <Card.Title>Google Analytics</Card.Title>
                        <Card.Text>
                            Небольшое описание google analytics
                        </Card.Text>
                        <Button variant="primary">Подключить</Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default SettingMultilinkIntegration;
