import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { BsFillPlusCircleFill, BsPlusCircle } from "react-icons/bs";
import { NavLink } from 'react-router-dom';

function ProfileBalance({ user }) {
    return (
        <Card style={{ backgroundColor: "#0068ff", color: "#fff" }}>
            <Card.Body>
                <Row>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>Ваш баланс: <strong>{user.balance} ₽</strong></div>
                        <Button variant="dark" as={NavLink} to='/profile/balance' style={{lineHeight: '1px', padding: "12px"}}><BsPlusCircle/></Button>
                    </div>
                    <Col sm={8}></Col>
                    <Col sm={4}></Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default ProfileBalance;
