import React, { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { AiOutlineLineChart, AiOutlineSetting } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchProjectOnline } from "../../../redux/online/online.action";
import { OnlineCreate } from "./index.js";
import styles from "./Online.module.scss";
import ProjectList from "../../Components/Ui/ProjectList/ProjectList";

function Online() {
    const { id: userId } = useSelector(({ user }) => user.userAuthInfo);

    const projectsOnline = useSelector(({ online }) => online.projects);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchProjectOnline(userId));
    }, []);

    if (!projectsOnline || projectsOnline.length === 0) {
        return <OnlineCreate />;
    }

    return (
        <>
            <Row>
                <Col sm={7}>
                    {projectsOnline.map((project) => (
                        <ProjectList project={project} key={project._id} />
                    ))}
                </Col>
                <Col sm={5}></Col>
            </Row>
        </>
    );
}

export default Online;
