import React from "react";

import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import styles from "../PageShop.module.scss";
import PageShopSetting from "../PageShopSetting/PageShopSetting";
import PageShopAddLabel from "./PageShopAddLabel/PageShopAddLabel";
import PageShopAddCat from "./PageShopAddCat/PageShopAddCat";

function PageShopInfo({ shop, userId }) {
    return (
        <div>
            <Row className={styles.block}>
                <Col sm={4}>
                    <PageShopAddCat shop={shop} userId={userId} />
                </Col>
                <Col sm={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Тип: <b>0</b>
                            </Card.Title>

                            <Button variant="secondary" type="submit" size="sm">
                                Добавить типы
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                    <PageShopAddLabel shop={shop} userId={userId} />
                </Col>
            </Row>{" "}
            <Row>
                <Col sm={4}>
                    {/* <Alert variant="secondary" className={styles.onCart}>
                        <b>Корзина товаров</b>

                        <div>
                            {" "}
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Включить"
                            />{" "}
                        </div>
                    </Alert>                     */}
                </Col>
                <Col sm={8}></Col>
            </Row>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Настройки магазина</Card.Title>
                    <div className="mb-3">
                        <small>
                            Включение корзины товаров. Настройки формы заказа,
                            оплаты, доставки, выбор валюты и т.д.
                        </small>
                    </div>

                    <PageShopSetting />
                </Card.Body>
            </Card>
        </div>
    );
}

export default PageShopInfo;
