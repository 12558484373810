import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";

import styles from "./QrCreator.module.scss";
import { Alert, Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { AddColorComponent } from "../../../Components";
import QrCreatorVcard from "./QrCreatorVcard/QrCreatorVcard";
import { NavLink } from "react-router-dom";

function QrCreator() {
    const [qrData, setQrData] = useState({
        text: "",
        bgColor: "#FFFFFF",
        fgColor: "#000000",
        size: 256,
        level: "L",
        includeMargin: false,
        image: {},
    });

    const [qrDataVcardDefault, setQrDataVcardDefault] = useState(false);

    console.log("qrData", qrData);
    const qrCodeHandler = (e) => {
        setQrData({ ...qrData, [e.target.name]: e.target.value });
    };

    const qrCodeHandlerValueNull = (e) => {
        setQrData({ ...qrData, text: "" });
        setQrDataVcardDefault(true);
        console.log("click");
    };

    const qrCodeCheckboxHandler = (e) => {
        setQrData({ ...qrData, [e.target.name]: e.target.checked });
    };

    const [qrCodeImageSize, setQrCodeImageSize] = useState({
        width: 0,
        height: 0,
    });

    const qrCodeImageLoadHandler = async (e) => {
        // создаем объект blob из файла изображения и получаем его url для отображения в img
        let blob = new Blob([e.target.files[0]], { type: "image/png" });
        let url = URL.createObjectURL(blob);


        // получаем данные изображения
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        
        let imgWidth = 0;
        let imgHeight = 0;
await (img.onload = (function () {
                imgHeight = img.height;
                imgWidth = img.width;
            }))

        
        console.log(img.width);

        await setQrData({
            ...qrData,
            image: {
                excavate: false,
                src: url,
                width: imgWidth,
                height: imgHeight,
                x: undefined,
                y: undefined,
            },
        });

        // setQrData({ ...qrData, [e.target.name]: url });
    };

    const qrCodeImageSettingHandler = (e) => {
        setQrData({
            ...qrData,
            image: {
                ...qrData.image,
                [e.target.name]: e.target.valueAsNumber
                    ? e.target.valueAsNumber
                    : e.target.value,
            },
        });
    };

    const qrCodeImageSettingCheckHandler = (e) => {
        setQrData({
            ...qrData,
            image: { ...qrData.image, [e.target.name]: e.target.checked },
        });
    };

    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleExport = () => {
        const canvasSave = document.querySelector(
            ".canvas_container > .canvas > canvas"
        );

        var imageData = canvasSave.toDataURL("image/png");
        downloadURI(imageData, "qr.png");
    };

    /////////////////////////////////////////////////////
    const colorBgHandler = (hex) => {
        setQrData({ ...qrData, bgColor: hex });
    };
    const colorFgHandler = (hex) => {
        setQrData({ ...qrData, fgColor: hex });
    };

    const [showImage, setShowImage] = useState(false);
    const imageHandler = (e) => {
        setShowImage(e.target.checked);
        if (!e.target.checked) {
            setQrData({ ...qrData, image: {} });
        } else {
            setQrData({
                ...qrData,
                image: {
                    src: "/favic.png",
                    x: undefined,
                    y: undefined,
                    height: 48,
                    width: 48,
                    excavate: false,
                },
            });
        }
    };

    const [showImagePosition, setShowImagePosition] = useState(false);
    const imagePositionHandler = (e) => {
        setShowImagePosition(e.target.checked);
        if (!e.target.checked) {
            setQrData({
                ...qrData,
                image: { ...qrData.image, x: undefined, y: undefined },
            });
        } else {
            setQrData({
                ...qrData,
                image: { ...qrData.image, x: 0, y: 0 },
            });
        }
    };

    return (
        <Row>
            <Col>
                <div className={styles.main_info}>
                    <Row>
                        <Col sm={10}>
                            <Tabs
                                defaultActiveKey="link"
                                id="tab-value"
                                className="mb-12"
                                onClick={qrCodeHandlerValueNull}
                            >
                                <Tab
                                    eventKey="link"
                                    title="Ссылка"
                                    className={styles.tab}
                                >
                                    <Form.Group controlId="formQr">
                                        <Form.Control
                                            type="text"
                                            placeholder="укажите ссылку для генерации QR-кода"
                                            value={qrData.text}
                                            name="text"
                                            onChange={qrCodeHandler}
                                            size="sm"
                                        />
                                    </Form.Group>
                                </Tab>
                                <Tab
                                    eventKey="text"
                                    title="Текст"
                                    className={styles.tab}
                                >
                                    <Form.Group controlId="formQr">
                                        <Form.Control
                                            type="text"
                                            placeholder="укажите текст для генерации QR-кода"
                                            as="textarea"
                                            rows={3}
                                            value={qrData.text}
                                            name="text"
                                            onChange={qrCodeHandler}
                                            size="sm"
                                        />
                                    </Form.Group>
                                </Tab>
                                <Tab
                                    eventKey="vcard"
                                    title="Визитка"
                                    className={styles.tab}
                                >
                                    <QrCreatorVcard
                                        qrData={qrData}
                                        setQrData={setQrData}
                                        qrDataVcardDefault={qrDataVcardDefault}
                                        setQrDataVcardDefault={
                                            setQrDataVcardDefault
                                        }
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col
                            sm={2}
                            style={{ display: "flex", alignItems: "flex-end" }}
                        >
                            <Form.Group controlId="formLevel">
                                <Form.Control
                                    as="select"
                                    value={qrData.level}
                                    name="level"
                                    onChange={qrCodeHandler}
                                    size="sm"
                                >
                                    <option value="L">L</option>
                                    <option value="M">M</option>
                                    <option value="Q">Q</option>
                                    <option value="H">H</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.design}>
                    <Row>
                        <Col sm={4}>
                            <Form.Group controlId="formBgColor">
                                <Form.Label>Цвет фона</Form.Label>
                                <AddColorComponent
                                    color={"#FFFFFF"}
                                    colorHandler={colorBgHandler}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group controlId="formFgColor">
                                <Form.Label>Цвет</Form.Label>
                                <AddColorComponent
                                    color={"#000000"}
                                    colorHandler={colorFgHandler}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group controlId="formSize">
                                <Form.Label>Размер QR-кода</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={qrData.size}
                                    name="size"
                                    onChange={qrCodeHandler}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formIncludeMargin">
                        <Form.Check
                            type="checkbox"
                            label="Включить обводку"
                            checked={qrData.includeMargin}
                            name="includeMargin"
                            onChange={qrCodeCheckboxHandler}
                        />
                    </Form.Group>
                </div>

                <div className={styles.design}>
                    <Form.Check
                        type="switch"
                        id="hide-img-switch"
                        label="Загрузить свой логотип"
                        value={showImage}
                        onChange={(e) => imageHandler(e)}
                        className={showImage ? styles.block : ""}
                    />

                    {showImage && (
                        <>
                            <Form.Group className={styles.block}>
                                {/* <Form.Label>Image</Form.Label> */}
                                <Form.Control
                                    type="file"
                                    name="image"
                                    // onLoad={qrCodeImageLoadHandler}
                                    defaultValue={""}
                                    onChange={(e) => qrCodeImageLoadHandler(e)}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="formIncludeExcavate"
                                className={styles.block}
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="Включить обводку логотипа"
                                    checked={qrData.image.excavate}
                                    name="excavate"
                                    onChange={qrCodeImageSettingCheckHandler}
                                />
                            </Form.Group>

                            <Row className={styles.block}>
                                <Col>
                                    <Form.Group controlId="formImageSize">
                                        <Form.Label>
                                            Размер по высоте
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="height"
                                            value={qrData.image.height}
                                            onChange={qrCodeImageSettingHandler}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formImageSize">
                                        <Form.Label>
                                            Размер по ширине
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="width"
                                            value={qrData.image.width}
                                            onChange={qrCodeImageSettingHandler}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Check
                                type="switch"
                                id="hide-img-switch"
                                label="Изменить позицию логотипа"
                                value={showImagePosition}
                                onChange={imagePositionHandler}
                                // className={showImage ? styles.block : ""}
                            />
                            {showImagePosition && (
                                <Row className={styles.block}>
                                    <Col>
                                        <Form.Group controlId="formImageMargin">
                                            <Form.Label>
                                                Позиция логотипа по горизонтали
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="x"
                                                value={Number(qrData.image.x)}
                                                onChange={
                                                    qrCodeImageSettingHandler
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formImageMargin">
                                            <Form.Label>
                                                Позиция логотипа по вертикали
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="y"
                                                value={Number(qrData.image.y)}
                                                onChange={
                                                    qrCodeImageSettingHandler
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </div>
            </Col>
            <Col>
                <div className={styles.sidebar}>
                    <div className="canvas_container">
                        <div className="canvas">
                            <QRCodeCanvas
                                style={{
                                    maxWidth: "150px",
                                    maxHeight: "150px",
                                }}
                                // value={
                                //     "BEGIN:VCARD\r\n" +
                                //     "VERSION:3.0\r\n" +
                                //     "N:;Иванов Иван Иванович;;\r\n" +
                                //     "FN:Иванов Иван Иванович\r\n" +
                                //     'ORG:ООО "Рога и копыта"\r\n' +
                                //     "TITLE:Директор\r\n" +
                                //     "TEL;TYPE=WORK,VOICE:8 (495) 123-45-67\r\n" +
                                //     "TEL;TYPE=CELL,VOICE:8 (916) 123-45-67\r\n" +
                                //     "ADR;TYPE=WORK:;;Москва, ул. Льва Толстого, д. 16\r\n" +
                                //     "URL:https://www.google.com\r\n" +
                                //     "END:VCARD"
                                // }
                                value={qrData.text}
                                size={qrData.size}
                                bgColor={qrData.bgColor}
                                fgColor={qrData.fgColor}
                                level={qrData.level}
                                includeMargin={qrData.includeMargin}
                                imageSettings={{
                                    src: qrData.image.src,
                                    x: qrData.image.x,
                                    y: qrData.image.y,
                                    height: qrData.image.height,
                                    width: qrData.image.width,
                                    excavate: qrData.image.excavate,
                                }}
                            />
                        </div>

                        <Button
                            onClick={handleExport}
                            style={{ marginBottom: "5px" }}
                        >
                            {" "}
                            Скачать QR-код{" "}
                        </Button>
                    </div>

                    <div>
                        <Alert variant="info">
                            <Alert.Heading>Что такое QR-код:</Alert.Heading>
                            QR код «QR - Quick Response - Быстрый Отклик» — это
                            двухмерный штрихкод (бар-код), предоставляющий
                            информацию для быстрого ее распознавания с помощью
                            камеры на мобильном телефоне. При помощи QR-кода
                            можно закодировать любую информацию, например:
                            текст, номер телефона, ссылку на сайт или визитную
                            карточку.
                        </Alert>
                        <Alert variant="info">
                            <Alert.Heading>
                                Как вести статистику переходов
                            </Alert.Heading>
                            Вы можете создать специальную ссылку используя utm-метки
                            и вести статистику переходов по ней. Создать такую ссылку поможет наш сервис {' '}
                            <a href="/tools/utm_generator/">генератор utm-меток</a>, вести статистику переходов можно в
                            Яндекс.Метрике или Google Analytics.
                        </Alert>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default QrCreator;
