export const SIDEBAR_RU = {
    sidebar: {
        brand: {
            desc:'Твой SMM помощниик',
        },
        menu:{
            dashboard: 'Дашборд',
            multilink: 'Мультиссылка',
            storilanding: 'Сторилэндинг',
            minishop: 'Минимагазин',
            online: 'On-line запись',
            widget: 'Виджеты',
            tools: 'Инструменты',
            profile: 'Профиль',
        },
    },
    sidebarCRM: {
        menu: {
            bid: 'Заявки',
            tasks: 'Задачи',
            team: 'Команда',
            stat: 'Статистика',
            base: 'Клиентская база'
        }
    }
} 