import React from "react";
import {
    Container,
    Button,
    TextBlock,
    IconBlock,
    DescriptionBlock,
    TitleBlock,
} from "./ViewMultilinkLink.styled";

function ViewMultilinkLink({ blockLink }) {
    console.log("gggggg", blockLink);

    return (
        <>
            <Container container={blockLink}>
                <Button container={blockLink}>
                    <TextBlock container={blockLink}>
                        <TitleBlock>
							{blockLink.main.title}
						</TitleBlock>
                        <DescriptionBlock>
                            {blockLink.main.description}
                        </DescriptionBlock>
                    </TextBlock>
                    {blockLink.main.iconType === "emoji"  && 
                        <IconBlock>{blockLink.main.icon && blockLink.main.icon.emoji}</IconBlock>
                    }
                    {blockLink.main.iconType === "icon"  && 
                        <IconBlock> <i className={blockLink.main.icon && blockLink.main.icon}></i></IconBlock>
                    }
                    {blockLink.main.iconType === "img"  && 
                        <IconBlock>{blockLink.main.icon && blockLink.main.icon}</IconBlock>
                    }
                    
                </Button>
            </Container>
        </>
    );
}

export default ViewMultilinkLink;
