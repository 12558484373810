import React, { useState, useRef } from "react";

import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Row,
} from "react-bootstrap";
import {
    BsTextCenter,
    BsTextRight,
    BsTextLeft,
    BsTypeH1,
    BsTypeH2,
    BsTypeH3,
} from "react-icons/bs";
import { MdTextFields, MdTitle } from "react-icons/md";
import { AddColorComponent } from "../../../../../Components";

function PageTitleMain({ title, setTitle }) {
    const colorBgHandler = (hex) => {
        setTitle({
            ...title,
            main: {
                ...title.main,
                color: hex
            },
        });
    };

    const buttonHandler = (event) => {
        setTitle({
            ...title,
            main: {
                ...title.main,
                [event.target.name]: event.target.value,
            },
        });
    };

    // изменение цвета кнопки
    const [buttonVariant, setButtonVariant] = useState(false);
    const target = useRef(null);

    const handleClick = () => {
        setButtonVariant(!buttonVariant);
    };

    return (
        <>
                <Row className="mb-3">
                    <Col sm={2}>
                        <Form.Select
                            size="sm"
                            name="size"
                            value={title.main.size}
                            onChange={buttonHandler}
                        >
                            <option>21px</option>
                            <option>24px</option>
                            <option>28px</option>
                            <option>32px</option>
                        </Form.Select>
                    </Col>

                    <Col sm={2}>
                        <Form.Select size="sm">
                            <option>Roboto</option>
                        </Form.Select>
                    </Col>

                    <Col sm={2}>
                        <Form.Select
                            size="sm"
                            name="weight"
                            value={title.main.weight}
                            onChange={buttonHandler}
                        >
                            <option value="300">Тонкий</option>
                            <option value="400">Обычный</option>
                            <option value="900">Жирный</option>
                        </Form.Select>
                    </Col>
                    <Col sm={2}>
                        <ButtonGroup size="sm">
                            <Button
                                variant={
                                    title.main.header === "h1"
                                        ? "primary"
                                        : "light"
                                }
                                name="header"
                                value="h1"
                                onClick={buttonHandler}
                            >
                                <BsTypeH1 style={{ pointerEvents: "none" }} />
                            </Button>{" "}
                            <Button
                                variant={
                                    title.main.header === "h2"
                                        ? "primary"
                                        : "light"
                                }
                                name="header"
                                value="h2"
                                onClick={buttonHandler}
                            >
                                <BsTypeH2 style={{ pointerEvents: "none" }} />
                            </Button>{" "}
                            <Button
                                variant={
                                    title.main.header === "h3"
                                        ? "primary"
                                        : "light"
                                }
                                name="header"
                                value="h3"
                                onClick={buttonHandler}
                            >
                                <BsTypeH3 style={{ pointerEvents: "none" }} />
                            </Button>{" "}
                            {/* текст заглавными буквами */}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={3}>
                        <AddColorComponent 
                            color={title.main.color} 
                            colorHandler={colorBgHandler} 
                        />
                    </Col>
                    <Col sm={2}>
                        <ButtonGroup size="sm">
                            <Button
                                variant={
                                    title.main.textAlign === "left"
                                        ? "primary"
                                        : "light"
                                }
                                name="textAlign"
                                value="left"
                                onClick={buttonHandler}
                            >
                                <BsTextLeft style={{ pointerEvents: "none" }} />
                            </Button>
                            <Button
                                variant={
                                    title.main.textAlign === "center"
                                        ? "primary"
                                        : "light"
                                }
                                name="textAlign"
                                value="center"
                                onClick={buttonHandler}
                            >
                                <BsTextCenter
                                    style={{ pointerEvents: "none" }}
                                />
                            </Button>
                            <Button
                                variant={
                                    title.main.textAlign === "right"
                                        ? "primary"
                                        : "light"
                                }
                                name="textAlign"
                                value="right"
                                onClick={buttonHandler}
                            >
                                <BsTextRight
                                    style={{ pointerEvents: "none" }}
                                />
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col sm={2}>
                        <ButtonGroup size="sm">
                            <Button
                                variant={
                                    title.main.transform === "none"
                                        ? "primary"
                                        : "light"
                                }
                                name="transform"
                                value="none"
                                onClick={buttonHandler}
                            >
                                <MdTextFields
                                    style={{ pointerEvents: "none" }}
                                />
                            </Button>{" "}
                            <Button
                                variant={
                                    title.main.transform === "uppercase"
                                        ? "primary"
                                        : "light"
                                }
                                name="transform"
                                value="uppercase"
                                onClick={buttonHandler}
                            >
                                <MdTitle style={{ pointerEvents: "none" }} />
                            </Button>{" "}
                            {/* текст заглавными буквами */}
                        </ButtonGroup>
                    </Col>


                </Row>

                <Row className="mb-3">
                    <Col sm={8}>
                        <Form.Group className="mb-3">
                            {/* <Form.Label>Текст заголовка</Form.Label> */}
                            <Form.Control
                                type="text"
                                size="sm"
                                placeholder="Введите текст заголовка"
                                name="text"
                                defaultValue={title.main.text}
                                value={title.main.text}
                                onChange={buttonHandler}
                                as="textarea"
                                rows={3}
                            />
                            {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text> */}
                        </Form.Group>
                    </Col>
                </Row>
        </>
    );
}

export default PageTitleMain;
