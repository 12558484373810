import $api from "../http";

export default class MultilinkService {


    static async copy(id, userId) {
        return $api.post(`/multilink/copy/${id}`, {id, userId})
    }  


    static async create(data) {
        console.log('/multilink/create', data);
        return $api.post('/multilink/create', {data})
    }   
    static async fetch(data) {
        return $api.post(`/multilink/fetch`, {data})
    }   
    static async remove(id, userId) {
        return $api.post(`/multilink/remove/${id}`, {id, userId})
    }   
    static async get(projectId, userId) {
        // console.log('projectId, userId', projectId, userId);
        return $api.post(`/multilink/get/${projectId}`, {projectId, userId})
    }   
    
    static async editSetting(data, userId) {
        // console.log('projectId, userId', data, userId);
        return $api.post(`/multilink/editSetting/${data.id}`, {data, userId})
    }   
    static async editSlug(data, userId) {
        // console.log('projectId, userId', data, userId);
        return $api.post(`/multilink/editSlug/${data.id}`, {data, userId})
    }   
    

    static async update(userId, projectId, type, data) {
        // console.log(projectId);
        return $api.put(`/multilink/update/${projectId}`, {userId, projectId, type, data})
    }   

}