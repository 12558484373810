import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./ProfilePage.module.scss";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";

import AvatarLoadCrop from "./AvatarLoadCrop/AvatarLoadCrop";
import { AiOutlineCamera } from "react-icons/ai";
import ProfileBalance from './ProfileBalance/ProfileBalance';


function ProfilePage() {
    const user = useSelector(({ user }) => user.userInfo);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);



    return (
        <>
            <AvatarLoadCrop show={show} setShow={setShow} />

            <Row>
                <Col sm={8}>
                    <div className={styles.container}>
                        <Card className={styles.profile}>
                            <Card.Body>
                                <Row>
                                    <Col sm={6}>
                                        <div
                                            className={styles.avatar_container}
                                        >
                                            {user.avatar ? (
                                                <Card.Img
                                                    src={`${user.avatarType ==='local' ? '/' + user.avatar : user.avatar}`}
                                                    alt={`Аватар ${user.firstName} ${user.secondName}`}
                                                    srcSet=""
                                                    width="150px"
                                                />
                                            ) : (
                                                // 'нет аватара'
                                                <Card.Img
                                                    src="/no_avatar.png"
                                                    alt="Аватар не загружен"
                                                    srcSet=""
                                                    width="150px"
                                                />
                                            )}
                                            <div
                                                className={styles.avatar_load}
                                                onClick={handleShow}
                                            >
                                                <AiOutlineCamera
                                                    style={{ fontSize: "16px" }}
                                                />{" "}
                                                Изменить аватар
                                            </div>
                                        </div>
                                    </Col>
                                    <Col am={6}>
                                        {user.firstName && user.lastName ? (
                                            <>
                                                <Card.Title>
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">
                                                    {user.login}
                                                </Card.Subtitle>
                                            </>
                                        ) : (
                                            <Card.Title>
                                                {user.login}
                                            </Card.Title>
                                        )}
                                    </Col>
                                </Row>

                                <div className="mt-2">
                                    <Button
                                        as={NavLink}
                                        to="edit"
                                        variant="light"
                                        size="sm"
                                    >
                                        Изменить профиль
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                        <div className={styles.mini_info}>
                            <ProfileBalance user={user} />
                            <Alert
                                variant="secondary"
                                className={styles.mini_info__block}
                            >
                                <div>
                                    Язык: <strong>{user.language}</strong>
                                </div>
                                <div>
                                    Валюта: <strong>{user.currency}</strong>
                                </div>
                            </Alert>
                        </div>
                    </div>
                    {/* <Partners /> */}
                    <Card className={styles.partners} body>
                        <span>
                            Начни зарабатывать с нами прямо сейчас! До 30% за
                            каждого зарегистрированного по Вашей ссылке -
                        </span>
                        <Button as={NavLink} to="partners" variant="primary">
                            Партнёрская программа
                        </Button>
                    </Card>
                </Col>
                <Col sm={4}></Col>
            </Row>
        </>
    );
}

export default ProfilePage;
