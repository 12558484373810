
export const TRANSLATIONS_RU = {
    landing:{
        auth: {
            login: 'Войти',
            signup: 'Регистрация',
            popup: {
                login: 'Войдите в аккаунт',
                login_signup: 'или зарегистрируйте',
                login_signup_link: 'бесплатный аккаунт',
                login_btn: 'Войти',
                email: 'Введите ваш email',
                email_desc: 'Мы не передаем ваш email третьим лицам',
                password: 'Пароль',
                google_btn: 'Войти с помощью Google'
            }
        },
        menu: {
            link1:'Мультилинк',
            link2:'Сторилендинг',
            link3:'Инстамагазин'
        },
        title: {
            part1:'Мультиссылка в Инстраграм',
            part2:'бесплатно',
            part3:'за 5 мин'
        },
        desc: 'Объедините аудитории всех социальных сетей и мессенджеров,чтобы получать больше продаж и обращений. '
    },

};