import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../Tools.module.scss";

function Translit() {
    const [translit, setTranslit] = React.useState("");
    console.log(translit);

    const a = {
        Ё: "YO",
        Й: "I",
        Ц: "TS",
        У: "U",
        К: "K",
        Е: "E",
        Н: "N",
        Г: "G",
        Ш: "SH",
        Щ: "SCH",
        З: "Z",
        Х: "H",
        Ъ: "'",
        ё: "yo",
        й: "i",
        ц: "ts",
        у: "u",
        к: "k",
        е: "e",
        н: "n",
        г: "g",
        ш: "sh",
        щ: "sch",
        з: "z",
        х: "h",
        ъ: "'",
        Ф: "F",
        Ы: "I",
        В: "V",
        А: "А",
        П: "P",
        Р: "R",
        О: "O",
        Л: "L",
        Д: "D",
        Ж: "ZH",
        Э: "E",
        ф: "f",
        ы: "i",
        в: "v",
        а: "a",
        п: "p",
        р: "r",
        о: "o",
        л: "l",
        д: "d",
        ж: "zh",
        э: "e",
        Я: "Ya",
        Ч: "CH",
        С: "S",
        М: "M",
        И: "I",
        Т: "T",
        Ь: "'",
        Б: "B",
        Ю: "YU",
        я: "ya",
        ч: "ch",
        с: "s",
        м: "m",
        и: "i",
        т: "t",
        ь: "'",
        б: "b",
        ю: "yu",
    };
    function transliterate(word) {
        return word
            .split("")
            .map(function (char) {
                return a[char] || char;
            })
            .join("");
    }

    const translitHandler = (word) => {
        setTranslit(transliterate(word));
    };

    return (
        <div>
            <Row>
                <Col sm={8}>
                    <Form className={styles.form}>
                        <h5>Преобразовать в транслит</h5>
                        <div>
                            <Form.Group>
                                <Form.Label>Введите исходный текст</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Введите текст"
                                    as="textarea"
                                    rows={3}
                                    onChange={(e) =>
                                        translitHandler(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label>Результат</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Результат"
                                    as="textarea"
                                    rows={3}
                                    value={translit}
                                    disabled
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Col>

                <Col sm={4}>
                    <div className={styles.info}>
                        Транслит онлайн — это сервис для написания русских слов
                        английскими буквами. От переводчика он отличается тем,
                        что просто меняет кириллицу на латиницу, а не переводит
                        само слово. Сервис для транслитерации изменяет русские
                        буквы на английские. Пример: «Здравствуйте —
                        Zdravstvuite». Транслит онлайн позволяет сделать текст
                        легко читаемым в случаях, когда можно использовать
                        только латиницу, и понятным для носителя языка.
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Translit;
