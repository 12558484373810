import StoryLandingService from "../../services/StoryLandingService";
import StoryLandingActionTypes from "./storyLanding.types";

export const fetchProjects = () => async (dispatch) => {
    console.log("redux");

    try {
        const response = await StoryLandingService.fetchProjects();
        dispatch({
            type: StoryLandingActionTypes.FETCH_PROJECTS,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const createProject = (data) => async (dispatch) => {
    try {
        const response = await StoryLandingService.createProject(data);
        dispatch({
            type: StoryLandingActionTypes.CREATE_PROJECT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const updateProject = (data) => async (dispatch) => {
    try {
        const response = await StoryLandingService.updateProject(data);
        dispatch({
            type: StoryLandingActionTypes.UPDATE_PROJECT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteProject = (projectId) => async (dispatch) => {
    try {
        await StoryLandingService.deleteProject(projectId);
        dispatch({
            type: StoryLandingActionTypes.DELETE_PROJECT,
            payload: projectId,
        });
    } catch (error) {
        console.error(error);
    }
};

// slides

export const setCurrentSlide = (slide) => async (dispatch) => {
    try {
        dispatch({
            type: StoryLandingActionTypes.SET_CURRENT_SLIDE,
            payload: slide,
        });

        const response = await StoryLandingService.getElements(slide._id);
        console.log('StoryLandingService.getElements', slide);
        console.log('StoryLandingService.getElements', response);
        dispatch({
            type: StoryLandingActionTypes.GET_ELEMENTS,
            payload: response.data,
        })


    } catch (error) {
        console.error(error);
    }
};

export const getSlides = (projectId) => async (dispatch) => {
    try {
        const response = await StoryLandingService.getSlides(projectId);
        dispatch({
            type: StoryLandingActionTypes.GET_SLIDES,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const createSlide = (data) => async (dispatch) => {
    try {
        const response = await StoryLandingService.createSlide(data);
        dispatch({
            type: StoryLandingActionTypes.CREATE_SLIDE,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const updateSlide = (data) => async (dispatch) => {
    try {
        const response = await StoryLandingService.updateSlide(data);
        dispatch({
            type: StoryLandingActionTypes.UPDATE_SLIDE,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteSlide = (slideId) => async (dispatch) => {
    try {
        await StoryLandingService.deleteSlide(slideId);
        dispatch({
            type: StoryLandingActionTypes.DELETE_SLIDE,
            payload: slideId,
        });
    } catch (error) {
        console.error(error);
    }
};

// Actions for Elements
export const createElement = (data) => async (dispatch) => {
    try {
        const response = await StoryLandingService.createElement(data);
        dispatch({
            type: StoryLandingActionTypes.CREATE_ELEMENT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const getElement = (data) => async (dispatch) => {
    console.log('fffffffffffffffffffffff', data);
    try {
        dispatch({
            type: StoryLandingActionTypes.GET_ELEMENT,
            payload: data,
        });
    } catch (error) {
        console.error(error);
    }
}

export const updateElement = (data) => async (dispatch) => {
    try {
        console.log('updateElement', data);
        const response = await StoryLandingService.updateElement(data);
        dispatch({
            type: StoryLandingActionTypes.UPDATE_ELEMENT,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteElement = (elementId) => async (dispatch) => {
    try {
        await StoryLandingService.deleteElement(elementId);
        dispatch({
            type: StoryLandingActionTypes.DELETE_ELEMENT,
            payload: elementId,
        });
    } catch (error) {
        console.error(error);
    }
};
