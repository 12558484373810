import UserActionTypes from './user.types';


const INITIAL_STATE = {
	userInfo: {},
	userAuthInfo: {},
	userAll: {}, 
	userAuth: false,
	userAuthCheck: false,
	userLoading: true,
	userLogout: {}
}

const userReducer = (state = INITIAL_STATE, action) => {
	// console.log('action.payload', action);
	switch (action.type) {
		// case UserActionTypes.LOGIN_USER:
		// 	return {
		// 		...state,
		// 		userInfo: action.payload
		// 	}
		case UserActionTypes.USER_AUTH_INFO:
			return {
				...state,
				userAuthInfo: action.payload
			}
		case UserActionTypes.USER_INFO:
			return {
				...state,
				userInfo: action.payload
			}
		case UserActionTypes.USER_AUTH:
			return {
				...state,
				userAuth: action.payload
			}
		case UserActionTypes.USER_LOADING:
			return {
				...state,
				userLoading: action.payload
			}
		case UserActionTypes.USER_CHECK:
			return {
				...state,
				userAuthCheck: action.payload
			}
		case UserActionTypes.USER_LOGOUT:
			return {
				...state,
				userLogout: action.payload
			}
		case UserActionTypes.USER_ALL:
			return {
				...state,
				userAll: action.payload
			}
		case UserActionTypes.USER_GET:
			return {
				...state,
				userGet: action.payload
			}

		default:
			return state;
	}
}

export default userReducer