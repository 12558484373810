import React from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Nav } from "react-bootstrap";
import styles from "./Sidebar.module.scss";
import {
    BsReverseLayoutTextWindowReverse,
    BsEnvelope,
    BsPeople,
    BsPinAngle,
    BsGraphUp,
    BsPersonLinesFill,
} from "react-icons/bs";

function SidebarMenuCRM() {
    const { t } = useTranslation("sidebar");

    return (
        <Nav className={`${styles.test} flex-column`}>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/"
            >
                <span>
                    <BsReverseLayoutTextWindowReverse />
                </span>{" "}
                {t("sidebar.menu.dashboard")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/bid"
            >
                <span>
                    <BsEnvelope />
                </span>{" "}
                {t("sidebarCRM.menu.bid")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/base"
            >
                <span>
                    <BsPersonLinesFill />
                </span>{" "}
                {t("sidebarCRM.menu.base")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/tasks"
            >
                <span>
                    <BsPinAngle />
                </span>{" "}
                {t("sidebarCRM.menu.tasks")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/team"
            >
                <span>
                    <BsPeople />
                </span>{" "}
                {t("sidebarCRM.menu.team")}
            </NavLink>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? `${styles.link} ${styles.active}`
                        : `${styles.link}`
                }
                to="/stat"
            >
                <span>
                    <BsGraphUp />
                </span>{" "}
                {t("sidebarCRM.menu.stat")}
            </NavLink>
        </Nav>
    );
}

export default SidebarMenuCRM;
