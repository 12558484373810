import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { update } from "../../../../../redux/multilink/multilink.action";

import { Offcanvas, Tab, Tabs } from "react-bootstrap";

import { usePrevious } from "../../../../../hook/usePrevious";
import { SaveComponent, DesignComponent, SettingComponent, SaveModalComponent } from "../Component";
import PageTextMain from './PageTextMain/PageTextMain';

function PageText({
    showCanvas,
    setShowCanvas,
    text,
    projectId,
    user,
}) {
    const dispatch = useDispatch();
    const handleCanvasClose = () => setShowCanvas(false);


    const [data, setData] = useState(text);

    useEffect(() => {
       setData(text)
    }, [text]);

    // модальное окно с подтверждением сохранения
    const [showModal, setShowModal] = useState(false);

    const handleModalCloseNoSave = () => {
        handleCanvasClose();
        setShowModal(false);
        setData(text);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalShow = () => setShowModal(true);
    // модальное окно с подтверждением сохранения

    // появление кнопки сохранить
    const [save, setSave] = useState(false);
    const prevData = usePrevious(text);
    
    useEffect(() => {
        if (JSON.stringify(prevData) !== JSON.stringify(data)) {
            setSave(true);
        } else {
            setSave(false);
        }
    }, [data, prevData, text]);

    const updateHandler = async (close) => {
        const type = "text";
        await dispatch(update(user.id, projectId, type, data));
        if (close) {
            setShowCanvas(false);
            setData(text);
        }
            
    };



    return (
        <>
        <SaveModalComponent 
            showModal={showModal} 
            handleModalClose={handleModalClose} 
            handleModalCloseNoSave={handleModalCloseNoSave}
        />



            <Offcanvas
                show={showCanvas}
                onHide={save ? handleModalShow : handleCanvasClose}
                style={{ width: "60%" }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Текст</Offcanvas.Title>
                </Offcanvas.Header>
                {data && (
                    <Offcanvas.Body>
                        <Tabs
                            defaultActiveKey="main"
                            transition={false}
                            className="mb-3"
                        >
                            <Tab eventKey="main" title="Основное">
                                <PageTextMain
                                    text={data}
                                    setText={setData}
                                />
                            </Tab>
                            <Tab eventKey="design_block" title="Дизайн блока">
                                <DesignComponent
                                    designComponent={data}
                                    setDesignComponent={setData}
                                />
                            </Tab>
                            <Tab eventKey="setting" title="Настройки">
                                <SettingComponent
                                    settingComponent={data}
                                    setSettingComponent={setData}
                                />
                            </Tab>
                        </Tabs>
                    </Offcanvas.Body>
                )}

                <SaveComponent updateHandler={updateHandler} save={save} />


            </Offcanvas>
        </>
    )
}

export default PageText