import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { QRCodeSVG } from "qrcode.react";
import { AddColorComponent } from "../../../../../Components";

function QrProject({ setShowModalQr, data }) {
    const handleCloseModalQr = () => {
        setShowModalQr(false);
    };

    const [qrColor, setQrColor] = useState("#000000");
    
    const colorTextHandler = (hex) => {
        setQrColor(hex,
        );
    };


    return (
        <Modal show={setShowModalQr} onHide={handleCloseModalQr}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddColorComponent color={"#000000"} colorHandler={colorTextHandler}/>

                <QRCodeSVG
                    value={`https://clckd.in/g/${data.slug}`}
                    size={205}
                    bgColor={"#ffffff"}
                    fgColor={qrColor}
                    level={"M"}
                    includeMargin={true}
                    imageSettings={{
                        src: "https://static.zpao.com/favicon.png",
                        x: undefined,
                        y: undefined,
                        height: 25,
                        width: 25,
                        excavate: false,
                    }}
                />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModalQr}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleCloseModalQr}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default QrProject;
