export const MULTILINK_EN = {
    multilink: {
        header: {
            list: 'All multilink',
            create: 'Create multilink',
            project: 'Project',
            stat: 'Statistic data',
            settingMain: 'Main setting multilink',
            settingAccess: 'Setting access multilink',
            settingIntegration: 'Integration'
        }
    }
} 