import React, { useState, useEffect } from "react";

import { Tab, Tabs, Table, Container, Row, Col } from "react-bootstrap";

function Partners() {
    //маркетинговые вознаграждения
    const [referredUsers, setReferredUsers] = useState([]);

    return (
        <Row>
            <Col>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Основное">
                        <h3>
                            Your referral link: {window.location.origin}
                            /register?referralCode=YOUR_REFERRAL_CODE
                        </h3>
                    </Tab>
                    <Tab eventKey="profile" title="Рефералы">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Expenses</th>
                                    <th>Commission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referredUsers.map((user) => (
                                    <tr key={user._id}>
                                        <td>{user.username}</td>
                                        <td>${user.expenses}</td>
                                        <td>${user.expenses * 0.1}</td>{" "}
                                        {/* Рассчитать комиссию 10% */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="contact" title="Заработано">
                        gjkgjjyhty
                    </Tab>
                    <Tab eventKey="history" title="История">
                        имиттсаап
                    </Tab>
                </Tabs>
            </Col>
            <Col></Col>
        </Row>
    );
}

export default Partners;
