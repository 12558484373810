import React from "react";
import { useDispatch } from "react-redux";
import { addMoney } from "../../../../../../../redux/profile/profile.action";

import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import styles from "./ProfileAddBalance.module.scss";
import { NavLink } from "react-router-dom";

function ProfileAddBalance({ user }) {
    const dispatch = useDispatch();
    const [balance, setBalance] = React.useState(110);
    const [addBalance, setAddBalance] = React.useState(100);


    const addBalanceHandler = async () => {
        await dispatch(addMoney(addBalance, user._id));

    };

    

    return (
        <Alert variant="info" className={styles.mini_info__block}>
            <Form>
                <p>
                    Ваш баланс: <strong>{user.balance} рублей</strong>
                </p>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="number"
                        placeholder="100"
                        defaultValue="100"
                        onChange={(e) => setAddBalance(e.target.value)}
                    />
                    <Button
                        // to="add"
                        variant="warning"
                        onClick={addBalanceHandler}
                    >
                        Пополнить счёт
                    </Button>
                </InputGroup>
                {/* <NavLink to="balance/history">
                    <Button variant="info">История транзакций</Button>
                </NavLink> */}
            </Form>
        </Alert>
    );
}

export default ProfileAddBalance;
