import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainApp from "./App/MainApp";
import { Dashboard } from "./App/Pages";
import Canvas from "./App/Pages/Designer/Designer";

import { Auth, Join, Success } from "./Landing/Components";

import Landing from "./Landing/Landing";
import {
    MultilinkRoutes,
    ToolsRoutes,
    MiniShopRoutes,
    ProfileRoutes,
    OnlineRoutes,
    StoryLandingRoutes
} from "./routes/index";

export const useRoutes = (isAuthenticated) => {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRedirect(true);
        }, 3000);
    }, []);

    if (!isAuthenticated) {
        return (
            <>
                <Routes>
                    <Route path="/" element={<Landing />}>
                        <Route path="/auth" element={<Auth />} />

                        {/* <Route path="/" end element={<Main/>} /> */}
                        <Route path="/good" element={<Success />} />
                        <Route path="/join" element={<Join />} />
                        {redirect ? (
                            <Route path="*" element={<Navigate to="/" />} />
                        ) : (
                            ""
                        )}
                    </Route>
                </Routes>
            </>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<MainApp />}>
                <Route index element={<Dashboard />} />

                <Route path="profile/*" element={<ProfileRoutes />} />
                <Route path="minishop/*" element={<MiniShopRoutes />} />
                <Route path="tools/*" element={<ToolsRoutes />} />
                <Route path="multilink/*" element={<MultilinkRoutes />} />
                <Route path="design/*" element={<Canvas />} />
                <Route path="online/*" element={<OnlineRoutes />} />
                <Route path="storylanding/*" element={<StoryLandingRoutes />} />

            </Route>
        </Routes>
    );
};
