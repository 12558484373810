import $api from "../http";

export default class AuthService {

    static async login(data) {
        return $api.post('/auth/login', {...data})
    }   

    static async registration(data) {
        return $api.post('/auth/registration', {...data})
    }

    static async refresh() {
        return $api.post('/auth/refresh')
    }
    static async logout() {
        return $api.post('/auth/logout')
    }

}

