import React from "react";
import { Outlet } from "react-router-dom";


// import styles from "./Multilink.module.scss";
// import { Button, Col, Row } from "react-bootstrap";


function Multilink() {
    // const user = useSelector(({ user }) => user.userAuthInfo);
    // const multilinks = useSelector(
    //     ({ multilink }) => multilink.multilinksFetch
    // );
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(fetchMultilinks(user));
    // }, []);


    return (
        <>

            <Outlet />
            {/* {multilinks ? <Outlet /> : <NoneMultilink />} */}
        </>
    );
}

export default Multilink;
